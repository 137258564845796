import React, { useCallback, useEffect, useState } from 'react'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { FolderIcon, MicrophoneIcon, PauseIcon, PlayCircleIcon, PlayIcon } from '@heroicons/react/24/outline';
import Loader from '../Loader';
import { useTranslation } from 'react-i18next';

function LiveAudioRecord({ handleSubmitMeeting }) {



    const {
        startRecording,
        stopRecording,
        togglePauseResume,
        recordingBlob,
        isRecording,
        isPaused,
        recordingTime, // This keeps updating with the ongoing recording time
        // ... other variables from useAudioRecorder
    } = useAudioRecorder();

    const [finalRecordingTime, setFinalRecordingTime] = useState(0);
    const [loading, setLoading] = useState(false)

    // Call this function when you want to stop recording
    const handleStopRecording = useCallback(() => {

        if (isRecording) {
            setFinalRecordingTime(recordingTime); // Capture the recording time just before stopping
            stopRecording(); // Then stop the recording

        }
    }, [recordingTime, isRecording, stopRecording]);

    // UseEffect for handling actions after recordingBlob is available
    useEffect(() => {
        if (!recordingBlob) return;
        setLoading(true)
        handleSubmitMeeting(recordingBlob, finalRecordingTime)
        // recordingBlob will be present at this point after 'stopRecording' has been called
    }, [recordingBlob, finalRecordingTime]);


    const { t } = useTranslation();



    return (
        <div className='w-full h-full flex flex-col justify-between items-center py-4'>
            <div className='w-full h-full flex justify-center items-center'>
                <div className='max-w-5xl w-full px-4 sm:px-12 flex flex-col items-center gap-8'>
                    <div>
                        <MicrophoneIcon className={'w-64 h-64 rounded-full px-8 text-white ' + ((isRecording && !isPaused) ? "animate-pulse bg-red-700" : " bg-black")}></MicrophoneIcon>
                    </div>
                    {!loading ? <div className='flex max-w-2xl gap-8 justify-between'>
                        {!isRecording && <button onClick={startRecording} className='bg-black text-white px-4 py-1 rounded-2xl flex gap-2 justify-center items-center'>
                            <PlayIcon className='w-6 h-6  ' />
                            <div className='mr-2'>{t('Audio.AudioRecord.RecordButton')}</div>
                        </button>}

                        {isRecording &&
                            <button onClick={togglePauseResume} className='text-black bg-white border border-black px-4 py-1 rounded-2xl flex gap-2 justify-center items-center'>
                                {isPaused ? <PlayIcon className=' w-10 h-10 px-2' /> : <PauseIcon className='bg-white  w-12 h-12 rounded-full px-3' />}
                                <div className='mr-2'>{isPaused ? t('Audio.AudioRecord.PauseButton') : t('Audio.AudioRecord.ContinueButton')}</div>
                            </button>}
                        {(!isRecording || isPaused) &&
                            <button onClick={handleStopRecording} className='bg-black text-white px-4 py-1 rounded-2xl flex gap-2 justify-center items-center'>
                                <FolderIcon className=' text-white  w-8 h-8 bg-black' />
                                <div className='mr-2'>{t('Audio.AudioRecord.EndAndSave')}</div>
                            </button>}

                    </div> :
                        <div className='flex flex-col items-center justify-center'>
                            <button onClick={handleStopRecording} className='bg-black text-white px-4 py-1 rounded-2xl flex gap-2 justify-center items-center w-32 h-12'>
                                <Loader />
                            </button>
                            <div>{t('Audio.AudioRecord.SaveText')}</div>
                        </div>
                    }

                    {/* Display the recording time */}
                    {!loading && <div>
                        {t('Audio.AudioRecord.RecordingTime1')} {recordingTime} {t('Audio.AudioRecord.RecordingTime2')}
                    </div>}
                </div>
            </div>
        </div>

    )
}

export default LiveAudioRecord

import React, { useEffect, useState } from 'react'
import SectionHeader from '../../../../Components/GeneralComponents/SectionHeader'
import { useTranslation } from 'react-i18next'
import MeetingCard from '../../../../Components/Meetings/MeetingCard'
import { getMeetings } from '../../../../Requests/MeetingRequests'
import { getTeamUuid } from '../../../../utils/cookies'
import CreateAnalysis from '../../../../Components/Analysis/CreateAnalysis'
import { getAllAnalysis } from '../../../../Requests/AnalysisRequests'
import AnalysisCard from '../../../../Components/Analysis/AnalysisCard'

function Analysis() {
    const { t } = useTranslation()
    const [meetings, setMeetings] = useState(null)
    const [createNewAnalysis, setCreateNewAnalysis] = useState(null)
    const [analysis, setAnalysis] = useState(null)

    useEffect(() => {
        let data = { "team_uuid": getTeamUuid() }
        getMeetings(data).then((res) => {
            setMeetings(res.data)
        }).catch((e) => {

        })
        getAllAnalysis(getTeamUuid()).then((res) => {
            setAnalysis(res.data)
        }).catch((e) => {

        })
    }, [])


    return (
        <div className='md:pl-64 pt-4 md:pt-12 w-full flex justify-center'>
            <div className='flex flex-col w-full justify-center items-center '>
                <div className='bg-white max-w-7xl rounded-2xl w-full h-full shadow-xl mx-8'>
                    <SectionHeader
                        title={t('Analysis.AnalysisTitle')}
                        subtitle={t('Analysis.AnalysisSubtitle')}
                    />
                </div>
                {(meetings && createNewAnalysis) && <CreateAnalysis meetings={meetings} />}

                {analysis && <div className='grid grid-cols-1 sm:grid-cols-2 justify-start py-4 max-w-7xl w-full flex-wrap gap-4 '>
                    {analysis.map((analys) => {
                        return <AnalysisCard analysis={analys} key={analys.uuid} />
                    })}

                </div>}
            </div>
        </div>
    )
}

export default Analysis

import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { updateFragment } from "../../Requests/NewAnalysisRequests";
import { formatTime } from "../../utils/calcs";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Bars3BottomLeftIcon, Bars4Icon, ClipboardIcon, ClockIcon, DocumentArrowDownIcon, PencilIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import ReactAudioPlayer from "react-audio-player";
import { PlayCircleIcon, PauseCircleIcon, MinusCircleIcon } from "@heroicons/react/20/solid";
import { ExportToWord } from "../../Pages/Private/Settings/TextCoder/ExportToWord";
import { v4 as uuidv4 } from 'uuid';
function processTranscript(data) {
    let concatenatedLines = data.lines.map(line => line.editablePart).join(' ');
    let concatenatedUUIDs = data.lines.map(line => line.staticPart.match(/<([^>]+)>/)[1]).join('');

    return {
        endTime: data.endTime,
        lines: concatenatedLines,
        speaker: data.speaker,
        startTime: data.startTime,
        uuid: concatenatedUUIDs
    };
}


export default function TranscriptionModal({ handleClose, analysis, fragment, transcriptions, setTranscription, view_mode }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(true);
    const [editableTranscriptions, setEditableTranscriptions] = useState([]);
    const [mode, setMode] = useState("view");
    const [isLoading, setIsLoading] = useState(false);
    const [viewGrouped, setViewGrouped] = useState(false);
    const [timeVisibility, setTimeVisibility] = useState(true);
    let colors = [
        "#A7C7E7",
        "#F3A683",
        "#F7D794",
        "#B8E0D2",
        "#E77F67",
        "#786FA6",
        "#F8A5C2",
        "#63C7B2",
        "#EFA8E4",
        "#F19066",
        "#F3EAC2",
        "#F5D6BA",
        "#C5D1EB",
        "#D6AD60",
        "#92B4A7",
    ];

    const playerRef = useRef();
    const audioRef = useRef(null);

    const speakerNamesObj = JSON.parse(analysis.speaker_names || '{}');

    const [playbackRate, setPlaybackRate] = useState(1); // Default playback rate is 1
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const intervalRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0); // Add this line

    const playSpeeds = [
        { "name": "0.25", "speed": 0.25 },
        { "name": "0.5", "speed": 0.5 },
        { "name": "0.75", "speed": 0.75 },
        { "name": "1", "speed": 1 },
        { "name": "1.25", "speed": 1.25 },
        { "name": "1.5", "speed": 1.5 }

    ]

    useEffect(() => {
        setEditableTranscriptions(transcriptions);
    }, [transcriptions]);

    const speakerColorMapping = {};
    const getColorForSpeaker = (speaker) => {

        const speakerKey = Object.keys(speakerNamesObj).find(key => speakerNamesObj[key] === speaker) || '';
        // Simple hash function on speaker name to get a consistent index
        let hash = 0;
        for (let i = 0; i < speakerKey.length; i++) {
            hash = speakerKey.charCodeAt(i) + ((hash << 5) - hash);
            hash = hash & hash; // Convert to 32bit integer
        }
        hash = Math.abs(hash);

        // Use the hash to select a color
        const colorIndex = hash % colors.length;
        return colors[colorIndex];
    };

    const handleEdit = (index, newText) => {
        const updatedTranscriptions = editableTranscriptions.map((item, i) => i === index ? { ...item, editablePart: newText } : item);
        setEditableTranscriptions(updatedTranscriptions);
    };

    const handleSave = async () => {
        setIsLoading(true);
        const newTranscription = editableTranscriptions.map(item => item.staticPart + item.editablePart).join("\n");
        let data = { "uuid": fragment.uuid, "result": newTranscription };
        try {
            await updateFragment(data);
            setTranscription(editableTranscriptions);
            setMode("view");
        } catch (error) {
            console.error("Failed to update transcription", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSetEdit = () => setMode("edit");

    const closeModal = () => {
        setOpen(false);
        handleClose();
    };

    const seekToTime = async (timeInSeconds) => {
        if (audioRef.current) {
            audioRef.current.currentTime = timeInSeconds;
            try {
                await audioRef.current.play();
            } catch (error) {
                console.error("Error playing audio", error);
            }
        }
    };

    const groupBySpeaker = (transcriptions) => {
        const grouped = [];
        let currentGroup = null;

        transcriptions.forEach((line, index) => {
            const matches = line.staticPart.match(/\[(\d+(?:\.\d+)?)-(\d+(?:\.\d+)?)\]\s+((?:Speaker\s+)?[\w\s]+):/);
            if (matches) {
                const startTime = matches[1];
                const endTime = matches[2];
                const speaker = matches[3]?.trim();
                if (currentGroup && currentGroup.speaker.trim() === speaker.trim()) {
                    currentGroup.lines.push(line);
                    currentGroup.endTime = endTime;
                } else {
                    if (currentGroup) {
                        grouped.push(currentGroup);
                    }
                    currentGroup = { speaker, startTime, endTime, lines: [line] };
                }
            }
            if (index === transcriptions.length - 1 && currentGroup) {
                grouped.push(currentGroup);
            }
        });

        return grouped;
    };

    const groupedTranscriptions = groupBySpeaker(editableTranscriptions);


    const handleSpeakerChange = (newSpeakerKey, index) => {

        // Update the staticPart to reflect the new speaker name
        const updatedTranscriptions = editableTranscriptions.map((item, i) => {
            if (i === index) {
                // Assuming the static part format is "[time-range] Speaker Name:"
                // and you want to replace "Speaker Name" with the new speaker name.
                const updatedStaticPart = item.staticPart.replace(/(\[\d+(?:\.\d+)?-\d+(?:\.\d+)?\]\s+)(?:Speaker\s+)?[\w\s]+:/, `$1${newSpeakerKey}:`);
                return { ...item, staticPart: updatedStaticPart };
            }
            return item;
        });

        setEditableTranscriptions(updatedTranscriptions);
    };

    function transformTimeFormat(staticPart, index) {
        const matches = staticPart.match(/\[(\d+(?:\.\d+)?)-(\d+(?:\.\d+)?)\]\s+((?:Speaker\s+)?[\w\s]+):/);
        if (matches) {
            // Parse start and end times into separate hours, minutes, and seconds
            const startTimeSeconds = parseFloat(matches[1]);
            const endTimeSeconds = parseFloat(matches[2]);
            const speakerName = matches[3].trim();

            const startTime = formatTime(startTimeSeconds).split(":");
            const endTime = formatTime(endTimeSeconds).split(":");

            const handleTimeChange = (value, timeType, unit, isEndTime) => {
                // Construct the new time in seconds based on the unit (hours, minutes, seconds) and whether it's start or end time
                const timeArray = isEndTime ? endTime : startTime;
                timeArray[unit] = value; // Update the specific unit with new value

                const newTimeInSeconds = convertToSeconds(timeArray.join(':'));

                // Update the transcription with the new time
                const updatedTranscriptions = editableTranscriptions.map((item, i) => {
                    if (i === index) {
                        let newStaticPart = item.staticPart;
                        if (timeType === 'start') {
                            newStaticPart = newStaticPart.replace(/\[(\d+(?:\.\d+)?)-/, `[${newTimeInSeconds}-`);
                        } else {
                            newStaticPart = newStaticPart.replace(/-(\d+(?:\.\d+)?)\]/, `-${newTimeInSeconds}]`);
                        }
                        return { ...item, staticPart: newStaticPart };
                    }
                    return item;
                });

                setEditableTranscriptions(updatedTranscriptions);
            };

            return (
                <div className="flex gap-2 items-center w-full">

                    {mode === "edit" ?
                        <div className="flex gap-2 flex-col md:flex-row w-full">

                            <select
                                value={speakerName}
                                onChange={(e) => handleSpeakerChange(e.target.value, index)}
                                className="text-black rounded px-4 py-1 text-sm"
                                style={{ backgroundColor: getColorForSpeaker(speakerNamesObj[speakerName]) }}
                            >
                                {Object.entries(speakerNamesObj).map(([key, name]) => (
                                    <option key={key} value={key}>{name}</option>
                                ))}
                            </select>
                            <div className=" cursor-pointer  text-gray-400 flex gap-1 text-sm items-center" >
                                <PlayCircleIcon className="w-5 h-5" onClick={() => { seekToTime(startTimeSeconds) }} />
                                {/* Start time inputs */}
                                {["Hours", "Minutes", "Seconds"].map((unit, unitIndex) => (
                                    <input
                                        key={`start-${unit}`}
                                        type="number"
                                        value={startTime[unitIndex]}
                                        onChange={(e) => handleTimeChange(e.target.value, 'start', unitIndex, false)}
                                        className="rounded px-2 py-1 text-xs w-9 input-number appearance-none text-center "
                                        min="0"
                                        placeholder={unit}
                                    />
                                ))}
                                <span>-</span>
                                {/* End time inputs */}
                                {["Hours", "Minutes", "Seconds"].map((unit, unitIndex) => (
                                    <input
                                        key={`end-${unit}`}
                                        type="number"
                                        value={endTime[unitIndex]}
                                        onChange={(e) => handleTimeChange(e.target.value, 'end', unitIndex, true)}
                                        className="rounded px-2 py-1 text-xs w-9 input-number appearance-none text-center "
                                        min="0"
                                        placeholder={unit}
                                    />
                                ))}

                            </div>
                        </div> :
                        <div>
                            <div className="flex gap-2 items-center ">
                                <span style={{ backgroundColor: getColorForSpeaker(speakerNamesObj[speakerName]) }} className="text-black rounded-full px-4 py-1 text-sm whitespace-nowrap">{speakerNamesObj[speakerName]}</span>
                                {timeVisibility && <div className="cursor-pointer underline text-gray-400 flex gap-1 text-sm items-center" onClick={() => { seekToTime(startTimeSeconds) }}>
                                    <PlayCircleIcon className="w-5 h-5" />
                                    {formatTime(startTimeSeconds, 0)} - {formatTime(endTimeSeconds, 0)}
                                </div>}
                            </div>
                        </div>
                    }
                </div>
            );
        }
        return staticPart; // Return unchanged if no match found
    }

    // Helper function to convert "hours:minutes:seconds" format to seconds
    function convertToSeconds(timeStr) {
        const parts = timeStr.split(':').map(Number);
        while (parts.length < 3) parts.unshift(0); // Ensure array is [hours, minutes, seconds]
        return parts[0] * 3600 + parts[1] * 60 + parts[2];
    }



    useEffect(() => {
        const audio = audioRef.current;
        const updateCurrentTime = () => {
            setCurrentTime(audio.currentTime);
        };

        if (audio) {
            audio.addEventListener('timeupdate', updateCurrentTime);
        }

        return () => {
            if (audio) {
                audio.removeEventListener('timeupdate', updateCurrentTime);
            }
        };
    }, []);

    // Function to format the time in minutes and seconds
    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);
        return [
            hours,
            minutes < 10 ? '0' + minutes : minutes,
            seconds < 10 ? '0' + seconds : seconds,
        ].join(':');
    };

    useEffect(() => {
        if (audioRef && audioRef.current) {
            if (isPlaying) {
                audioRef.current.play();
                startProgressLoop();
            } else {
                audioRef.current.pause();
            }
        }
        return () => clearInterval(intervalRef.current);
    }, [isPlaying]);

    useEffect(() => {
        if (audioRef?.current) {
            audioRef.current.playbackRate = playbackRate;
        }
    }, [playbackRate]);

    const startProgressLoop = () => {
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            if (audioRef.current) {
                const { currentTime, duration } = audioRef.current;
                // Multiply by 400 for finer increments
                setProgress((currentTime / duration) * 400);
            }
        }, [250]);
    };

    // When handling changes from the progress bar, adjust how you calculate currentTime
    const handleProgressChange = (e) => {
        const value = e.target.value;
        // Adjust calculation to match the new 0-400 range
        audioRef.current.currentTime = (audioRef.current?.duration / 400) * value;
        setProgress(value);
    };

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
    };


    const handlePlaybackRateChange = (e) => {
        setPlaybackRate(Number(e.target.value));
    };


    const skip = (amount) => {
        if (audioRef.current) {
            let newTime = audioRef.current.currentTime + amount;
            // Ensure newTime is within the bounds of the audio duration
            newTime = Math.max(0, Math.min(newTime, audioRef.current.duration));
            audioRef.current.currentTime = newTime;
            // Update progress state
            const newProgress = (newTime / audioRef.current.duration) * 400; // Multiplied by 400 for finer increments
            setProgress(newProgress);
        }
    };


    const addNewBlock = (index) => {
        // Default values for a new block
        const newBlock = {
            staticPart: "<" + uuidv4() + "> [0-0] " + Object.keys(speakerNamesObj)[0] + ":",
            editablePart: '', // Placeholder text or empty
        };

        // Split the editableTranscriptions into two parts, before and after the specified index
        const start = editableTranscriptions.slice(0, index);
        const end = editableTranscriptions.slice(index);

        // Insert the new block in between the two parts and update the state
        setEditableTranscriptions([...start, newBlock, ...end]);
    };

    const removeBlock = (index) => {
        // Create a copy of the current editableTranscriptions to not directly mutate state
        let updatedTranscriptions = [...editableTranscriptions];
        // Remove the element at the specified index
        updatedTranscriptions.splice(index, 1);
        // Update the state with the new array
        setEditableTranscriptions(updatedTranscriptions);
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-none ">
                    <div className="flex min-h-full items-end justify-center mt-4 p-2 md:p-4 text-center sm:items-center sm:p-0 max-h-screen">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="flex flex-col max-h-screen relative transform rounded-lg bg-white px-2 md:px-4 pt-2 pb-0 text-left shadow-xl transition-all sm:my-8 w-full md:max-w-full  items-center">
                                <Dialog.Title
                                    as="h3"
                                    className=" text-lg font-medium leading-6 text-gray-500 text-center w-full"
                                >
                                    <div className="flex w-full justify-start items-center gap-4 mb-2 md:mb-4">



                                        <div className="text-black  text-xl font-bold flex-grow">
                                            {analysis.name}
                                        </div>
                                        <button className="border-2 border-gray-500 bg-white text-gray-500 rounded-md px-4 py-2 text-sm"
                                            onClick={handleClose}
                                        >
                                            X
                                        </button>
                                    </div>


                                </Dialog.Title>
                                <div className="w-full flex flex-col md:flex-row justify-center  mb-2 gap-2 ">

                                    <div className="w-full flex justify-center md:justify-end mb-2 gap-2 max-w-7xl">
                                        <div className="flex gap-2 justify-end">
                                            <button className={"rounded-md text-sm border-2 border-black px-4 py-2 " + (!viewGrouped ? "bg-black text-white" : "bg-white text-black")}
                                                onClick={() => setViewGrouped(!viewGrouped)}
                                            >
                                                {viewGrouped ? <div className="flex items-center gap-2"><Bars3BottomLeftIcon className="w-4 h-4" />

                                                </div> : <div className="flex items-center gap-2"><Bars4Icon className="w-4 h-4" />

                                                </div>}
                                            </button>
                                            <button className={"rounded-md text-sm border-2 border-black px-4 py-2 " + (timeVisibility ? "bg-black text-white" : "bg-white text-black")}
                                                onClick={() => setTimeVisibility(!timeVisibility)}
                                            >
                                                <div className="flex gap-2 items-center">
                                                    <ClockIcon className="w-4 h-4" />

                                                </div>
                                            </button>
                                        </div>
                                        <div className="flex gap-2">
                                            <CopyToClipboard >
                                                <button className="border-2 border-black bg-white text-black rounded-md px-4 py-2 text-sm hover:bg-black hover:text-white flex justify-center items-center gap-2 w-full"
                                                    onClick={() => ExportToWord(groupedTranscriptions.map((group) => {
                                                        return (processTranscript(group))
                                                    }), speakerNamesObj, null, colors, null, analysis, null)}
                                                >
                                                    <DocumentArrowDownIcon className=" w-4 h-4" />
                                                    Word
                                                </button>
                                            </CopyToClipboard>
                                            {!(view_mode == "public") && <button className="border-2  border-black bg-black text-white rounded-md px-4 py-2 text-sm flex items-center justify-center gap-2 w-full"
                                                type="button"
                                                onClick={() => {
                                                    mode == "view" ? handleSetEdit() : handleSave();
                                                }}
                                            >
                                                <PencilIcon className=" w-4 h-4" />
                                                {mode == "view" ? t('TranscriptionModal.EditButton') : t('TranscriptionModal.SaveButton')}
                                            </button>}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex justify-center overflow-y-auto">
                                    <div className="w-full max-w-7xl">
                                        <div className="overflow-y-auto ">
                                            {mode == "view" ?
                                                <div>
                                                    {viewGrouped ?
                                                        (groupedTranscriptions.map((group, groupIndex) => (

                                                            /*
                                                             <span className="bg-black text-white rounded-full px-4 py-1 text-sm">{speaker}</span>
                                <div className=" cursor-pointer underline text-gray-400 flex gap-1 text-sm items-center" onClick={() => { seekToTime(startTime) }}>
                                    <PlayCircleIcon className="w-5 h-5" />
                                    {formattedStartTime} - {formattedEndTime}
            
                                </div>
                                                            */
                                                            <div key={groupIndex} className="text-base mt-2 mb-4">
                                                                <div className="text-base mt-2 flex flex-col">
                                                                    <div className="flex gap-2">
                                                                        <div style={{ backgroundColor: getColorForSpeaker(speakerNamesObj[group.speaker]) }} className="text-black rounded-full px-4 py-1 text-sm w-fit">{speakerNamesObj[group.speaker]}</div>
                                                                        {timeVisibility &&
                                                                            <div className=" cursor-pointer underline text-gray-400 flex gap-1 text-sm items-center" onClick={() => { seekToTime(group.startTime) }}>
                                                                                <PlayCircleIcon className="w-5 h-5" />
                                                                                {formatTime(group.startTime, 0)} - {formatTime(group.endTime, 0)}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="mt-2  ml-2">
                                                                        {group.lines.map((line, lineIndex) => (
                                                                            <span key={lineIndex} className="text-xs md:text-sm">
                                                                                {" "}{line.editablePart}

                                                                            </span>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))) :
                                                        editableTranscriptions.map((line, index) => {
                                                            if (line.staticPart.length > 1) {
                                                                return <div className="text-base mt-2 mb-4">
                                                                    <div className="text-base mt-2 ">{transformTimeFormat(line.staticPart, index)}
                                                                        <div className="mt-2 ml-2 text-xs md:text-sm">{line.editablePart}</div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        })}

                                                </div> : <>{editableTranscriptions.map((line, index) => {
                                                    if (line.staticPart.length > 1) {
                                                        return (
                                                            <div key={index} className="flex flex-col gap-4 items-start my-2 ">
                                                                <div className="text-base whitespace-nowrap flex justify-between w-full">
                                                                    <div>{transformTimeFormat(line.staticPart, index)}</div>
                                                                    <div className="flex gap-2 items-center flex-col md:flex-row">
                                                                        <button className="add-block-btn " onClick={() => addNewBlock(index)}>
                                                                            <PlusCircleIcon className="w-6 h-6" />
                                                                        </button>
                                                                        <button className="add-block-btn " onClick={() => removeBlock(index)}>
                                                                            <MinusCircleIcon className="w-6 h-6 text-red-600" />
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                                <textarea
                                                                    value={line.editablePart}
                                                                    onChange={(e) => handleEdit(index, e.target.value)}
                                                                    className="flex  border rounded w-full h-fit text-xs md:text-md"
                                                                    rows={2}
                                                                />
                                                            </div>
                                                        )
                                                    };
                                                })}
                                                </>}

                                        </div>
                                    </div>

                                </div>
                                {analysis?.file?.url && (
                                    <div className="w-full mb-8">

                                        {/* Progress Bar */}
                                        <input
                                            type="range"
                                            value={progress}
                                            min="0"
                                            max="400"
                                            onChange={handleProgressChange}
                                            className="range-slider w-full appearance-none bg-transparent mt-0"
                                            style={{ '--progress': `${(progress / 400) * 100}%` }}
                                        />



                                        <div className="flex flex-col md:flex-row items-center justify-between gap-2">
                                            {/* Custom Controls */}
                                            <div className="flex items-center md:gap-4 flex-col md:flex-row">
                                                <div className="flex items-center gap-2 justify-start mt-2 md:mt-0">
                                                    <button onClick={handlePlayPause}>
                                                        {isPlaying ? <PauseCircleIcon className="w-12 h-12" /> : <PlayCircleIcon className="w-12 h-12" />}
                                                    </button>
                                                    <span>{formatTime(currentTime) + " / "}<span className="text-sm text-gray-500">{formatTime(analysis.duration)}</span> </span> {/* Display the formatted current time */}
                                                </div>
                                                <div className="flex items-center justify-between gap-2 ml-2">
                                                    <button onClick={() => skip(-15)} className="bg-white border text-xs text-black px-1.5 py-0.5 rounded hover:bg-black hover:text-white">-15s</button>
                                                    <button onClick={() => skip(-5)} className="bg-white border text-xs text-black px-1.5 py-0.5 rounded hover:bg-black hover:text-white">-5s</button>
                                                    <button onClick={() => skip(-1)} className="bg-white border text-xs text-black px-1.5 py-0.5 rounded hover:bg-black hover:text-white">-1s</button>
                                                    <button onClick={() => skip(1)} className="bg-white border text-xs text-black px-1.5 py-0.5 rounded hover:bg-black hover:text-white">+1s</button>
                                                    <button onClick={() => skip(5)} className="bg-white border text-xs text-black px-1.5 py-0.5 rounded hover:bg-black hover:text-white">+5s</button>
                                                    <button onClick={() => skip(15)} className="bg-white border text-xs text-black px-1.5 py-0.5 rounded hover:bg-black hover:text-white">+15s</button>
                                                </div>
                                            </div>



                                            <div className="flex gap-3">

                                                {playSpeeds.map((playSpeed) => {
                                                    return (
                                                        playSpeed.speed == playbackRate ?
                                                            <button className="rounded-lg  border border-black bg-black text-white w-10 md:w-12 h-6 md:h-8 flex justify-center items-center text-xs md:text-sm shadow-lg">{playSpeed.name}</button>
                                                            :
                                                            <button
                                                                onClick={() => { setPlaybackRate(playSpeed.speed) }}
                                                                className="rounded-lg  border border-black bg-white text-black w-10 md:w-12 h-6 md:h-8 flex justify-center items-center text-xs md:text-sm shadow-lg">{playSpeed.name}</button>
                                                    )
                                                })}

                                            </div>

                                            {/* Hidden Audio Element */}
                                            <audio
                                                ref={audioRef}
                                                src={analysis.file.url}
                                                onEnded={() => setIsPlaying(false)}
                                                onPlay={() => setIsPlaying(true)}
                                                onPause={() => setIsPlaying(false)}
                                                hidden
                                            />
                                        </div>
                                    </div>
                                )}

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    );
}

import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sendAmplitudeData } from "../../../../utils/amplitude";
import Loader from "../../../../Components/Loader";

export default function StripeCardPage() {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    sendAmplitudeData({ "type": "Navigation", "value": "Payment info" })
    if (!stripe) {
      return;
    }
  }, [stripe, elements]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "http://localhost:3000/view_subscriptions",
      },
    });
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage(t("Billing.PaymentError"));
    }

    setIsLoading(false);
  };

  return (
    <div className="w-full d-flex align-items-center justify-content-end">
      <h1 className="text-4xl font-semibold text-gray-900 my-8 mx-8 text-left">
        {t("Billing.PaymentTabTitle")}
      </h1>
      <h1 className="text-xl  text-gray-900 my-8 mx-8 text-left">
        {t("Billing.PaymentTabDescription")}
      </h1>
      <form
        id="payment-form"
        onSubmit={handleSubmit}
        className=" lg:mx-auto lg:max-w-2xl"
      >
        {!!elements ? (
          <div>
            <PaymentElement id="payment-element" />
            <div>
              {!isLoading ? (
                <button
                  className=" rounded-md border border-transparent bg-gray-800 px-5 py-3 text-base font-medium text-white hover:bg-gray-900"
                  type="submit"
                  disabled={!stripe}
                >
                  <span id="button-text">{t("Billing.PayNow")}</span>
                </button>
              ) : (
                <button
                  className=" rounded-md border border-transparent bg-gray-800 px-5 py-3 text-base font-medium text-white hover:bg-gray-900">
                  <Loader />
                </button>
              )}
            </div>
          </div>
        ) : (
          <div>
            <></>
          </div>
        )}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
}

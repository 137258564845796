import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
    const cookies = new Cookies();
    return cookies.get("token");
}

export function uploadMeeting(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/meeting`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}`, 'Content-Type': 'multipart/form-data' } });
}

export function getMeetings(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/meetings`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getMeeting(meeting_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + `/meeting/` + meeting_uuid;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getPublicMeeting(public_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + `/meeting/public/` + public_uuid;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function audioChat(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/meeting/chat`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function requestMeetingProcess(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/meeting/process`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getMeetingTranscript(meeting_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + `/meeting/transcript/` + meeting_uuid;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}


export function updateMeetingTranscript(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/meeting/transcript/update`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function updateSpeakers(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/meeting/speakers/update`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getStatus(meeting_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + '/meeting/status/' + meeting_uuid;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function deleteAudio(meeting_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + '/meeting/audio/delete/' + meeting_uuid;
    return axios.delete(url, { headers: { authorization: `Bearer ${getToken()}` } });
}
export function deleteTranscript(meeting_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + '/meeting/transcript/delete/' + meeting_uuid;
    return axios.delete(url, { headers: { authorization: `Bearer ${getToken()}` } });
}
export function deleteMeeting(meeting_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + '/meeting/delete/' + meeting_uuid;
    return axios.delete(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function updateMeetingRequest(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/meeting/update`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function updateMeetingVisibilityRequest(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/meeting/visibility`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
    const cookies = new Cookies();
    return cookies.get("token");
}

export function createOrEditAnalysis(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/analysis/create`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getAllAnalysis(team_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + `/all_analysis/` + team_uuid;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getAnalysis(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/analysis`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function askAnalysis(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/analysis/question`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

import { ChatBubbleBottomCenterIcon, CircleStackIcon, CpuChipIcon, DocumentTextIcon, SpeakerWaveIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getStatus, requestMeetingProcess } from '../../Requests/MeetingRequests'
import { calculateProcessCost, formatDateTime } from '../../utils/calcs'
import { getTeamUuid } from '../../utils/cookies'
import { useMyContext } from '../ContextProviders/CreditsContextProvider'
import Loader from '../Loader'
import { useTranslation } from 'react-i18next'
import NoCreditsModal from '../Billing/NoCreditsModal'
import { analysisStatus, processAudioAnalysis, requestTranscriptionProcess } from '../../Requests/NewAnalysisRequests'
import { processtextAnalysis } from '../../Requests/TextRequests'
import UpgradedLoader from '../UpgradedLoader'

function AnalisysCard({ meeting }) {

    const { credits, setCredits } = useMyContext();
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [insufficientCredits, setInsufficientCredits] = useState(null)
    const [requestedProcess, setRequestedProcess] = useState(false)

    const handleProcessMeeting = () => {
        setRequestedProcess(true)
        let data = { "analysis_uuid": meeting.uuid, "team_uuid": getTeamUuid() }
        if (meeting.file_type == "audio") {
            processAudioAnalysis(data).then(() => {
                setCredits((credits - calculateProcessCost(meeting.duration)))
                window.location.reload(false)
            }).catch((e) => {
                if (JSON.parse(e.request.response)["message"] == "Insufficient credits") {
                    setInsufficientCredits(<NoCreditsModal
                        title={t('CreditsModal.Title')}
                        body={t('CreditsModal.Body')}
                        continue={t('CreditsModal.Continue')}
                        navLink="/pricing/credits"
                        handleClose={() => { setInsufficientCredits(null) }} />)
                }

                setRequestedProcess(false)
            })
        } else if (meeting.file_type == "pdf") {
            processtextAnalysis(data).then((res) => {
                //setCredits((credits - calculateProcessCost(meeting.duration)).toFixed(1))
                setRequestedProcess(false)
                window.location.reload(false)
            }).catch((e) => {
                if (JSON.parse(e.request.response)["message"] == "Insufficient credits") {
                    setInsufficientCredits(<NoCreditsModal
                        title={t('CreditsModal.Title')}
                        body={t('CreditsModal.Body')}
                        continue={t('CreditsModal.Continue')}
                        navLink="/pricing/credits"
                        handleClose={() => { setInsufficientCredits(null) }} />)
                }
                setRequestedProcess(false)
            })
        } else if (meeting.file_type == "manual_transcription") {
            requestTranscriptionProcess(meeting.uuid).then(() => {
                setRequestedProcess(false)
                window.location.reload(false)
            }).catch((e) => {
                setRequestedProcess(false)
            })
        }
    }

    const [timerId, setTimerId] = useState(null);

    useEffect(() => {
        if (meeting.status == "Processing") {
            const intervalId = setInterval(async () => {
                try {
                    const res = await analysisStatus(meeting.uuid);
                    if (res.data.message === "updated") {
                        clearInterval(intervalId); // turn off timer here
                        window.location.reload(false); // reload the page
                    }
                } catch (error) {
                    console.error('Error fetching status:', error);
                }
            }, 10000);

            setTimerId(intervalId);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, []); // Add dependencies if neede



    return (
        <div className='flex flex-col border border-black px-4 py-4 w-full bg-white rounded-2xl h-full '>
            <div className='flex justify-between flex-col sm:flex-row gap-2'>
                <div className='flex items-center justify-start mb-4 ml-1'>
                    {meeting.file_type == "audio" && <SpeakerWaveIcon className='w-10 h-10' />}
                    {meeting.file_type == "pdf" && <DocumentTextIcon className='w-10 h-10' />}
                    {meeting.file_type == "manual_transcription" && <ChatBubbleBottomCenterIcon className='w-10 h-10' />}
                    <div className=' pl-4'>
                        <div className='text-base font-semibold  text-gray-900'>
                            {meeting.name}
                        </div>
                        <div className='text-sm text-gray-500'>{formatDateTime(meeting.created_at)}</div>
                        {meeting.file_type == "audio" && <div className='text-sm text-gray-500 font-bold'>Audio</div>}
                    </div>

                </div>
                <button className='text-sm bg-black text-white rounded-xl h-fit px-3 py-2 whitespace-nowrap'
                    onClick={() => {
                        if (meeting.status != "Not processed") { navigate("/analysis/" + meeting.uuid) };
                        if (meeting.status == "Not processed" && !requestedProcess) { handleProcessMeeting() };
                    }}
                >
                    {meeting.status == "Processed" && <div>{t('MeetingCard.StatusViewMore')}</div>}
                    {meeting.status == "Processing" &&
                        <div className='flex gap-2 items-center justify-between animate-pulse'>
                            <div>{t('MeetingCard.StatusProcessing')}...</div>
                        </div>}
                    {(meeting.status != "Processed" && meeting.status != "Processing") &&
                        (requestedProcess ?
                            <UpgradedLoader w={5} h={5} bg="bg-white"></UpgradedLoader>
                            : <div className='flex justify-between items-center gap-3'>
                                <div>{t('MeetingCard.StatusProcess')}</div>
                                <div className='flex items-center gap-1'>
                                    <div className='font-bold'>{calculateProcessCost(meeting.duration) > 0 ? calculateProcessCost(meeting.duration)?.toFixed(2) : "0"}</div>

                                    <CpuChipIcon className='w-5 h-5' />
                                </div>
                            </div>)}
                </button>
            </div>
            {meeting.fragments.length > 0 && <div className={'px-2 text-sm py-4 border-t-2 border-dotted h-full' + (!meeting.status == "Processed" && " bg-slate-100 animate-pulse ")}>
                {meeting.fragments[0].result}
            </div>
            }
            {insufficientCredits}
        </div>
    )
}

export default AnalisysCard

import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
    const cookies = new Cookies();
    return cookies.get("token");
}



export function getTranscriptionsCodeFinders(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/getTranscriptionsCodeFinders`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getTranscriptionsCodeFinder(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/getTranscriptionsCodeFinder`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function requestTranscriptionCodeFinding(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/requestTranscriptionsCodeFinding`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}


import React, { useEffect, useState } from 'react'
import AICodeFinder from './AICodeFinder';
import { Card } from '@tremor/react';
import TextCodeModal from '../TextCoder/TextCodeModal';
import { ArrowDownIcon, ArrowUpIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { deleteAiCode, getAiCode, getAiCodes, requestAiCodeFinding } from '../../Requests/AICodeFinder';
import { getTeamUuid } from '../../utils/cookies';
import { waitFor } from '@testing-library/react';
import UpgradedLoader from '../UpgradedLoader';
import { useTranslation } from 'react-i18next';
import NoCreditsModal from '../Billing/NoCreditsModal';
import { useMyContext } from '../ContextProviders/CreditsContextProvider';

function AICodeView({ project, setUploadAudioModal, handleUpdateCode, handleAddNewCode }) {
    const [aiCodes, setAiCodes] = useState([]);
    const { credits, setCredits } = useMyContext();
    const [waitingCodes, setWaitingCodes] = useState(null);
    const [isAsking, setIsAsking] = useState(true);
    const { t } = useTranslation()
    const [collapsedCards, setCollapsedCards] = useState({});
    const [insufficientCredits, setInsufficientCredits] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            const res = await getAiCodes({ "project_uuid": project.uuid, "team_uuid": getTeamUuid() });
            const temp_res = [];
            const waiting_for = [];

            res.data.forEach(coder_block => {
                if (coder_block.status === "Processed") {
                    temp_res.push({ "aiCoder": coder_block, "result": JSON.parse(coder_block.codes_found) });
                } else if (coder_block.status === "Failed") {
                    temp_res.push(coder_block);
                }
                else {
                    waiting_for.push(coder_block);
                }
            });

            if (temp_res.length > 0) setAiCodes(temp_res);
            if (waiting_for.length > 0) setWaitingCodes(waiting_for);
            if (waiting_for.length < 1) setIsAsking(false);
            if (temp_res.length == 0 && waiting_for.length == 0) setIsAsking(false);
        };

        fetchData();
    }, [project.uuid]);



    useEffect(() => {
        const interval = setInterval(async () => {
            if (waitingCodes && waitingCodes.length > 0) {
                let allFailed = true; // Assume all codes have failed initially
                for (const waitingCode of waitingCodes) {
                    if (waitingCode.status != "Failed") {
                        const res = await getAiCode({ "project_uuid": project.uuid, "team_uuid": getTeamUuid(), "uuid": waitingCode.uuid });

                        if (res.data.status === "Processed") {
                            setAiCodes(prev => [{ "aiCoder": waitingCode, "result": JSON.parse(res.data.codes_found) }, ...prev]);
                            setWaitingCodes(prev => prev.filter(code => code.uuid !== waitingCode.uuid));
                            if (waitingCodes.length === 1) setIsAsking(false);
                        } else if (res.data.status === "Failed") {
                            allFailed = true; // If any code is not failed, update the flag

                            setAiCodes(prev => [res.data, ...prev]);
                            setWaitingCodes(prev => prev.filter(code => code.uuid !== waitingCode.uuid));
                        } else {
                            allFailed = false;
                        }
                    }
                }
                if (allFailed) {
                    clearInterval(interval); // Clear interval if all codes have failed
                    setIsAsking(false);
                }
            } else {
                clearInterval(interval);
            }
        }, 5000); // Check every 5 seconds.

        return () => clearInterval(interval);
    }, [waitingCodes, project.uuid]);




    const toggleCard = (uuid) => {
        setCollapsedCards(prev => ({
            ...prev,
            [uuid]: !prev[uuid]
        }));
    };

    const delete_code = (deleteCode, ifFailed = false) => {
        deleteAiCode({ "project_uuid": project.uuid, "team_uuid": getTeamUuid(), "uuid": deleteCode.uuid }).then((res) => {
            // Filter out the deleted code from the aiCodes state
            if (ifFailed) {
                setAiCodes(prev => prev?.filter(code => code.uuid !== deleteCode.uuid));
            } else {
                setAiCodes(prev => prev?.filter(code => code?.aiCoder?.uuid !== deleteCode.uuid));
                // Optionally filter out from waitingCodes state if needed
                setWaitingCodes(prev => prev?.filter(code => code.uuid !== deleteCode.uuid));
            }
        }).catch((e) => { })
    }

    const requestCodeFind = (data, cost) => {
        setIsAsking(true)
        requestAiCodeFinding(data).then((res) => {
            setWaitingCodes(prev => {
                setCredits(credits - cost)
                const updatedCodes = [res.data, ...(prev || [])];
                return updatedCodes;
            });
        }).catch((e) => {
            if (JSON.parse(e.request.response)["message"] == "Insufficient credits") {
                setInsufficientCredits(<NoCreditsModal
                    title={t('CreditsModal.Title')}
                    body={t('CreditsModal.Body')}
                    continue={t('CreditsModal.Continue')}
                    navLink="/pricing/credits"
                    handleClose={() => { setInsufficientCredits(null) }} />)
            }
            setIsAsking(false)
        })
    }

    return <div>
        <AICodeFinder project={project} isAsking={isAsking} setIsAsking={setIsAsking} requestCodeFind={requestCodeFind} />
        {aiCodes && (
            <>
                <div className='flex flex-col w-full gap-2 my-2'>
                    {waitingCodes?.map((current_waiting_for) => {
                        return (<Card>

                            <div className='flex flex-col gap-4 '>
                                <div className='font-bold '>
                                    {t('AiCodeDiscovery.FindingCodes')}
                                </div>
                                <UpgradedLoader w={6} h={6} bg="bg-black" />
                            </div>
                        </Card>
                        )
                    })}
                </div>
                {aiCodes?.map((current_code) => {

                    return (
                        current_code.status != "Failed" ?
                            <Card className='my-2' key={current_code.aiCoder.uuid}>
                                <div className='flex flex-col gap-4 mt0'>
                                    <div className='flex flex-col gap-2'>
                                        <div className='flex justify-between'>
                                            <div className='font-bold '>{t('AiCodeDiscovery.Results')}</div>
                                            <button onClick={() => toggleCard(current_code.aiCoder.uuid)}>
                                                {collapsedCards[current_code.aiCoder.uuid] ? <ChevronDownIcon className='w68 h-6' /> : <ChevronUpIcon className='w-6 h-6' />}
                                            </button>

                                        </div>
                                        <div className='flex text-xs gap-2 '>
                                            {t('TextCoder.Files')}:
                                            {project?.analysis?.map(current_analysis => {
                                                const analysisUUIDs = current_code?.aiCoder?.analysis_uuids.split(',');
                                                if (analysisUUIDs.includes(current_analysis?.uuid)) {
                                                    return <div>{current_analysis?.name}</div>
                                                }
                                                return null
                                            })}
                                        </div>
                                    </div>
                                    {!collapsedCards[current_code.aiCoder.uuid] && (
                                        <>
                                            <div className='flex flex-col rounded-md py-2'>
                                                {Object.entries(current_code.result?.codes || {}).map(([key, value]) => (
                                                    <div className="flex justify-start flex-col mb-4 rounded-lg border border-black p-2 gap-2" key={key}>
                                                        <div className='flex flex-col md:flex-row justify-between items-center gap-2'>
                                                            <div className='font-bold'>{key}</div>
                                                            <div className='flex justify-center items-center w-full md:w-fit'>
                                                                <button className='rounded-lg px-4 py-1 text-md bg-black text-white hover:bg-white hover:text-black w-full md:w-fit'
                                                                    onClick={() => setUploadAudioModal(
                                                                        <TextCodeModal handleClose={() => setUploadAudioModal(null)} handleUpdateCode={handleUpdateCode} handleAddNewCode={handleAddNewCode} code={{ "name": key, "description": value }} />
                                                                    )}>
                                                                    {t('AiCodeDiscovery.AddCodeLabel')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='text-sm'>{value}</div>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className='flex flex-col rounded-md px-4 py-4' key={current_code.aiCoder.uuid}>
                                                <div className='text-lg font-bold mb-4'>
                                                    {t('AiCodeDiscovery.TableTitle')}
                                                </div>
                                                {/* Dynamically adjusting grid columns based on the number of interviews, adding fallback */}
                                                <div className={`grid items-center justify-center grid-cols-${Object.keys(current_code?.result?.interviews || {}).length + 1}`}>
                                                    <div className=''>
                                                        {t('AiCodeDiscovery.TableCorner')}

                                                    </div>
                                                    {Object.keys(current_code.result?.interviews || {}).map((interviewKey) => (
                                                        <div className='font-bold text-center border-l-2  my-1'>{interviewKey}</div>
                                                    ))}
                                                    {Object.entries(current_code.result?.codes || {}).map(([key, value], index) => (
                                                        <React.Fragment key={index}>
                                                            <div className='font-bold text-sm h-full border-t-2 my-1'>{key}</div>
                                                            {Object.entries(current_code.result?.interviews || {}).map(([interviewKey, InterviewValue]) => (
                                                                <div className="flex w-full justify-center gap-8 border-l-2 border-t-2 h-full items-center" key={interviewKey}>
                                                                    <div className={"h-fit rounded-md " + ((InterviewValue[key] == true || InterviewValue[key] == "true") ? "bg-lime-700" : "bg-red-700")}>
                                                                        {(InterviewValue[key] == true || InterviewValue[key] == "true") ? <CheckIcon className='text-white w-5 h-5' /> : <XMarkIcon className='text-white w-5 h-5' />}
                                                                    </div>
                                                                </div>
                                                            ))}

                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className='flex justify-end'>
                                                <button className='bg-red-800 rounded-md px-4 py-2 text-white w-fit'
                                                    onClick={() => { delete_code(current_code.aiCoder, false) }}
                                                >{t('AiCodeDiscovery.Delete')}</button>
                                            </div>
                                        </>
                                    )}
                                </div>

                            </Card> :
                            <Card className='my-2'><div className='flex items-center justify-between gap-4 '>
                                <div className='font-bold text-red-600'>
                                    {t('AiCodeDiscovery.Error')}
                                </div>
                                <button className='bg-red-800 rounded-md px-4 py-2 text-white'
                                    onClick={() => { delete_code(current_code, true) }}
                                >{t('AiCodeDiscovery.Delete')}</button>
                            </div>
                            </Card>
                    )
                })}

            </>
        )}

        {insufficientCredits}
    </div>;
}



export default AICodeView

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector) // Use the language detector
  .use(initReactI18next)
  .init({
    backend: {
      // path where resources get loaded from, in your case, from public/locales
      loadPath: '/locales/{{lng}}/translation.json',
    },
    detection: {
      // order and from where user language should be detected
      order: ['localStorage', 'navigator', 'querystring', 'cookie', 'sessionStorage', 'htmlTag'],

      // keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupSessionStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

      // optional html5 API usage
      checkWhitelist: true, // check if the detected language is in the whitelist
    },
    supportedLngs: ['pt', 'en'],
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;

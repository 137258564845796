import { Dialog, Transition } from '@headlessui/react'
import { PhotoIcon } from '@heroicons/react/24/outline'
import React, { useEffect } from 'react'
import { Fragment, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FileUploader } from "react-drag-drop-files";
import ReactAudioPlayer from 'react-audio-player';
import Loader from '../Loader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import cryptoJs from 'crypto-js';
import { uploadMeeting } from '../../Requests/MeetingRequests';
import { getTeamUuid } from '../../utils/cookies';
import { newAudioAnalysis, updateAnalysisLanguage } from '../../Requests/NewAnalysisRequests';
import UpgradedLoader from '../UpgradedLoader';
import { CpuChipIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { calculateProcessCost } from '../../utils/calcs';
const fileTypes = ["3ga", "8svx", "aac", "ac3", "aif", "aiff", "alac", "amr", "ape", "au", "dss", "flac", "flv", "m4a", "m4b", "m4p", "m4r", "mp3", "mpga", "ogg", "oga", "mogg", "opus", "qcp", "tta", "voc", "wav", "wma", "wv", "mp4"];


function ChangeLanguageModal({ handleClose, analysis }) {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation()
    const [language, setLanguage] = useState(analysis?.language)
    const [loading, setLoading] = useState(false)

    const closeModal = () => {
        handleClose()
        setOpen(false)
    }


    const handleSelectChange = (event) => {
        setLanguage(event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        let data = {
            "language": language,
            "team_uuid": getTeamUuid(),
            "analysis_uuid": analysis.uuid
        }
        updateAnalysisLanguage(data).then((res) => {
            navigate("/project/" + analysis.project?.uuid)
        }).catch((e) => {
            setLoading(false)
        })
        /*
        setUploading(true)
        e.preventDefault();
        if (!encryptedFile) {
            console.error('Please encrypt the file before submitting.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('meeting_name', e.target.meeting_name.value || "Meeting " + new Date().toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour24: true }));
        formData.append('team_uuid', "9f45466d-c3a7-49c5-a07c-4f80b8a8afcf");
        uploadMeeting(formData).then((res) => {
            navigate("/meetings")
            setUploading(false)
        }).catch(() => {
            setUploading(false)
        })*/

    };


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex w-full min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 h-full ">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                                >
                                    {t('Audio.AudioUpload.Title')}
                                </Dialog.Title>


                                <form className='flex w-full h-full flex-col justify-between gap-8' onSubmit={handleSubmit}>
                                    <div className='flex gap-4 items-center mt-6'>
                                        <ExclamationTriangleIcon className='w-14 h-14 text-red-700'></ExclamationTriangleIcon>
                                        <div className='text-sm'>{t('Audio.AudioUpload.Step2.ChangeLanguageWarning')}</div>
                                    </div>
                                    <div>
                                        <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                                            {t('Audio.AudioUpload.Step2.LanguageLabel')}
                                        </label>
                                        <select
                                            id="location"
                                            name="location"
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-black sm:text-sm sm:leading-6"
                                            value={language.split("_")[0]}
                                            onChange={handleSelectChange}
                                        >
                                            <option>  {t('Audio.AudioUpload.Step2.LanguageOption1')}</option>
                                            <option>  {t('Audio.AudioUpload.Step2.LanguageOption2')}</option>
                                            <option>  {t('Audio.AudioUpload.Step2.LanguageOption3')}</option>
                                        </select>
                                    </div>
                                    <div className='flex justify-end'>
                                        <button className='text-sm bg-black text-white rounded-xl h-fit px-3 py-2 '
                                            disabled={loading}
                                        >
                                            <div className='flex justify-between items-center gap-3'>
                                                <div>{t('MeetingCard.StatusProcess')}</div>
                                                <div className='flex items-center gap-1'>
                                                    <div className='font-bold'>{calculateProcessCost(analysis?.duration).toFixed(2)}</div>

                                                    <CpuChipIcon className='w-5 h-5' />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}

export default ChangeLanguageModal

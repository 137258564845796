import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate, useParams } from "react-router-dom";
import teamIcon from "../../Assets/Teams/team.png"
import { useTranslation } from "react-i18next";
import { onboardTeamMember } from "../../Requests/TeamRequests";
import { getTeamUuid } from "../../utils/cookies";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { updateMeetingTranscript } from "../../Requests/MeetingRequests";
import { Bars3BottomLeftIcon, Bars4Icon, ClipboardIcon, ClockIcon, PencilIcon } from "@heroicons/react/24/outline";
import { formatTime } from "../../utils/calcs";
import ReactAudioPlayer from "react-audio-player";

export default function ViewTranscription({ handleClose, transcription, setTranscription, meeting, view_mode }) {
    const { meeting_uuid } = useParams();
    const [open, setOpen] = useState(true);
    const { t } = useTranslation();
    const [editableTranscriptions, setEditableTranscriptions] = useState([]);
    const [mode, setMode] = useState("view")

    useEffect(() => {
        setEditableTranscriptions(transcription);
    }, [transcription]);

    const handleEdit = (index, newText) => {
        // Update the specific line with the new text
        const updatedTranscriptions = editableTranscriptions.map((item, i) => {
            if (i === index) {
                return { ...item, editablePart: newText };
            }
            return item;
        });
        setEditableTranscriptions(updatedTranscriptions);
        setTranscription(updatedTranscriptions);
    };

    const handleSave = () => {
        setMode("view")
        // Join the lines together with "\n" and save it
        const newTranscription = editableTranscriptions.map(item => item.staticPart + item.editablePart).join("\n");
        let data = {
            "meeting_uuid": meeting_uuid,
            "updated_transcription": newTranscription
        }
        updateMeetingTranscript(data).then((res) => {

        }).catch((e) => {

        })
    };

    const handleSetEdit = () => {
        setMode("edit")
    }

    const closeModal = () => {
        handleClose();
        setOpen(false);
    };

    const groupBySpeaker = (transcriptions) => {
        const grouped = [];
        let currentGroup = null;
        let currentSpeaker = '';

        transcriptions.forEach((line, index) => {
            const matches = line.staticPart.match(/\[(\d+(?:\.\d+)?)-(\d+(?:\.\d+)?)\]\s+((?:Speaker\s+)?[\w\s]+):/);
            if (matches && matches.length === 4) {
                const startTime = matches[1];
                const endTime = matches[2];
                const speaker = matches[3];
                if (currentGroup && currentGroup.speaker === speaker) {
                    currentGroup.lines.push(line);
                    currentGroup.endTime = endTime;
                } else {
                    if (currentGroup) {
                        grouped.push(currentGroup);
                    }
                    currentGroup = {
                        speaker,
                        startTime,
                        endTime,
                        lines: [line]
                    };
                    currentSpeaker = speaker;
                }
            }
            if (index === transcriptions.length - 1 && currentGroup) {
                grouped.push(currentGroup);
            }
        });

        return grouped;
    };

    const groupedTranscriptions = groupBySpeaker(editableTranscriptions);

    const [viewGrouped, setViewGrouped] = useState(false)
    const [timeVisibility, setTimeVisibility] = useState(true)

    function transformTimeFormat(staticPart) {
        const matches = staticPart.match(/\[(\d+(?:\.\d+)?)-(\d+(?:\.\d+)?)\]\s+((?:Speaker\s+)?[\w\s]+):/);

        if (matches && matches.length === 4) {
            const startTime = parseFloat(matches[1]);
            const endTime = parseFloat(matches[2]);
            const formattedStartTime = formatTime(startTime, 0); // Use 1 decimal place
            const formattedEndTime = formatTime(endTime, 0); // Use 1 decimal place
            const speaker = matches[3];

            return timeVisibility ? <span ><span className=" cursor-pointer underline" onClick={() => { seekToTime(startTime) }}>[{formattedStartTime} - {formattedEndTime}]</span> <span className="font-bold no-underline">{speaker}:</span></span> : <span className="font-bold">{speaker}:</span>;
        }
        return staticPart; // Return unchanged if no match
    }


    const playerRef = useRef()


    const seekToTime = async (timeInSeconds) => {
        // Ensure the audio player reference is current and the audio element is accessible
        if (playerRef.current) {
            playerRef.current.currentTime = timeInSeconds;
            try {
                await playerRef.current.play();
            } catch (error) {
            }
        }
    };


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-none ">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 max-h-screen">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="flex flex-col max-h-screen relative transform overflow-none rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full md:max-w-7xl sm:p-6">
                                <Dialog.Title
                                    as="h3"
                                    className="pb-4 text-lg font-medium leading-6 text-gray-500 text-center"
                                >
                                    {t('TranscriptionModal.TranscriptionLabel')}
                                </Dialog.Title>
                                {meeting?.file_url && <div className="w-full pb-2">
                                    <audio controls
                                        src={meeting.file_url}
                                        className="w-full"
                                        ref={playerRef}
                                    />
                                </div>
                                }

                                <div className="w-full flex flex-col md:flex-row justify-center md:justify-between mb-2 gap-2 ">

                                    <div className="w-full flex flex-col md:flex-row justify-center md:justify-start mb-2 gap-2 ">
                                        <button className={"rounded-md text-sm border-2 border-black px-4 py-2 " + (!viewGrouped ? "bg-black text-white" : "bg-white text-black")}
                                            onClick={() => setViewGrouped(!viewGrouped)}
                                        >
                                            {viewGrouped ? <div className="flex items-center gap-2"><Bars3BottomLeftIcon className="w-4 h-4" />
                                                {t('TranscriptionModal.Lines')}
                                            </div> : <div className="flex items-center gap-2"><Bars4Icon className="w-4 h-4" />
                                                {t('TranscriptionModal.Paragraphs')}
                                            </div>}
                                        </button>
                                        <button className={"rounded-md text-sm border-2 border-black px-4 py-2 " + (timeVisibility ? "bg-black text-white" : "bg-white text-black")}
                                            onClick={() => setTimeVisibility(!timeVisibility)}
                                        >
                                            <div className="flex gap-2 items-center">
                                                <ClockIcon className="w-4 h-4" />
                                                {t('TranscriptionModal.Time')}
                                            </div>
                                        </button>
                                    </div>
                                    <div className="w-full flex flex-col md:flex-row justify-center md:justify-end mb-2 gap-2 ">
                                        <CopyToClipboard text={transcription}>
                                            <button className="border-2 border-black bg-white text-black rounded-md px-4 py-2 text-sm hover:bg-black hover:text-white flex justify-center items-center gap-2">
                                                <ClipboardIcon className=" w-4 h-4" />
                                                {t('TranscriptionModal.CopyTextLabel')}
                                            </button>
                                        </CopyToClipboard>
                                        {!(view_mode == "public") && <button className="border-2  border-black bg-black text-white rounded-md px-4 py-2 text-sm flex items-center justify-center gap-2"
                                            onClick={() => {
                                                mode == "view" ? handleSetEdit() : handleSave();
                                                seekToTime(50)
                                            }}
                                        >
                                            <PencilIcon className=" w-4 h-4" />
                                            {mode == "view" ? t('TranscriptionModal.EditButton') : t('TranscriptionModal.SaveButton')}
                                        </button>}
                                    </div>
                                </div>
                                <div className="overflow-y-auto ">
                                    {mode == "view" ? <div>
                                        {viewGrouped ?
                                            (groupedTranscriptions.map((group, groupIndex) => (
                                                <div key={groupIndex} className="text-base my-2">
                                                    <div className="text-base mt-0">
                                                        <span className=" cursor-pointer underline" onClick={() => { seekToTime(group.startTime) }}>{timeVisibility && ("[" + [formatTime(group.startTime, 0) + "-" + formatTime(group.endTime, 0)] + "]")}</span> <span className="font-bold">{group.speaker}</span>:
                                                        {group.lines.map((line, lineIndex) => (
                                                            <span key={lineIndex}>
                                                                {" "}{line.editablePart}

                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))) :
                                            editableTranscriptions.map((line, index) => {
                                                if (line.staticPart.length > 1) {
                                                    return <div className="text-base my-2">
                                                        <div className="text-base mt-0">{transformTimeFormat(line.staticPart)} {line.editablePart}</div>
                                                    </div>
                                                }
                                            })}

                                    </div> : <>{editableTranscriptions.map((line, index) => {
                                        if (line.staticPart.length > 1) {
                                            return (
                                                <div key={index} className="flex flex-col md:flex-row  gap-4 items-start md:items-center my-2">
                                                    <div className="text-base whitespace-nowrap">{transformTimeFormat(line.staticPart)}</div>
                                                    <textarea
                                                        value={line.editablePart}
                                                        onChange={(e) => handleEdit(index, e.target.value)}
                                                        className="flex md:ml-1 border rounded w-full h-fit"
                                                        rows={1}
                                                    />
                                                </div>
                                            )
                                        };
                                    })}
                                    </>}
                                </div>
                                <div className="w-full flex justify-end items-center gap-4 mt-2">

                                    <button className="border-2 border-black bg-black text-white rounded-md px-4 py-2 text-sm"
                                        onClick={handleClose}
                                    >
                                        {t('TranscriptionModal.CloseLabel')}
                                    </button>

                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    );
}

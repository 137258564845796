import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getMeeting } from '../../../../Requests/MeetingRequests';
import ExpandedMeetingCard from '../../../../Components/Meetings/ExpandedMeetingCard';
import { AreaChart, Card, Title } from "@tremor/react";
import ExpandedAnalysisCard from '../../../../Components/Analysis/ExpandedAnalysisCard';


function Analys() {
    return (
        <div className='md:pl-64 py-12 w-full flex justify-center h-full px-4'>
            <div className='flex flex-col w-full justify-center items-center h-full'>
                <div className='bg-white max-w-7xl rounded-2xl w-full h-full shadow-xl mx-8  '>
                    <ExpandedAnalysisCard />
                </div>
            </div>

        </div>
    )
}

export default Analys

import React, { useState, useEffect } from 'react';
import { Card, DonutChart, Flex, Legend, Metric, Text } from '@tremor/react';
import { formatTime } from '../../utils/calcs';

function OverallParticipationCard({ duration, fragment, t, speakerNames }) {
    const [participationData, setParticipationData] = useState([]);

    useEffect(() => {
        // Function to replace keys with speaker names if available
        const replaceKeysWithSpeakerNames = (entries) => {
            return entries.map(([key, value]) => {
                // Replace the key with the speaker name if it exists in speakerNames
                const name = speakerNames && speakerNames[key] ? speakerNames[key] : key;
                return { name, value: parseFloat(value) };
            });
        };


        if (fragment.result) {
            const entries = Object.entries(JSON.parse(fragment.result));
            const replacedEntries = replaceKeysWithSpeakerNames(entries);
            setParticipationData(replacedEntries);
        }
    }, [fragment.result, speakerNames]);

    // Colors for each category
    const colors = ["emerald-500", "yellow-500", "purple-500", "red-500", "lime-500", "zinc-500", "gray-500", "indigo-500", "orange-500", "black", "teal-500", "cyan-500"];

    return (
        <Card className='flex flex-col h-full justify-between max-h-96'>
            {fragment.result ?
                <>
                    <div>
                        <Flex alignItems="start">
                            <Text>{t('ExpandedMeedingCard.TimeSpokenLabel')}</Text>
                        </Flex>
                        <Flex className="space-x-3 truncate" justifyContent="start" alignItems="baseline">
                            <Metric>{formatTime(duration)}</Metric>
                        </Flex>
                    </div>
                    <div>
                        <DonutChart
                            className="mt-6 max-h-32"
                            data={participationData}
                            category="value"
                            index="name"
                            colors={colors.slice(0, participationData.length)} // Use a slice of the colors array to match the number of entries
                            showAnimation={true}
                            showLabel={false}
                        />
                    </div>
                    <div className='w-full flex justify-center items-center'>
                        <Legend
                            className="mt-3"
                            categories={participationData.map(entry => `${entry.name} (${entry.value}%)`)}
                            colors={colors.slice(0, participationData.length)} // Use a slice of the colors array to match the number of entries
                        />
                    </div>
                </>
                : <div className='min-w-full h-32 bg-gray-200 animate-pulse'></div>}
        </Card>
    );
}

export default OverallParticipationCard;

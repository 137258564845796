import React, { useState } from "react";
import BillingController from "./Payments/BillingController";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function UserSettings({ ...children }) {
  const [mountedSettings, setMoutedSetting] = useState(children.children);
  const { t } = useTranslation();
  const [mountedSettingsName, setMoutedSettingName] = useState(
    children.tabName
  );
  return (

    <div className="h-full">
      <div className="flex flex-1 flex-col md:pl-64 h-full">
        <main className="flex-1 h-full">
          <div className="py-6  h-full">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  h-full">
              <div className="border-b border-gray-200 pb-5 sm:pb-0 ">
                {mountedSettings}
              </div>
            </div>
            <div className="mx-0 w-full px-4 sm:px-6 md:px-8 mt-10"></div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default UserSettings;

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import teamIcon from "../../Assets/Teams/team.png"
import { useTranslation } from "react-i18next";
import { onboardTeamMember } from "../../Requests/TeamRequests";
import { getTeamUuid } from "../../utils/cookies";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import Loader from "../Loader";
import SuccessPopup from "../PopupNotifications/SuccessPopup";
import { deleteAnalysis, deleteAudio, deleteTranscript } from "../../Requests/NewAnalysisRequests";

export default function DeleteAnalysisBlocksModal({ analysis, handleClose, setDeletePopUp, updateAnalysis }) {
    const [open, setOpen] = useState(true);
    const { t } = useTranslation()
    const [saving, setSaving] = useState(false)

    const closeModal = () => {
        updateAnalysis()
        handleClose()
        setOpen(false)
    }

    const navigate = useNavigate()





    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full w-full md:max-w-2xl sm:p-6">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                                >
                                    {t('DeleteModal.Title')}
                                </Dialog.Title>
                                <div className="text-sm text-red-600 mt-2 text-center">{t('DeleteModal.Description')}</div>
                                {analysis?.file?.url && <div className="flex flex-col gap-2 mt-2 py-4 ">
                                    <div className="flex items-center justify-between gap-4">
                                        <div className="flex flex-col items-start justify-center px-4">
                                            <div>
                                                {t("DeleteModal.DeleteAudioTitle")}
                                            </div>
                                            <div className="text-sm text-gray-500">
                                                {t("DeleteModal.DeleteAudioDescription")}
                                            </div>
                                        </div>
                                        <button className="bg-red-700 text-white rounded-xl px-4 py-2 whitespace-nowrap text-sm"
                                            type="button"
                                            onClick={() => {
                                                deleteAudio(analysis.uuid).then((res) => {
                                                    setDeletePopUp(<SuccessPopup text="Apagado" handleClose={() => { setDeletePopUp(false) }} />)
                                                    updateAnalysis()
                                                    handleClose()
                                                })
                                            }}
                                        >
                                            {t("DeleteModal.DeleteAudioButton")}
                                        </button>
                                    </div>
                                </div>}
                                {analysis.fragments && analysis.fragments.some(fragment => fragment.type === "edited_transcription") &&
                                    <div className="flex flex-col gap-2 mt-2 py-4">
                                        <div className="flex items-center justify-between gap-4">
                                            <div className="flex flex-col items-start justify-center px-4">
                                                <div>
                                                    {t("DeleteModal.DeleteTranscriptionTitle")}
                                                </div>
                                                <div className="text-sm text-gray-500">
                                                    {t("DeleteModal.DeleteTranscriptionDescription")}
                                                </div>
                                            </div>
                                            <button className="bg-red-700 text-white rounded-xl px-4 py-2 whitespace-nowrap text-sm"
                                                type="button"
                                                onClick={() => {
                                                    deleteTranscript(analysis.uuid).then((res) => {
                                                        setDeletePopUp(<SuccessPopup text="Apagado" handleClose={() => { setDeletePopUp(false) }} />);
                                                        updateAnalysis();
                                                        handleClose();
                                                    });
                                                }}
                                            >
                                                {t("DeleteModal.DeleteTranscriptionButton")}
                                            </button>
                                        </div>
                                    </div>
                                }
                                <div className="flex flex-col gap-2 mt-2 py-4 ">
                                    <div className="flex items-center justify-between gap-4">
                                        <div className="flex flex-col items-start justify-center px-4">
                                            <div>
                                                {t("DeleteModal.DeleteMeetingTitle")}
                                            </div>
                                            <div className="text-sm text-gray-500">
                                                {t("DeleteModal.DeleteMeetingDescription")}
                                            </div>
                                        </div>
                                        <button className="bg-red-700 text-white rounded-xl px-4 py-2 whitespace-nowrap text-sm"
                                            type="button"
                                            onClick={() => {
                                                deleteAnalysis(analysis.uuid).then((res) => {
                                                    navigate("/projects")
                                                }).catch((e) => {

                                                })
                                            }}
                                        >
                                            {t("DeleteModal.DeleteMeetingButton")}</button>
                                    </div>
                                </div>

                                <div className="w-full flex justify-end mt-2">
                                    <button className="border border-black px-4 py-2 rounded-xl text-sm"
                                        type="buton"
                                        onClick={closeModal}
                                    >
                                        {t("DeleteModal.CancelLabel")}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    );
}

import { CircleStackIcon } from '@heroicons/react/24/outline';
import React from 'react'
import { formatDateTime } from '../../utils/calcs';
import { Navigate, useNavigate } from 'react-router-dom';
import { Card, DonutChart, Flex, Legend, Metric, Text } from '@tremor/react';
import logo from "../../Assets/logo.png"
import SectionHeader from '../../Components/GeneralComponents/SectionHeader';
const resultados =
    [{
        "Title": "1 - Debate PS vs IL | Pedro Nuno Santos vs Rui Rocha",
        "Abstract": "Aqui está um resumo de 10 linhas dos principais pontos do debate:A moderadora Clara apresentou Pedro Nuno Santos e Rui Rocha, defendendo modelos económicos diferentes. Pedro Nuno Santos evitou responder diretamente sobre a posição do PS/Açores, reiterando respeito pela sua autonomia. Rui Rocha criticou Pedro Nuno Santos por poder criar instabilidade na campanha. Clara perguntou sobre as suas visões divergentes de crescimento económico. Rui Rocha acusou Pedro Nuno Santos de falta de ambição. Este defendeu uma economia mais sofisticada e diversificada. Debateram também saúde, educação, habitação e transportes, com discordâncias sobre o papel do estado versus privados. Clara encerrou agradecendo a discussão.",
        "Winner": "Na minha opinião, este debate foi equilibrado e ambos os políticos defenderam as suas posições de forma consistente, ainda que discordantes em muitos pontos. No entanto, diria que Rui Rocha esteve mais convincente, pois conseguiu pressionar Pedro Nuno Santos a tomar uma posição clara sobre o caso dos Açores, o que este procurou evitar de forma insistente. Além disso, Rui Rocha apresentou argumentos mais incisivos ao acusar o adversário de falta de ambição económica, ao passo que as respostas de Pedro Nuno Santos pareceram mais vagas e genéricas. Contudo, é natural que num debate ideológico como este cada parte defenda a sua visão de forma veemente. No global, ambos cumpriram o objetivo de esclarecer as diferentes abordagens liberais e socialistas aos principais temas. Desta forma, embora eu considere que Rui Rocha esteve ligeiramente por cima, não foi um vencedor claro, tendo sido um debate produtivo e esclarecedor para o espectador poder comparar as propostas de cada um.",
        "Url": "https://app.meetpulp.com/meeting/public/bc871dbf-9e72-4d84-9cc4-534ecd367627",
        "WinnerName": "Rui Rocha"
    },
    {
        "Title": "2 - Debate Chega vs PAN | André Ventura vs Inês Sousa Real",
        "Abstract": "Eis um resumo de 10 linhas dos principais pontos discutidos no debate:O moderador começa o debate entre André Ventura e Inês Souza Real. Pergunta a Ventura sobre a situação nos Açores após as eleições, onde o Chega teve bom resultado. Ventura diz ser necessária uma solução de maioria para estabilidade. A moderador pressiona por uma resposta sobre apoio a governo minoritário de direita, mas Ventura evita responder. Souza Real critica o papel do Chega nos Açores. Discute-se também a Madeira, onde o governo demitiu. A moderador pergunta a Souza Real sobre o acordo do PAN, que diz respeitar a autonomia mas garantir estabilidade. Ventura acusa o PAN de querer apenas manter-se no poder e evitar eleições.",
        "Winner": "Na minha opinião, este debate terminou de forma equilibrada, sem um claro vencedor. O André Ventura conseguiu defender bem as posições do Chega, mostrando compreensão pelos protestos da polícia e criticando o PAN por alguns votos contra propostas do Chega no parlamento. No entanto, a Inês Souza Real também respondeu bem às acusações, defendendo o trabalho do PAN em aprovar medidas importantes e o respeito pela autonomia da Madeira e dos Açores. Ambos concordaram em taxar lucros excessivos de empresas petrolíferas e bancos, o que mostra pontos de convergência. Em suma, acho que nenhum dos dois partidos conseguiu dar um 'golpe decisivo' no outro. O debate manteve-se cordial e equilibrado nas trocas de argumentos. Portanto, diria que terminou sem um vencedor claro, com ambos a defenderam as suas posições de forma consistente.",
        "Url": "https://app.meetpulp.com/meeting/public/d217c7c2-4232-4b0f-90ac-7cce16622780",
        "WinnerName": "Empate"
    },

    {
        "Title": "3 - Debate PCP vs PAN | Paulo Raimundo vs Inês Sousa Real",
        "Abstract": "Aqui está um resumo de 10 linhas:O moderador A dá as boas-vindas a Paulo Raimundo do PCP e Inês Sousa Real do PAN ao debate. Inês agradece com o braço engessado devido a um acidente. Discutem a corrupção, ambos os partidos dizem que deve ser combatida. Inês defende a regulação do lóbi para mais transparência. Paulo questiona a eficácia disso. Inês dá o exemplo de um contrato que seria revelado. O moderador pergunta se só o lóbi chega ou se precisam de mais medidas. Discutem depois a agricultura e se devem aliviar regulamentos. Inês defende soluções equilibradas em vez de desregulação e critica a falta de apoio do PCP às alterações climáticas.",
        "Winner": "Na minha opinião, a Inês Sousa Real teve uma prestação ligeiramente melhor no debate. Apesar de o Paulo Raimundo ter levantado pontos válidos sobre os custos que oneram os agricultores, ficou-se um pouco por uma resposta direta à questão colocada sobre aliviar ou não as regulações ambientais. Já a Inês defendeu soluções equilibradas, apoiando práticas sustentáveis em vez da desregulação, e criticou a falta de apoio do PCP a medidas de combate às alterações climáticas. Em geral, a Inês conseguiu responder mais diretamente às questões colocadas e apresentou propostas mais concretas do que o Paulo Raimundo. No entanto, ambos trouxeram pontos interessantes à discussão sobre temas como a corrupção, a agricultura e o acesso à saúde. Foi um debate construtivo e esclarecedor sobre estas matérias importantes.",
        "Url": "https://app.meetpulp.com/meeting/public/da63b540-f322-41f2-8307-afb5cb5d6831",
        "WinnerName": "Inês de Sousa Real"
    },

    {
        "Title": "4 - Debate AD vs BE - Luís Montenegro vs Mariana Mortágua",
        "Abstract": "Eis um resumo de 10 linhas dos principais pontos debatidos:O debate começou com uma pergunta sobre a perda de estatuto parlamentar do Bloco de Esquerda nos Açores. Mortágua respondeu que o BE continua o quarto maior partido e foi a principal oposição ao governo de direita. Discutiram-se depois os cuidados de saúde, com o BE a defender mais soluções públicas e o PSD parcerias público-privadas. Mortágua criticou a falta de propostas concretas do PSD, que disse resolver imediatamente as listas de espera com médicos de família e privados. Por fim, debateram-se soluções para habitação, onde também divergiram nas opções públicas ou privadas preferidas por cada partido.",
        "Winner": "Na minha opinião, este debate foi mais equilibrado do que um claro vencedor. No entanto, se tivesse de escolher, diria que a Mariana Mortágua esteve mais convincente nas suas argumentações. A Mariana Mortágua conseguiu defender de forma consistente as soluções mais públicas preconizadas pelo Bloco de Esquerda para a saúde e habitação, mostrando-se menos recetiva aos argumentos do privado defendidos pelo PSD. Embora o Luís Montenegro tenha tentado apresentar propostas concretas para reduzir listas de espera na saúde, ficou-me a ideia que as mesmas assentavam demasiado no privado sem garantir o acesso universal. A Mariana Mortágua também soube contrariar a caracterização da situação nos Açores feita pelo PSD, demonstrando que o Bloco de Esquerda continua a ser uma força relevante na oposição regional. Em suma, apesar de ambos terem tido momentos fortes, a Mariana Mortágua pareceu-me mais convincente na defesa das soluções predominantemente públicas preconizadas pelo seu partido.",
        "Url": "https://app.meetpulp.com/meeting/public/0888869e-bdb3-4a05-a000-8b69d5195d37",
        "WinnerName": "Mariana Mortágua"
    },
    {
        "Title": "5 - Debate Chega vs IL - André Ventura vs Rui Rocha",
        "Abstract": "Aqui está um resumo de 10 linhas dos principais pontos debatidos:Ventura defendeu cortes de impostos e aumento de pensões pelo Chega. Rocha criticou esta posição como irrealista. Debateu-se o aumento de pensões proposto pelo Chega, com Rocha a dizer que não é sustentável. Ventura acusou Rocha de ser contra ajudar os mais vulneráveis. Falaram-se também de imigração, com Ventura a defender mais controlo e Rocha a dizer que apoia se sustentarem. Quando questionado sobre expulsão de imigrantes criminosos, Rocha evitou responder diretamente, irritando Ventura. Por fim, Rocha propôs governo minoritário de IL e PSD com apoio do Chega, mas Ventura recusou-se a assinar qualquer documento.",
        "Winner": "Na minha opinião, o candidato que melhor se apresentou no debate foi o Rui Rocha. Apesar de ambos defenderem posições fortes nas suas áreas ideológicas, o Rui Rocha conseguiu argumentar as suas ideias de forma mais consistente e fundamentada. O André Ventura focou-se mais em afirmações contundentes sobre temas polémicos como a imigração e reformas nas pensões, mas não deu muitos detalhes concretos sobre como financiaria as suas propostas. Já o Rui Rocha questionou sistematicamente como seriam financiadas as medidas do Chega e colocou o foco na sustentabilidade orçamental a longo prazo. Além disso, o Rui Rocha soube defender as ideias liberais do seu partido de forma ponderada, realçando a importância do crescimento económico e das reformas estruturais, ao invés de apenas promessas de gastos imediatos. No final, conseguiu ainda colocar o André Ventura numa posição difícil ao desafiá-lo a comprometer-se com um governo de direita, o que este recusou de forma pouco clara. Em suma, apesar de ambos terem defendido as suas visões de forma veemente, o Rui Rocha conseguiu apresentar-se como mais preparado e fundamentado nos seus argumentos ao longo do debate.",
        "Url": "https://app.meetpulp.com/meeting/public/adc430b6-7e95-4ba9-8d18-be0efb502b42",
        "WinnerName": "Rui Rocha"
    },
    {
        "Title": "7 - Debate BE vs Livre - Mariana Mortágua vs Rui Tavares",
        "Abstract": "Aqui está um resumo de 10 linhas do debate:O moderador deu as boas-vindas a Mariana Mortágua e Rui Tavares para debaterem as eleições de 10 de Março. Mortágua recusou-se a dar detalhes sobre a situação da sua avó em relação à lei de rendas de 2012, criticando apenas a lei. Tavares comentou que os debates devem seguir as regras acordadas.As propostas políticas foram discutidas, com Tavares a propor uma taxa sobre propriedades de luxo para habitação de emergência e Mortágua a apoiar a proibição de venda de casas a não-residentes. Tavares expressou preocupações legais com a proposta de Mortágua. Desemprego juvenil e emigração foram também debatidos, com ambos a enfatizarem aumentos salariais. Discordaram sobre o projeto-piloto de rendimento básico universal de LIVRE. O moderador agradeceu no final.",
        "Winner": "Eu acho que este debate foi equilibrado e ambos os participantes defenderam as suas posições de forma consistente. A Mariana Mortágua focou-se mais em criticar as políticas do governo anterior, nomeadamente a lei dos arrendamentos de 2012 que afetou a sua família. Contudo, evitou entrar em detalhes sobre a situação concreta da sua avó, o que poderia ter fortalecido a sua argumentação. O Rui Tavares concentrou-se mais em apresentar as propostas concretas do LIVRE, como a taxa sobre imóveis de luxo para habitação social ou o projeto-piloto de rendimento básico incondicional. Demonstrou também algumas preocupações com a proposta da Mariana Mortágua de proibir a venda de casas a não residentes, apontando potenciais problemas legais e de nacionalismo. No global, diria que o Rui Tavares esteve um pouco mais focado em apresentar soluções alternativas, enquanto a Mariana Mortágua adotou mais uma postura crítica em relação às políticas passadas. Contudo, ambos defenderam bem as suas posições e perspetivas para os principais problemas do país. Foi um debate construtivo e esclarecedor em que não houve um claro vencedor.",
        "Url": "https://app.meetpulp.com/meeting/public/f66e78eb-eff9-4cdf-b697-825b81194cbf",
        "WinnerName": "Rui Tavares"
    },
    {
        "Title": "8 - Debate IL vs PAN - Rui Rocha vs Inês Sousa Real",
        "Abstract": "Aqui está um resumo de 10 linhas:Rosa recebe Inês Souza Real do PAN e Rui Rocha da Iniciativa Liberal para debater. Discutem a possibilidade de coligações com o AAD nos Açores. Inês diz que o PAN regional analisará primeiro. Rui diz que a IL avaliará cada orçamento e apoiará menos impostos e patronagem. Debatem a economia verde e salários. Inês fala em empregos para mulheres. Rui questiona suas afirmações. Também discutem educação, saúde e habitação. Desafiam-se mutuamente e criticam votos passados. Rosa controla o debate.",
        "Winner": "Na minha opinião, este debate foi equilibrado e ambos os participantes defenderam bem as suas posições. No entanto, diria que Rui Rocha do Iniciativa Liberal conseguiu questionar e desafiar algumas das propostas da Inês Souza Real do PAN de forma mais eficaz. Embora a Inês tenha apresentado vários argumentos a favor das causas defendidas pelo PAN, o Rui pôs algumas dúvidas sobre números e estudos citados, mostrando vontade de ir mais além na análise. Contudo, ambos demonstraram conhecimento dos seus programas e souberam debater de forma construtiva. Foi um debate que permitiu esclarecer as posições de dois partidos com visões diferentes, mas com vontade de melhorar o país.",
        "Url": "https://app.meetpulp.com/meeting/public/4ce9cc1b-a2dd-41c2-a1f9-88fe64217065",
        "WinnerName": "Rui Rocha"
    },
    {
        "Title": "9 - Debate PS vs Livre - Pedro Nuno Santos vs Rui Tavares",
        "Abstract": "Eis um resumo de 10 linhas do debate:O debate começa com João a moderar e Rui Tavares e Pedro Nuno Santos a participar. João pergunta a Rui sobre o que a esquerda deveria fazer caso haja um governo minoritário de direita em Portugal. Rui não responde diretamente. João insiste na questão e Rui volta a não responder claramente. Pedro Nuno diz que a esquerda não tem capacidade para derrubar um governo sem apresentar moções de censura. João aborda a recuperação do tempo de serviço dos professores, mas Pedro Nuno e Rui não esclarecem quanto tempo levará o processo nem como será concretizado. Rui defende negociações na educação nos primeiros 100 dias de um governo de esquerda.",
        "Winner": "Do meu ponto de vista, este debate não teve um claro vencedor. Ambos os participantes tiveram momentos fortes e fracos. O Rui Tavares pareceu evasivo na questão colocada inicialmente sobre derrubar um governo minoritário de direita. Contudo, defendeu de forma convincente a necessidade de negociações rápidas para resolver problemas nos setores-chave como a educação. Já o Pedro Nuno Santos foi mais direto em algumas respostas, como quando afirmou que a recuperação integral do tempo de serviço dos professores poderá demorar um legislatura. No entanto, falhou em concretizar como seria feita essa recuperação. Em geral, ambos os políticos não foram totalmente claros em algumas respostas e esquivaram-se a comprometer-se com posições definitivas. O moderador João procurou pressioná-los a ser mais assertivos, o que nem sempre conseguiu. Assim, considero que este debate não teve um vencedor inequívoco. Cada participante teve momentos em que conseguiu defender melhor as suas ideias, mas também houve espaço para ambiguidades nas respostas que poderiam ter sido evitadas. Ficou patente que há ainda divergências na estratégia da esquerda em certas questões.",
        "Url": "https://app.meetpulp.com/meeting/public/bfa6e0e5-5237-44ae-a2a0-98a223aa5c86",
        "WinnerName": "Empate"
    },
    {
        "Title": "11 - Debate AD vs PCP Luís Montenegro vs Paulo Raimundo",
        "Abstract": "Eis um resumo de 10 linhas:O moderador João Adelino Faria deu as boas-vindas a Paulo Raimundo do CDU e Luís Montenegro do AD para debater propostas para Portugal e governança caso nenhum partido ganhe maioria. Raimundo disse que o CDU nunca apoiaria ou governaria com o AD devido a objetivos opostos. Montenegro afirmou que será primeiro-ministro se eleito pelo povo português com maioria ou maioria relativa. Raimundo propôs aumento de 7,5% nas pensões e de 15% nos salários, ao que Montenegro respondeu que as propostas não são viáveis financeiramente. Os partidos debateram outras propostas antes do moderador agradecer a ambos.",
        "Winner": "Pelas informações fornecidas no resumo, eu diria que Luís Montenegro teve um desempenho ligeiramente melhor no debate. Alguns pontos que noto: - Montenegro conseguiu defender a sua posição de que quereria ser primeiro-ministro caso o seu partido ganhasse as eleições ou obtivesse a maioria relativa, dando exemplos do passado. Isto deu-lhe um discurso mais coerente. - Quando questionado sobre uma possível vitória do PS sem maioria absoluta, Montenegro manteve-se firme na sua posição inicial, ao invés de especular. Isto mostra consistência. - Montenegro também conseguiu contrariar a proposta do aumento das pensões feita por Raimundo, apontando que o país não tem capacidade financeira para tal. Desta forma, defendia-se melhor economicamente. - Por outro lado, Raimundo não chegou a responder à questão sobre o custo da proposta de aumentos salariais, o que deixou essa parte da sua argumentação menos sólida. Assim, apesar de ambos terem defendido as suas propostas, Montenegro pareceu ter argumentos mais consistentes e bem fundamentados do ponto de vista económico-financeiro. No entanto, o resumo não é exaustivo, por isso esta é apenas uma opinião parcial com base na informação fornecida.",
        "Url": "https://app.meetpulp.com/meeting/public/6d8c0ed6-be00-40fa-8008-f2d517a0fab5",
        "WinnerName": "Luís Montenegro"
    },
    {
        "Title": "13 - Debate AD vs Pan - Luís Montenegro vs Inês Sousa Real",
        "Abstract": "Eis um resumo de 10 linhas do debate:Inês de Sousa Real e Luís Montenegro debateram vários temas como violência doméstica, pobreza feminina, direitos das mulheres, saúde, educação, impostos, habitação e ambiente. Inês defendeu as políticas e vitórias do PAN, criticando o falhanço do PSD. Montenegro questionou por que o PAN não conseguiu mais. Debateu-se também o papel do PAN e o fim da aproximação ao Chega. No fim, Montenegro disse que aguardam o Tribunal Constitucional sobre a eutanásia.",
        "Winner": "Na minha opinião, Inês de Sousa Real teve uma performance ligeiramente melhor no debate. Conseguiu defender eficazmente a posição do PAN em relação ao Chega, destacando os valores progressistas do partido em matérias como os direitos das mulheres e o combate à violência doméstica. Por outro lado, notou-se alguma incomodidade por parte de Luís Montenegro quando confrontado com estas questões, tendo chegado a rir-se durante a intervenção da Inês. No geral, a líder do PAN demonstrou maior habilidade argumentativa e conseguiu colocar o adversário político em posições desconfortáveis em alguns momentos. Contudo, o debate abordou vários temas e não foi totalmente desnivelado. Pode-se dizer que a Inês de Sousa Real levou a melhor, ainda que ligeiramente, nesta discussão televisiva.",
        "Url": "https://app.meetpulp.com/meeting/public/39878dae-9886-4569-8047-dbf4563bfb3a",
        "WinnerName": "Inês de Sousa Real"
    },
    {
        "Title": "14 - Debate BE vs PCP - Mariana Mortágua vs Paulo Raimundo",
        "Abstract": "Aqui está um resumo de 10 linhas:O moderador recebe Mariana Mortágua do Bloco de Esquerda e Paulo Raimundo do Partido Comunista Português para debater as eleições de 10 de Março. Discutem a chamada de Pedro Nuno Santos para um voto útil no PS. Mortágua vê isto como desleal, já que o PS pode precisar do BE ou PCP.Raimundo discorda, argumentando que o PS está errado em dizer que precisa de maioria. Critica o governo do PS por deixar o país em crise. Mortágua propõe um acordo pré-eleitoral com o PS, que recusou. Raimundo diz que o PCP não causará instabilidade, mas pressionará pelas suas propostas. Os participantes discutem divergências e concordâncias, especialmente sobre a UE e cooperação europeia na defesa.",
        "Winner": "Na minha opinião, o debate foi equilibrado e ambos os participantes defenderam bem as suas posições. No entanto, diria que Mariana Mortágua conseguiu apresentar as propostas do Bloco de Esquerda de forma mais concreta e detalhada, especialmente no que diz respeito à habitação. Ao contrário, Paulo Raimundo focou-se mais em criticar as propostas existentes e a atuação do PS, sem no entanto avançar alternativas tão específicas. Contudo, ambos concordaram em pontos importantes como a necessidade de cooperação europeia na defesa com respeito pela soberania. De um modo geral, considero que Mortágua esteve ligeiramente mais convincente na defesa das propostas do seu partido, embora o debate tenha sido construtivo e tenham defendido as suas posições de forma equilibrada.",
        "Url": "https://app.meetpulp.com/meeting/public/97cc16da-3ea1-4677-88b8-e2aea69d4e6b",
        "WinnerName": "Mariana Mortágua"
    },
    {
        "Title": "15 - Debate AD vs Chega - Luís Montenegro vs André Ventura",
        "Abstract": "Eis um resumo de 10 linhas:Luís Montenegro e André Ventura debateram vários temas políticos em Portugal. Discutiram forças de segurança e direito à greve, com opiniões divergentes sobre prazos para resolver problemas. Debateu-se também corrupção, com Montenegro a defender avaliação de políticas e Ventura mais penas e confisco de bens. As reformas nas pensões foram outro tópico, sem consenso sobre prazos e custos das propostas de cada um. Por fim, Montenegro apontou o PS como único rival, ao passo que Ventura viu PS e PSD como sistema estabelecido, apelando ao voto no Chega para mudança real.",
        "Winner": "Na minha opinião, o debate foi equilibrado e ambos os participantes defenderam as suas posições de forma enérgica. No entanto, diria que André Ventura conseguiu afirmar melhor as suas propostas e ideias, respondendo de forma mais direta às questões colocadas. Luís Montenegro pareceu mais cauteloso e evasivo em alguns momentos, talvez por ter de defender o estatu quo. Contudo, reconheço que estes debates raramente têm um vencedor claro e ambos cumpriram o objetivo de expor as suas visões perante os eleitores. Foi um debate útil para quem quer conhecer as alternativas políticas em discussão.",
        "Url": "https://app.meetpulp.com/meeting/public/550821ba-f7c9-429f-900a-b192dc2ae1bf",
        "WinnerName": "André Ventura"
    },
    {
        "Title": "17 - Debate Chega vs BE - André Ventura vs Mariana Mortágua",
        "Abstract": "Eis um resumo de 10 linhas dos principais pontos debatidos:O debate começou com Mariana Mortágua a defender a proposta do Bloco de Esquerda de proibir a compra de casas por não residentes para combater a especulação imobiliária. André Ventura defendeu quotas anuais para imigrantes. Mortágua criticou esta ideia, dizendo que sectores como agricultura dependem de 80% de mão-de-obra imigrante. Discutiram também habitação, corrupção e igualdade de género. Ao longo do debate, acusaram-se mutuamente de mentiras e de não terem soluções políticas factíveis. No final, João perguntou sobre os próximos passos dos respectivos partidos anti-sistema.",
        "Winner": "Na minha opinião, este debate foi bastante acalorado com ambos os candidatos a acusarem-se mutuamente. No entanto, pareceu-me que a Mariana Mortágua do Bloco de Esquerda conseguiu defender as suas posições de forma mais consistente e fundamentada com argumentos. Embora o André Ventura também tenha apresentado as suas ideias, a Mariana foi capaz de apontar falhas e contra-argumentar de forma mais sólida, por exemplo ao falar dos impactos da proposta de quotas para imigrantes na agricultura e construção civil. Além disso, demonstrou melhor conhecimento dos dados e estudos sobre a problemática da habitação em Portugal, citando o Banco de Portugal. Portanto, diria que a Mariana Mortágua saiu vencedora deste debate por ter conseguido sustentar as suas posições de forma mais sólida com factos e análises, ao invés de apenas acusações como fez por vezes o André Ventura.",
        "Url": "https://app.meetpulp.com/meeting/public/2d0c8d7c-ee81-414b-81a4-47dbc80a67d1",
        "WinnerName": "Mariana Mortágua"
    },
    {
        "Title": "18 - Debate Livre vs PAN - Rui Tavares vs Inês Sousa Real",
        "Abstract": "Eis um resumo de 10 linhas:O moderador João Adolino deu as boas-vindas a Inês de Sousa Real do PAN e Rui Tavares do LIVRE para o debate. Rui Tavares defendeu que é necessária reforma da justiça para recuperar a confiança entre os poderes judiciário e político, enquanto Inês concordou que a reforma está atrasada, especialmente no que diz respeito à regulação do lóbi. João questionou se a próxima legislatura deveria ser o momento para a reforma dado casos recentes de relevância pública. Rui apoiou um debate nacional sobre como melhorar a transparência e reduzir os tempos de processamento de casos. A discussão depois virou-se para os recursos naturais, com Rui defendendo uma gestão pública de litio e hidrogénio e Inês preocupada com impactos ambientais de projetos atuais.",
        "Winner": "Na minha opinião, ambos os candidatos apresentaram pontos de vista válidos durante o debate. No entanto, diria que Rui Tavares teve uma performance ligeiramente melhor por ter conseguido defender as suas ideias de forma mais consistente e estruturada. Ao longo do debate, Rui Tavares mostrou-se capaz de responder diretamente às questões colocadas pelo moderador, apresentando soluções concretas para os problemas levantados, como a necessidade de maior transparência e participação cidadã no sistema judicial, ou a gestão pública dos recursos naturais portugueses para benefício dos cidadãos. Por outro lado, embora Inês de Sousa Real também tenha abordado temas pertinentes como as reformas na justiça e a proteção ambiental, as suas respostas pareceram em alguns momentos mais genéricas e menos focadas em propostas específicas. Desta forma, considero que Rui Tavares conseguiu 'ganhar' o debate por ter conseguido defender as ideias do LIVRE de forma mais sólida e estruturada. No entanto, vale a pena notar que ambos os candidatos trouxeram contributos interessantes para a discussão dos assuntos em debate.",
        "Url": "https://app.meetpulp.com/meeting/public/0c33d925-9dc5-464b-be80-a68ad4c2e4c6",
        "WinnerName": "Rui Tavares"
    },
    {
        "Title": "19 - Debate PS vs Chega - Pedro Nuno Santos vs André Ventura",
        "Abstract": "Aqui está um resumo de 10 linhas:O debate começa entre Pedro Nuno Santos e André Ventura. Sara modera e explica a ordem das intervenções. Pergunta a Pedro Nuno Santos sobre a libertação de três detidos na Madeira. André Ventura dá condolências a alguém nas eleições por uma desgraça familiar.Discutem-se políticas fiscais, com André Ventura a propor cortes de impostos e Pedro Nuno Santos a duvidar que se consigam financiar dentro do orçamento. Debate-se também saúde, com Pedro Nuno Santos a propor tempo mínimo para médicos no SNS e André Ventura a preferir aumentos salariais.Para terminar, André Ventura questiona a preparação de Pedro Nuno Santos para primeiro-ministro, e este responde questionando a credibilidade de André Ventura sobre uma garantia anterior de governo de direita que agora recusa identificar a fonte.",
        "Winner": "Na minha opinião, Pedro Nuno Santos teve uma performance ligeiramente melhor no debate. Apesar de André Ventura ter defendido as propostas do Chega de forma enérgica, particularmente nos tópicos fiscais, não conseguiu responder de forma convincente quando questionado sobre a credibilidade das suas declarações anteriores. Por outro lado, Pedro Nuno Santos demonstrou maior preparação nos diferentes temas discutidos, desde a saúde à economia, e colocou em causa a viabilidade financeira de algumas medidas defendidas por André Ventura. No entanto, ambos os participantes tiveram momentos fortes e conseguiram defender as suas posições. Se tivesse de escolher um vencedor, diria que Pedro Nuno Santos esteve ligeiramente por cima, mas o debate no seu conjunto foi interessante e permitiu debater ideias diferentes.",
        "Url": "https://app.meetpulp.com/meeting/public/dd7207d9-daee-4be9-9bfe-7a3683a583f3",
        "WinnerName": "Pedro Nuno Santos"
    },
    {
        "Title": "20 - Debate IL vs PCP - Rui Rocha vs Paulo Raimundo",
        "Abstract": "Eis um resumo de 10 linhas do segmento de transcrição:O debate começa com a apresentação de Paulo Raimundo do CDU e Rui Rocha da Iniciativa Liberal. Rui defende que a detenção de alguém por 21 dias viola direitos. Paulo afirma que a proposta da IL é prejudicar mais a justiça. Rui nega e fala em estratégias para tornar a justiça mais eficiente. Paulo pergunta se Rui quer mudar como o ministério público atua, principalmente em casos de grandes partidos. Rui diz que a lei é suficiente para entender que ninguém pode ser detido por mais de 21 dias e propõe acelerar processos judiciais com melhorias na eficiência. Paulo pergunta se mudanças na justiça são necessárias agora ou se é perigoso.",
        "Winner": "Na minha opinião, Rui Rocha do partido Iniciativa Liberal teve uma melhor performance no debate. Apesar de Paulo Raimundo do CDU ter tentado colocar Rui Rocha em posições desconfortáveis questionando as suas propostas, Rui conseguiu defender de forma consistente a visão do seu partido de um sistema judicial mais eficiente e que respeite os direitos dos cidadãos. Rui argumentou de forma clara e objetiva, afirmando que a legislação atual é suficiente mas que é preciso implementar estratégias para acelerar os processos judiciais. Em contraste, Paulo pareceu mais focado em atacar as ideias do adversário político em vez de apresentar alternativas concretas. Portanto, considero que Rui Rocha levou a melhor no debate pela forma como defendeu os pontos de vista do Iniciativa Liberal.",
        "Url": "https://app.meetpulp.com/meeting/public/575b5566-1ac4-4082-a759-3570a4cef75a",
        "WinnerName": "Rui Rocha"
    },
    {
        "Title": "21 - Debate IL vs BE - Rui Rocha vs Mariana Mortágua",
        "Abstract": "Eis um resumo de 10 linhas do debate:O debate começou com a discussão sobre habitação, onde as partes diferem. Rui questionou se a meta de 25% de novas construções a preços acessíveis de Bloco é realista, ao que Mariana criticou a direita por culpar a falta de casas em vez do excesso de casas vazias. Eles discordaram sobre o problema ser não-residentes, com Rui defendendo mais construção e Mariana criticando casas para aluguel de curto prazo. A discussão mudou para impostos, com Rui defendendo cortes e Mariana questionando como substituir a receita perdida. Eles debateram também saúde, educação e outras questões com abordagens diferentes.",
        "Winner": "Pelo que li no resumo, parece-me que Mariana Mortágua teve uma prestação mais sólida no debate. Apresentou argumentos bem fundamentados em defesa das posições do Bloco de Esquerda, criticando as propostas do adversário sempre com dados e factos concretos. Questionou os números apresentados por Rui Rocha e explicou bem as dificuldades reais do mercado imobiliário português. Por outro lado, Rui Rocha pareceu-me menos convincente, limitando-se mais a defender ideias genéricas como cortes de impostos e crescimento económico, sem conseguir rebater eficazmente os contra-argumentos da Bloco. No global, diria que Mariana Mortágua conseguiu defender melhor as suas posições e colocar em causa as do adversário, o que a torna na vencedora deste debate específico sobre habitação.",
        "Url": "https://app.meetpulp.com/meeting/public/4d855ad1-f58e-485c-99c6-3f00b14e8078",
        "WinnerName": "Mariana Mortágua"
    },
    {
        "Title": "22 - Debate PS vs BE - Pedro Nuno Santos vs Mariana Mortágua",
        "Abstract": "Eis um resumo de 10 linhas dos principais pontos debatidos:Pedro Nuno e Mariana debateram sobre a necessidade de um acordo pré-eleitoral entre PS e Bloco. Discutiram a crise na justiça e confiança na Procuradora Geral. Debateu-se também o SNS e parcerias privadas. Mariana criticou as políticas anteriores. Abordaram ainda a crise da habitação e propostas do Bloco para controlar empresas e restrição de compra de casa por não residentes. Outros temas foram discutidos durante mais de 30 minutos, incluindo organização do SNS além de valorizar carreiras, antes de concluírem o debate.",
        "Winner": "Na minha opinião, o debate foi equilibrado e ambos os participantes defenderam as suas posições de forma consistente. No entanto, diria que a Mariana Mortágua conseguiu melhor explicar as propostas do Bloco de Esquerda para resolver problemas concretos como a saúde, habitação e salários. Apresentou soluções mais detalhadas e criticou com argumentos as políticas passadas. O Pedro Nuno Santos focou-se mais em defender o atual governo e não apresentou alternativas tão concretas. Assim, considero que a Mariana Mortágua esteve ligeiramente mais convincente na defesa das suas ideias, pelo que poderia ser vista como a vencedora do debate. No entanto, foi um debate construtivo onde ambos os lados tiveram oportunidade de expor as suas posições.",
        "Url": "https://app.meetpulp.com/meeting/public/b8120904-054f-47d8-affe-4365c9e2f7b7",
        "WinnerName": "Mariana Mortágua"
    },
    {
        "Title": "23 - Debate Chega vs Livre - André Ventura vs Rui Tavares",
        "Abstract": "André Ventura defendeu aumentar as penas por corrupção e confiscar bens de criminosos. Rui Tavares respondeu ser importante prevenir corrupção, como tornar contratos públicos mais transparentes. Ventura criticou o LIVRE por votar contra propostas de aumento de penas. Discordaram sobre a posição do LIVRE em relação às prisões. Tavares defendeu reformar o sistema prisional, Ventura afirmou que querem ninguém na prisão. Debateram também privatizações e posições de cada um, discordando frequentemente ao longo da conversa entre os dois políticos.",
        "Winner": "Na minha opinião, este debate foi bastante acalorado com ambos os participantes a defenderem fortemente as suas posições. No entanto, diria que Rui Tavares esteve mais convincente na argumentação das propostas do seu partido e na defesa dos seus pontos de vista. Apesar das críticas de André Ventura, Rui Tavares conseguiu explicar de forma clara as políticas do LIVRE e porque é que discorda de propostas como aumentar penas ou confiscar bens. Por outro lado, notei que André Ventura recorreu mais a ataques pessoais ao LIVRE e a Rui Tavares do que a explicar em concreto as suas próprias soluções. Portanto, no global, considero que Rui Tavares teve uma performance ligeiramente melhor no debate e foi quem conseguiu defender as suas ideias de forma mais convincente.",
        "Url": "https://app.meetpulp.com/meeting/public/6a7220d9-25aa-40d4-9e3b-03c0ca92dcda",
        "WinnerName": "Rui Tavares"
    },
    {
        "Title": "24 - Debate PS vs PCP - Pedro Nuno Santos vs Paulo Raimundo",
        "Abstract": "Eis um resumo de 10 linhas dos principais pontos debatidos:Os candidatos discutiram o caso da detenção na Madeira, com Paulo afirmando que não comenta casos específicos. Falaram sobre reformas laborais do governo e se receberam apoio do PCP. Debateu-se propostas para habitação e aumentos salariais. Questionado sobre juntar-se a governo, Paulo evitou resposta clara. Concordaram em buscar paz mas divergiram em gastos militares. O moderador encerrou o debate tendo sido discutidos estes temas de justiça, direitos trabalhistas, habitação e política orçamental e militar.",
        "Winner": "Na minha opinião, o debate foi equilibrado entre os dois candidatos, com argumentos válidos de ambos os lados. No entanto, diria que Paulo Raimundo esteve ligeiramente por cima, uma vez que conseguiu defender de forma consistente as propostas do PCP e apontar algumas limitações dos planos defendidos por Pedro Nuno Santos. Em particular, Paulo Raimundo foi hábil a questionar a capacidade das medidas do PS em alterar substancialmente o estado atual das coisas, nomeadamente no que diz respeito à habitação. Por outro lado, Pedro Nuno Santos também teve intervenções sólidas, destacando algumas das conquistas do governo na área laboral. No entanto, não foi tão eficaz a rebater os argumentos do PCP como Paulo Raimundo o foi em criticar as propostas socialistas. Em suma, embora tenha sido um debate equilibrado, diria que Paulo Raimundo esteve ligeiramente por cima, conseguindo defender melhor as suas ideias e colocar algumas dúvidas nas propostas do adversário. Foi o candidato que pareceu sair mais reforçado deste confronto entre os dois partidos.",
        "Url": "https://app.meetpulp.com/meeting/public/a9297896-3281-4ad3-97cd-5a0c232dbafd",
        "WinnerName": "Paulo Raimundo"
    },
    {
        "Title": "25 - Debate AD vs Livre - Luís Montenegro vs Rui Tavares",
        "Abstract": "Aqui está um resumo de 10 linhas dos principais pontos debatidos:O debate começou entre Rui Tavares do LIVRE e Luís Montenegro representando a coligação AD. Discutiram reformas judiciais, com ambos os programas propondo medidas comuns. Falaram sobre atrasos na justiça criminal e fiscal. Debateu-se a criminalização do enriquecimento ilícito. Abordaram reformas nas pensões, com LIVRE querendo aumentar as mais baixas e AD propondo um suplemento de solidariedade. Discutiram apoio à juventude, com divergências sobre impostos e habitação. Por fim, debateram produtividade, conciliação trabalho-família e retenção de jovens.",
        "Winner": "Na minha opinião, este debate foi equilibrado e ambos os representantes defenderam bem as suas posições. No entanto, diria que Rui Tavares conseguiu apresentar as propostas do LIVRE de forma mais detalhada e fundamentada. Ao longo do debate, foi capaz de explicar melhor como as medidas do seu partido iriam funcionar na prática e responder às questões colocadas pelo moderador. Por outro lado, embora o Luís Montenegro tenha apontado alguns problemas relevantes, não desenvolveu tanto as soluções do AD. Além disso, em alguns momentos pareceu mais defensivo e a criticar as ideias do LIVRE, em vez de positivar as do seu programa. Assim, considerando a capacidade de cada um em explicar e defender as suas propostas, daria uma pequena vantagem a Rui Tavares neste debate. No entanto, foi um debate construtivo onde ambos os representantes trouxeram pontos válidos para a discussão sobre reformas judiciais, da segurança social e apoio aos jovens em Portugal.",
        "Url": "https://app.meetpulp.com/meeting/public/82ae2551-a055-49f8-aec5-90050c08a6db",
        "WinnerName": "Rui Tavares"
    },
    {
        "Title": "26 - Debate BE vs PAN - Mariana Mortágua vs Inês Sousa Real",
        "Abstract": "Aqui está um resumo de 10 linhas do debate:O debate começou entre Mariana Mortágua do Bloco de Esquerda e Inês de Souza Real do Partido Pan. Mariana não respondeu diretamente se o Bloco exigirá fazer parte do governo. Inês reconheceu eventos preocupantes envolvendo Navalny e Gaza, defendendo o reconhecimento de Palestina. Mariana continuou sem responder se o Bloco exigirá fazer parte do governo, focando em políticas de habitação. O debate virou para mudanças climáticas, com Mariana defendendo energias renováveis e Inês defendendo transporte público e neutralidade de carbono até 2045.",
        "Winner": "Na minha opinião, não é possível escolher claramente um vencedor neste debate. Ambas as participantes defenderam as posições do seu partido de forma consistente. A Mariana Mortágua focou-se mais nas políticas concretas que o Bloco de Esquerda defende para questões como o arrendamento, os juros da habitação e o ambiente. No entanto, evitou comprometer-se diretamente com a entrada do Bloco no governo caso haja uma maioria de esquerda. Já a Inês de Souza Real abordou também propostas do PAN, nomeadamente para alcançar a neutralidade carbónica até 2045 através de subsídios aos transportes públicos e incentivos. Além disso, destacou temas internacionais como a Palestina e Navalny. Globalmente, ambas as participantes defenderam bem as suas posições partidárias. No entanto, como nenhuma conseguiu claramente dominar o debate ou desmontar os argumentos da outra, não me parece justo escolher um único vencedor. Foi um debate equilibrado onde cada uma cumpriu o seu papel de representar as ideias do seu partido.",
        "Url": "https://app.meetpulp.com/meeting/public/d4842815-8a96-49da-ab08-758f3bb57e54",
        "WinnerName": "Empate"
    },
    {
        "Title": "27 - Debate AD vs IL - Luís Montenegro vs Rui Rocha",
        "Abstract": "A conversação começa entre o Orador A e o Orador B, onde falam sobre a situação política na Madeira. O Orador B diz que vão respeitar o processo político na região. Pergunta-se então sobre a permanência de Miguel Albuquerque no cargo caso vença as eleições, mas o Orador B não antecipa cenários. Debatem-se também propostas de saúde e privatizações entre Rui Rocha e Luís Montenegro, defendendo cada um as propostas do seu partido. Concordam no final que o diálogo entre partidos é benéfico para o país.",
        "Winner": "Pelas informações fornecidas no resumo, parece-me que o debate entre Rui Rocha e Luís Montenegro foi equilibrado, com ambos os políticos a defenderem as propostas dos seus partidos de forma veemente, mas respeitosa. No entanto, poder-se-á dizer que Rui Rocha conseguiu destacar-se mais ao apontar diferenças concretas entre as propostas da Iniciativa Liberal e do PSD, nomeadamente ao nível da liberdade de escolha no sistema de saúde e das propostas fiscais. Rui Rocha argumentou que a IL defende uma maior liberalização da economia ao passo que o PSD propõe medidas mais moderadas. Por outro lado, Luís Montenegro conseguiu também fazer valer os argumentos do PSD, mostrando que as suas propostas não se resumem a cortes fiscais mais tímidos, mas incluem medidas direcionadas para os jovens. Defendeu também a importância de assegurar primeiro o acesso universal à saúde. Em suma, apesar de ambos terem apresentado os seus pontos de vista de forma construtiva, diria que Rui Rocha saiu ligeiramente por cima ao conseguir destacar de forma mais clara as diferenças ideológicas entre os dois partidos. No entanto, o debate parece ter sido bastante equilibrado e construtivo para o esclarecimento dos eleitores.",
        "Url": "https://app.meetpulp.com/meeting/public/3c3fa05b-5af5-48ab-ba7c-69ec215b1ae2",
        "WinnerName": "Rui Rocha"
    },
    {
        "Title": "28 - Debate PS vs PSD - Pedro Nuno Santos vs Luís Montenegro",
        "Abstract": "Aqui está um resumo de 10 linhas dos principais pontos discutidos no debate:Os candidatos debateram propostas para a educação. Montenegro defendeu valorizar a carreira dos professores e estimular mais a educação profissional. Santos disse que não irá repor exames finais nos 1o e 2o ciclos, mas sim avaliações de meio percurso.Falou-se também do Serviço Nacional de Saúde. Santos listou investimentos feitos nos últimos anos e aumento de produção. Montenegro criticou as taxas de execução dos orçamentos da saúde pelo PS.Por fim, discutiu-se a falta de profissionais no SNS, com milhares a reformarem-se ou mudarem para o privado. Os candidatos não apresentaram propostas concretas para resolver esta questão.",
        "Winner": "Na minha opinião, este debate foi equilibrado e ambos os candidatos apresentaram as suas ideias de forma construtiva. No entanto, diria que Luís Montenegro conseguiu defender melhor as propostas do PSD, respondendo de forma mais detalhada às questões colocadas. Ao mesmo tempo, Pedro Nuno Santos focou-se mais em criticar o adversário do que em explicar as soluções do PS para os problemas discutidos. Assim, considero que Luís Montenegro teve uma prestação ligeiramente melhor neste debate, conseguindo afirmar-se como o vencedor. No entanto, foi um debate civilizado onde ambos os líderes partidários conseguiram expor as suas visões para o país.",
        "Url": "https://app.meetpulp.com/meeting/public/03fddf38-ec59-459f-a7ac-fcbb5a86edb0",
        "WinnerName": "Luís Montenegro"
    }];



function Legislativas2024() {
    const navigate = useNavigate()
    const winnerCounts = {};

    resultados.forEach(debate => {
        const winnerName = debate.WinnerName;
        if (winnerCounts[winnerName]) {
            winnerCounts[winnerName] += 1;
        } else {
            winnerCounts[winnerName] = 1;
        }
    });

    const formattedWinnerCounts = Object.keys(winnerCounts).map(key => ({
        name: key,
        value: winnerCounts[key]
    })).sort((a, b) => b.value - a.value);

    const winnerNames = formattedWinnerCounts.map(winner => winner.name + " - " + winner.value + (winner.value > 1 ? " debates" : " debate"));
    return (
        <div className='flex flex-col items-center'>
            <div className="flex flex-col justigy-center items-center z-10 bg-black text-white w-full">
                <header className="bg-white fixed t top-0 z-40 w-full">
                    <nav className="mx-auto flex items-center justify-between py-0 lg:px-8 z-50" aria-label="Global" >
                        <div className="flex lg:flex-1">
                            <a className="-m-1.5 p-1.5 flex items-center" href='https://meetpulp.com/pt' target='_blank'>
                                <img className="h-24 w-auto" src={logo} alt="" />
                                <p className="text-xl ml-4 font-semibold text-black text-start flex items-end">Meetpulp <span className='px-2 hidden md:block '>|</span><span className='text-sm text-gray-400 hidden md:block'> Grave, transcreva, resuma e obtenha tarefas apartir do seu áudio, com o poder de IA.</span></p>
                            </a>
                        </div>
                        <div className="flex ">
                            <a
                                type="button"
                                className="mr-2 text-md font-semibold leading-6 bg-black rounded-xl px-2 py-2 text-white"
                                href='https://meetpulp.com/pt' target='_blank'
                            >
                                Começar agora
                            </a>
                        </div>
                    </nav>
                    <div className='text-sm text-gray-400 md:hidden block px-4 text-center'>Grave, transcreva, resuma e obtenha tarefas apartir do seu áudio, com o poder de IA.</div>
                </header>

                <h1 className="font-black text-5xl sm:text-7xl mt-8 mb-12 mx-8 text-center max-w-5xl leading-none mt-48">
                    Perguntámos à Inteligência Artificial o que achou dos debates legislativos de 2024
                </h1>
                <h1 className=" font-medium text-2xl sm:text-4xl mb-16 mx-8 text-center max-w-6xl leading-tight ">
                    Obtivemos estes resultados*:
                </h1>
            </div>


            <div className='max-w-7xl px-4 py-4 '>
                <Card>
                    <Flex className="start flex flex-col items-start">
                        <div className='text-black font-bold'>Legislativas 2024</div>
                        <div>Vencedores dos debates segundo a Inteligência Artificial</div>
                    </Flex>
                    <div className='w-full justify-center mt-4'>
                        <span className="text-center block font-mono text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                            Debates
                        </span>
                        <DonutChart
                            className="mt-2 max-h-92"
                            data={
                                formattedWinnerCounts
                            }
                            category="value"
                            index="name"
                            colors={["indigo-500", "red-500", "yellow-500", "green-500", "blue-500", "purple-500", "pink-500", "orange-500", "teal-500", "gray-500", "cyan-500", "amber-500", "lime-500", "emerald-500", "violet-500"]}
                            showAnimation={true}
                        />
                        <Legend
                            className="mt-4 grid z-10"
                            categories={winnerNames}
                            colors={["indigo-500", "red-500", "yellow-500", "green-500", "blue-500", "purple-500", "pink-500", "orange-500", "teal-500", "gray-500", "cyan-500", "amber-500", "lime-500", "emerald-500", "violet-500"]}

                        /></div>

                </Card>
                <div className='my-4'>
                    <SectionHeader title="Frente a Frente" subtitle="Veja resumos dos debates, os sentimentos detetados e a análise da IA" />
                </div>
                <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4  pb-4'>


                    {resultados.map((meeting) => {
                        return (
                            <div className='flex flex-col shadow-2xl px-4 py-4 w-full bg-white rounded-2xl h-full '>
                                <div className='flex justify-between flex-col sm:flex-row gap-2 mb-4'>
                                    <div className='flex items-center  ml-1 justify-center'>
                                        <CircleStackIcon className='w-9 h-9' />
                                        <div className=' pl-4 h-full flex items-center'>
                                            <div className='text-base font-semibold  text-gray-900 '>
                                                {meeting["Title"]}
                                            </div>
                                        </div>

                                    </div>
                                    <a className='text-sm bg-black text-white rounded-xl h-fit px-3 py-2 shadow-xl text-center'
                                        href={meeting["Url"]}

                                    >
                                        <div>Sentimentos e mais</div>

                                    </a>
                                </div>
                                <div className={'px-2 text-sm py-4 border-t-2 border-dotted h-full'}>
                                    <span className='font-bold'>Vencedor segundo Inteligência Artificial</span>:<br />{meeting["WinnerName"]}
                                </div>
                                <div className={'px-2 text-sm py-4 border-t-2 border-dotted h-full'}>
                                    <span className='font-bold'>Resumo</span>:<br />{meeting["Abstract"]}
                                </div>
                                <div className={'px-2 text-sm py-4 border-t-2 border-dotted h-full'}>
                                    <span className='font-bold'>Explicação da Inteligência Artificial </span>:<br />{meeting["Winner"]}
                                </div>
                            </div>)
                    })}
                </div>
            </div>
            <div className='text-xs text-gray-600 '>* Os resultados apresentados tem apenas como objectivo entretenimento, e não expressando as opiniões do Meetpulp nem nenhum dos funcionários da empresa. Poderão haver erros, o Meetpulp não se responsabiliza pelos mesmos.</div>
            <div className='text-xs text-gray-600 mb-4'>Para ajuda ou alguma informação pode contactar-nos em support@meetpulp.com</div>
        </div >
    )
}

export default Legislativas2024

import React, { useEffect, useState } from "react";
import { login, requestPasswordChange } from "../../Requests/User/UserRequests";
import { useNavigate } from "react-router-dom";
import login_brain from "../../Assets/casset.png";
import logo from "../../Assets/logo.png";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import SuccesModal from "../../Components/SuccesModal";
import ErrorModal from "../../Components/ErrorModal";
import Loader from "../../Components/Loader";


function RequestPasswordChange() {
  const [goToLogin, setGoToLogin] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  let handleSubmit = (event) => {
    setIsLoading(true)
    setFeedbackModal(false)
    event.preventDefault();
    let response = requestPasswordChange(event.target.email.value);
    response
      .then((res) => {

        setIsLoading(false)
        setFeedbackModal(<SuccesModal
          title={t('RequestPasswordChangePage.SuccessModalTitle')}
          body={t('RequestPasswordChangePage.SuccessModalText')}
          continue={t('RequestPasswordChangePage.SuccessModalButton')}
          navLink="/login"
        />)
      }).catch((error) => {
        setIsLoading(false)
        setFeedbackModal(<ErrorModal
          title={t('RequestPasswordChangePage.FailModalTitle')}
          body={t('RequestPasswordChangePage.FailModalText')}
          continue={t('RequestPasswordChangePage.FailModalButton')}
        />)
      });
  }

  function redirectToLogin() {
    setGoToLogin(true);
  }

  useEffect(() => {
    if (goToLogin) {
      navigate(`/login`);
    }
  }, [goToLogin]);

  return (
    <>
      <div className="flex min-h-full bg-white">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className="w-full flex justify-center">
                <img className="h-32 w-auto" src={logo} alt="Your Company" />
              </div>
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                {t('RequestPasswordChangePage.pageTitle')}
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form className="space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('RequestPasswordChangePage.EmailField')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <a
                        href=""
                        className="font-medium text-black hover:text-black"
                        onClick={redirectToLogin}
                      >
                        {t('RequestPasswordChangePage.LoginLink')}
                      </a>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                    >
                      {isLoading ? <Loader /> : t('RequestPasswordChangePage.SubmitButton')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={login_brain}
            alt=""
          />
        </div>
      </div>
      {feedbackModal}
    </>
  );
}

export default RequestPasswordChange;

import React, { useState } from 'react'
import SectionHeader from '../GeneralComponents/SectionHeader'
import { useTranslation } from 'react-i18next'
import UploadAudioFileModal from '../GeneralComponents/UploadAudioFileModal'
import { ChatBubbleBottomCenterTextIcon, DocumentArrowUpIcon, SpeakerWaveIcon } from '@heroicons/react/24/outline'
import UploadFileModal from '../Text/TextUploadModal'
import TextTranscriptionModal from '../Text/TextTranscriptionModal'

function AudioUpload({ projects }) {
    const { t } = useTranslation()
    const [uploadAudioModal, setUploadAudioModal] = useState(false)

    const handleNewFileUpload = (type) => {
        if (type === "Audio") {
            setUploadAudioModal(<UploadAudioFileModal handleClose={() => { setUploadAudioModal(null); }} projects={projects} />);
        } else if (type === "Pdf") {
            setUploadAudioModal(<UploadFileModal handleClose={() => { setUploadAudioModal(null); }} projects={projects} />);
        } else if (type == "Transcription") {
            setUploadAudioModal(<TextTranscriptionModal handleClose={() => { setUploadAudioModal(null); }} projects={projects} />);
        }
    };
    return (
        <div className='md:pl-64 pt-4 md:pt-12 w-full flex justify-center'>
            <div className='bg-white max-w-7xl rounded-2xl w-full h-full shadow-xl mx-8 '>
                <SectionHeader title={t('Audio.NewFileUpload.Title')} subtitle={t('Audio.NewFileUpload.Subtitle')} ></SectionHeader>
                <div className='grid grid-cols-1 md:grid-cols-3 mt-4 p-12 gap-12'>
                    <button
                        type='button'
                        onClick={() => {
                            handleNewFileUpload("Audio")
                        }}
                        className='w-full flex flex-col justify-center items-center gap-2 whitespace-nowrap p-12 shadow-lg border border-black rounded-lg hover:bg-black hover:text-white'>
                        <SpeakerWaveIcon className='w-12 h-12' />
                        {t("UploadFileWithTypes.Audio")}
                    </button>
                    <button
                        type='button'
                        onClick={() => {
                            handleNewFileUpload("Transcription")
                        }}
                        className='w-full flex flex-col justify-center items-center gap-2 whitespace-nowrap p-12 shadow-lg border border-black rounded-lg hover:bg-black hover:text-white'>
                        <ChatBubbleBottomCenterTextIcon className='w-12 h-12' />
                        {t("UploadFileWithTypes.Upload transcription")}
                    </button>
                    <button
                        type='button'
                        onClick={() => {
                            handleNewFileUpload("Pdf")
                        }}
                        className='w-full flex flex-col justify-center items-center gap-2 whitespace-nowrap p-12 shadow-lg border border-black rounded-lg hover:bg-black hover:text-white'>
                        <DocumentArrowUpIcon className='w-12 h-12' />
                        {t("UploadFileWithTypes.PDF")}
                    </button>
                </div>

            </div>
            {uploadAudioModal}
        </div>
    )
}

export default AudioUpload

import React, { useEffect, useState } from 'react'
import AudioRecord from '../../../../Components/Audio/AudioRecord'
import AudioUpload from '../../../../Components/Audio/AudioUpload'
import { getProjects } from '../../../../Requests/NewAnalysisRequests'
import { getTeamUuid } from '../../../../utils/cookies'

function Audio() {
    const [projects, setProjects] = useState(null)
    useEffect(() => {
        if (!projects) {
            getProjects(getTeamUuid()).then((res) => {
                setProjects(res.data)
            }).catch((e) => {

            })
        }
    }, [])
    return (
        <div>
            {/*<AudioRecord />*/}
            {projects && <AudioUpload projects={projects} />}
        </div>
    )
}

export default Audio

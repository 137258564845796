import React, { useEffect, useState } from 'react'
import step_1_image from '../../../../Assets/Onboarding/step_1.png'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { onboard } from '../../../../Requests/OnboardingRequests'
import Cookies from "universal-cookie";
import { useMyContext } from '../../../../Components/ContextProviders/CreditsContextProvider'
import { getTeamUuid } from '../../../../utils/cookies'
function Onboarding() {


    const navigate = useNavigate()
    const [onboardingStep, setOnboardingStep] = useState(1)
    const { t } = useTranslation()
    const cookies = new Cookies();
    const { setCredits } = useMyContext();

    const handleForm = (event) => {
        event.preventDefault()
        let data = {
            "team": event.target.team.value,
            "organization": event.target.organization.value
        }
        onboard(data).then((res) => {
            cookies.set("team_uuid", res.data.team_uuid, { path: '/' })
            cookies.set("credits", res.data.credits, { path: '/' })
            setCredits(res.data.credits)

            navigate("/dashboard")
        }).catch((e) => {

        })
    }

    useEffect(() => {
        if (getTeamUuid() != null) {
            navigate("/dashboard")
        }
    }, [])

    return (
        <div className='w-full flex justify-center h-full'>
            <form onSubmit={handleForm} className='w-full flex justify-center h-full'>
                <div className='bg-white rounded-2xl w-full max-w-3xl m-4 sm:m-12 flex flex-col justify-between items-center pb-12 min-h-fit '>
                    <div className='px-4'>
                        <h1 className='w-full text-center text-4xl font-bold mt-7'>{t('Onboarding.Title')}</h1>
                        <h1 className='w-full text-center text-lg  mt-4'>{t('Onboarding.Subtitle')}</h1>
                    </div>

                    <div className='w-full flex justify-center items-center sm:px-24'>
                        <img src={step_1_image} alt='Office image'></img>
                    </div>

                    <div className='px-4 ' hidden={onboardingStep != 1}>

                        <div className='text-md text-center'>
                            {t('Onboarding.Step1Description1')}
                        </div>

                        <div className='flex gap-2 w-full  flex-col mt-4 items-center justify-center'>
                            <input
                                type="text"
                                name="organization"
                                id="organization"
                                className="block max-w-md w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                                placeholder={t('Onboarding.Step1FormPlaceHolder')}
                            >
                            </input>
                            <button
                                type="button"
                                onClick={() => {
                                    setOnboardingStep(2)
                                }}
                                className='bg-black text-white rounded-md px-12 py-2 w-fit'>
                                {t('Onboarding.Step1FormButton')}
                            </button>
                        </div>
                    </div>

                    <div className='px-4' hidden={onboardingStep != 2}>

                        <div className='text-base text-center'>
                            {t('Onboarding.Step2Description1')}
                        </div>
                        <div className='flex gap-2 w-full  flex-col mt-4 items-center'>
                            <input
                                type="text"
                                name="team"
                                id="team"
                                className="block w-full max-w-md text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                                placeholder={t('Onboarding.Step2FormPlaceHolder')}
                            >
                            </input>
                            <button
                                type='submit'
                                className='bg-black text-white rounded-md px-12 py-2 w-fit'>
                                {t('Onboarding.Step2FormButton')}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Onboarding

// TextCodeExcerptsModal.js
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Card } from '@tremor/react';
import { useTranslation } from 'react-i18next';
import { getExcerptsForCode } from '../../Requests/TextCoderRequests';

function TextCodeExcerptsModal({ handleClose, code }) {
    const [open, setOpen] = useState(true);
    const { project_uuid } = useParams();
    const [files, setFiles] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate()

    useEffect(() => {
        getExcerptsForCode(project_uuid, code.uuid).then((res) => {
            const processedFiles = processExcerpts(res.data);
            setFiles(processedFiles);
        }).catch((e) => {

        });
    }, [project_uuid, code.uuid]);

    const closeModal = () => {
        handleClose();
        setOpen(false);
    };

    const authorList = files?.reduce((acc, currentSelection) => {
        if (Array.isArray(currentSelection.excerpts)) {
            currentSelection.excerpts.forEach(excerpt => {
                acc.add(excerpt.author || "AI");  // Add the author to the Set, duplicates will be ignored
            });
        }
        return acc;
    }, new Set());

    const totalExcerpts = files?.reduce((total, currentSelection) => {
        // Check if currentSelection.excerpts is defined and is an array
        if (Array.isArray(currentSelection.excerpts)) {
            return total + currentSelection.excerpts.length;
        }
        return total;
    }, 0);  // 

    const fileList = files?.map((selection) => { return selection.analysis_name })

    // Convert the Set to an array
    const uniqueAuthors = authorList && Array.from(authorList)?.join(", ");
    const uniqueFiles = fileList && Array.from(fileList)?.join(", ")


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex w-full min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg px-4  pb-4 text-left transition-all sm:my-8 sm:w-full sm:max-w-7xl pt-0">
                                <button className='w-full flex justify-end mb-2' onClick={() => { handleClose() }}>
                                    <XMarkIcon className='bg-black w-10 h-10 text-white rounded-full p-2'></XMarkIcon>
                                </button>
                                <Card className='flex flex-col justify-start gap-2 mb-4'>
                                    <div className='text-xl font-bold w-full text-center px-4 py-2 rounded-md mb-2 text-gray-600' style={{ backgroundColor: code.color }}>
                                        {code.name}
                                    </div>
                                    <div className='text-base'>
                                        {code.description}
                                    </div>
                                    <div className='flex text-sm gap-8 text-gray-400'>
                                        <div >
                                            {t('TextCoder.Total')}: {files && <span className='font-bold'>{totalExcerpts || 0}</span>}
                                        </div>
                                        <div >
                                            {t('TextCoder.Files with the code')}: {files?.length}
                                        </div>
                                    </div>
                                    <div className='text-sm'>
                                        {t('TextCoder.Authors')}: <span className='font-bold'> {uniqueAuthors}</span>
                                    </div>
                                    <div className='text-sm'>
                                        {t('TextCoder.Files')}: <span className='font-bold'> {uniqueFiles}</span>
                                    </div>
                                </Card>
                                {files.map((file, index) => (
                                    <div key={index} className="bg-white rounded-md flex flex-col gap-2 mb-4 px-4 pt-2 pb-4">
                                        <div className='text-2xl font-bold my-4 flex items-center gap-2'>
                                            <div className='rounded-full border-2 border-black aspect-square w-8 h-8 min-w-fit min-h-fit flex justify-center items-center p-2 text-base bg-black text-white'>{file?.excerpts?.length}</div>
                                            <div>{file.analysis_name}</div>
                                        </div>
                                        {file.excerpts.map((excerpt, eIndex) => (
                                            <div key={eIndex} className="p-2 bg-gray-100/80 rounded-md">
                                                <div className="text-lg flex items-center gap-3 ">
                                                    <div className='bg-black text-white flex items-center justify-center text-sm  rounded-full w-6 h-6 min-w-6 min-h-6 aspect-square'>{excerpt.count}</div>
                                                    {excerpt.text}</div>
                                                <div className="text-sm text-gray-500 mt-2 w-fit">
                                                    {Array.from(excerpt.authors).join(", ")}
                                                </div>
                                            </div>
                                        ))}
                                        <div className='flex justify-end'>
                                            <button className='bg-black text-white px-4 py-2 rounded-md mt-4'
                                                onClick={() => {
                                                    navigate("/text_coder/" + project_uuid + "/file/" + file.analysis_uuid)
                                                }}
                                            >{t('TextCoder.Open in coder')}</button>
                                        </div>
                                    </div>
                                ))}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    );
}

export default TextCodeExcerptsModal;


// processExcerpts.js
export const processExcerpts = (data) => {
    const fileMap = new Map(); // Map to hold data per file

    data.forEach((selection) => {
        const { analysis_name, analysis_uuid } = selection;
        if (!fileMap.has(analysis_name)) {
            fileMap.set(analysis_name, {
                uuid: analysis_uuid,
                excerpts: new Map() // Each file has its own map of excerpts
            });
        }

        selection.excerpts.forEach((excerpt) => {
            const key = `${excerpt.text}-${excerpt.startIndex}-${excerpt.endIndex}`;
            const excerptsMap = fileMap.get(analysis_name).excerpts;

            if (excerptsMap.has(key)) {
                const existing = excerptsMap.get(key);
                existing.count += 1;
                existing.authors.add(excerpt.author);
            } else {
                excerptsMap.set(key, {
                    count: 1,
                    authors: new Set([excerpt.author || "AI"]),
                    ...excerpt
                });
            }
        });
    });

    // Convert maps into arrays for easier rendering
    const files = Array.from(fileMap.entries());
    const fileExcerpts = files.map(([analysis_name, { uuid, excerpts }]) => ({
        analysis_name,
        analysis_uuid: uuid,
        excerpts: Array.from(excerpts.values())
    }));

    return fileExcerpts;
};


import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getAnalysis, getProject } from '../../../../Requests/NewAnalysisRequests'
import { PlayCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import TextCodeModal from '../../../../Components/TextCoder/TextCodeModal'
import { createOrUpdateExcerpt, deleteExcerpt, getExcerpts, getTextCodes } from '../../../../Requests/TextCoderRequests'
import TextCodes from './TextCodes'
import { getProjects } from '../../../../Requests/ProjectRequests'
import ViewFiles from './ViewFiles'
import { Tooltip } from 'react-tooltip'
import { v4 as uuidv4 } from 'uuid';
import { getSpeakerColor, newFormatTime } from '../../../../utils/calcs'
import TranscriptionMarker from './TranscriptionMarker'
import { getUserUuid } from '../../../../utils/cookies'
import no_file_image from '../../../../Assets/TextCoder/no_file_selected_no_bg.png'
import { useTranslation } from 'react-i18next'
function TextCoder() {

    const { project_uuid, analysis_uuid } = useParams()
    const [transcription, setTranscription] = useState(null)
    const [groupedTranscriptions, setGroupedTranscriptions] = useState(null)
    const [speakerNames, setSpeakerNames] = useState(null)
    const [editableTranscriptions, setEditableTranscriptions] = useState([]);
    const [isEditingTextCodes, setIsEditingTextCodes] = useState(false);
    const [analysis, setAnalysis] = useState(null)
    const [textCodes, setTextCodes] = useState(null)
    const [files, setFiles] = useState(null)
    const [selectableGroupUuid, setSelectableGroupUuid] = useState(null);
    const [selections, setSelections] = useState([]);
    const [pageActiveSelections, setPageActiveSelections] = useState([])
    const [isSelecting, setIsSelecting] = useState(null)
    const [createCodeModal, setCreateCodeModal] = useState(null)
    const [teamMembers, setTeamMembers] = useState(null)
    const [viewCodesModal, setViewCodesModal] = useState(null)
    const { t } = useTranslation()

    useEffect(() => {
        if (!textCodes) {
            getTextCodes(project_uuid).then((res) => {
                setTextCodes(res.data);
                let activeOnStart = [];
                res.data.map((code) => {
                    activeOnStart.push(code.uuid);
                });
                setPageActiveSelections(activeOnStart);
            }).catch((error) => { });
        }
    }, [project_uuid]);

    useEffect(() => {
        if (!files) {
            getProject(project_uuid).then((res) => {
                setFiles(res.data.analysis);
            }).catch((error) => { });
        }
    }, [project_uuid]);

    useEffect(() => {
        if (analysis_uuid) {
            getAnalysis({ "analysis_uuid": analysis_uuid }).then((res) => {
                setAnalysis(res.data);
                setSpeakerNames(JSON.parse(res.data.speaker_names || '{}'));
                setTranscriptionAndNames(res.data);
                res.data.team_members.push(
                    { "user_uuid": "AI", visible: true, "user_name": "AI" }
                )
                setTeamMembers(res.data.team_members?.map(member => ({
                    ...member,
                    visible: true
                })));
            }).catch((error) => { });
            getExcerpts(analysis_uuid).then((res) => {
                let temp_exerpts = [];
                setSelections(res.data.map((section) => {
                    temp_exerpts.push({
                        name: section.text_code_uuid,
                        start: parseInt(section.start_index),
                        end: parseInt(section.end_index),
                        text: section.text,
                        uuid: section.transcription_uuid,
                        auuid: section.uuid,
                        author: section.author || "AI",
                        isSynced: true
                    });
                    return temp_exerpts; // This ensures data is added once the map is complete
                }));
                setSelections(temp_exerpts);
            }).catch((error) => { });
        }
    }, [analysis_uuid]); // Dependency on analysis_uuid and analysis


    const groupBySpeaker = (transcriptions) => {
        const grouped = [];
        let currentGroup = null;
        transcriptions.forEach((line, index) => {
            const matches = line.staticPart.match(/\[(\d+(?:\.\d+)?)-(\d+(?:\.\d+)?)\]\s+((?:Speaker\s+)?[\w\s]+):/);
            if (matches) {
                const startTime = matches[1];
                const endTime = matches[2];
                const speaker = matches[3]?.trim();
                if (currentGroup && currentGroup.speaker.trim() === speaker.trim()) {
                    currentGroup.lines += " " + line.editablePart
                    currentGroup.endTime = endTime;
                    currentGroup.uuid += line.uuid
                } else {
                    if (currentGroup) {
                        grouped.push(currentGroup);
                    }
                    currentGroup = { speaker, startTime, endTime, lines: line.editablePart, uuid: line.uuid };
                }
            }
            if (index === transcriptions.length - 1 && currentGroup) {
                grouped.push(currentGroup);
            }
        });

        return grouped;
    };

    useEffect(() => {
        setEditableTranscriptions(transcription);
    }, [transcription]);

    useEffect(() => {
        editableTranscriptions && setGroupedTranscriptions(groupBySpeaker(editableTranscriptions));
    }, [editableTranscriptions])


    const setTranscriptionAndNames = (analysis) => {
        let names = null;
        let transcriptionFragment = analysis.fragments
            ?.find(fragment => fragment.type === "edited_transcription");

        if (transcriptionFragment) {
            const lines = (transcriptionFragment?.result.split('\n')).map((line) => {
                // Adjusted regex to only match UUID at the start of the line
                const uuidMatch = line.match(/^<([^>]+)>/);
                const uuid = uuidMatch ? uuidMatch[1] : "";

                // Remove the UUID from the line to process the rest as before
                const lineWithoutUuid = uuid ? line.replace(/^<[^>]+>/, '').trim() : line;

                const parts = lineWithoutUuid.split(":");
                const timeAndSpeaker = parts[0].trim();
                const speakerMatch = timeAndSpeaker.match(/(Speaker\s+[A-Z])$/);
                const speakerKey = speakerMatch ? speakerMatch[0] : "";
                const time = speakerKey ? timeAndSpeaker.replace(speakerKey, "").trim() : timeAndSpeaker;

                let speakerName = speakerKey;
                if (names) {
                    // This logic remains the same but suggests you might want to replace `speakerKey` with a name from `names` if applicable
                    speakerName = speakerKey;
                }

                const final_line = {
                    uuid,
                    staticPart: `${time} ${speakerName}:`,
                    editablePart: parts.slice(1).join(":").trim() || "",
                };

                if (final_line?.editablePart != "") {
                    return final_line;
                } else {
                    return;
                }
            });

            const filteredLines = lines.filter(line => line !== undefined);

            if (!filteredLines.every(element => element === undefined)) {
                setTranscription(filteredLines);
            } else {
                setTranscription(null);
            }
        }
    }


    const handleSaveSelection = (color, name) => {
        setIsSelecting(null);
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            let startContainer = range.startContainer;
            let endContainer = range.endContainer;


            const selectedText = selection.toString();


            const isTargetContainer = (node) => node.classList?.contains('markable');
            const getGlobalOffset = (node, offset) => {
                let totalOffset = offset;
                while (node && !isTargetContainer(node)) {
                    let prevSibling = node.previousSibling;
                    while (prevSibling) {
                        totalOffset += prevSibling.textContent?.length || 0;
                        prevSibling = prevSibling.previousSibling;
                    }
                    node = node.parentNode;
                }
                return totalOffset;
            };
            const startGlobalOffset = getGlobalOffset(startContainer, range.startOffset);
            const endGlobalOffset = getGlobalOffset(endContainer, range.endOffset);

            // Proceed if there is actually some text selected
            if (startGlobalOffset !== endGlobalOffset) {
                setSelections(currentSelections => [
                    ...currentSelections,
                    {
                        author: getUserUuid(),
                        uuid: selectableGroupUuid,
                        start: startGlobalOffset,
                        end: endGlobalOffset,
                        color: color,
                        name: name,
                        text: selectedText,
                        auuid: uuidv4(),
                        isSynced: false
                    },
                ]);

            }
        }
    };

    useEffect(() => {
        const unsyncedSelections = selections.filter(selection => !selection.isSynced);
        if (unsyncedSelections.length > 0) {
            const data = unsyncedSelections.map(excerpt => ({
                analysis_parent_uuid: analysis_uuid,
                text_code_uuid: excerpt.name,
                transcription_uuid: excerpt.uuid,
                start_index: excerpt.start.toString(),
                end_index: excerpt.end.toString(),
                text: excerpt.text,
                uuid: excerpt.auuid
            }));
            createOrUpdateExcerpt(data).then((res) => {
                setSelections(currentSelections =>
                    currentSelections.map(selection => ({
                        ...selection,
                        isSynced: unsyncedSelections.some(unsynced => unsynced.auuid === selection.auuid) ? true : selection.isSynced,
                    }))
                );

            })
        }
    }, [selections])

    const [occurrencesByName, setOccurrencesByName] = useState({});

    const teamMemberVisibility = teamMembers?.reduce((acc, member) => {
        acc[member.user_uuid] = member.visible;
        return acc;
    }, {});

    useEffect(() => {
        if (teamMembers) {
            const counts = selections?.reduce((acc, selection) => {
                const { name, author } = selection;
                if (teamMemberVisibility[author]) { // Check if author is visible
                    acc[name] = (acc[name] || 0) + 1;
                }
                return acc;
            }, {});
            setOccurrencesByName(counts);
        }
    }, [selections, teamMembers]);


    return (
        <div className='flex h-full gap-2 px-2'>
            <ViewFiles files={files || null} analysis_uuid={analysis_uuid} project_uuid={project_uuid}></ViewFiles>
            {(analysis && teamMembers && textCodes) ? <TranscriptionMarker
                textCodes={textCodes}
                setSelections={setSelections}
                analysis={analysis}
                editableTranscriptions={editableTranscriptions}
                groupedTranscriptions={groupedTranscriptions}
                speakerNames={speakerNames}
                pageActiveSelections={pageActiveSelections}
                selections={selections}
                selectableGroupUuid={selectableGroupUuid}
                setSelectableGroupUuid={setSelectableGroupUuid}
                teamMembers={teamMembers}
                setTeamMembers={setTeamMembers}
            /> :
                <div className='w-full py-12 mt-4 mb-2 rounded-md bg-white p-4 flex flex-col items-center gap-8 flex-grow'>
                    <div className='text-xl font-bold'>{t('TextCoder.No file selected')}</div>
                    <div className='text-base text-gray-500 '>{t('TextCoder.Select a file to start coding')}</div>
                    <img src={no_file_image} className=' lg:h-1/2 lg:max-h-1/2 aspect-square mt-12'></img>
                </div>
            }

            <TextCodes
                textCodes={textCodes}
                setTextCodes={setTextCodes}
                handleSaveSelection={handleSaveSelection}
                createCodeModal={createCodeModal}
                setCreateCodeModal={setCreateCodeModal}
                pageActiveSelections={pageActiveSelections}
                setPageActiveSelections={setPageActiveSelections}
                isEditingTextCodes={isEditingTextCodes}
                setIsEditingTextCodes={setIsEditingTextCodes}
                occurrencesByName={occurrencesByName}
                setViewCodesModal={setViewCodesModal}
            ></TextCodes>
            <Tooltip id="code-tooltip"> </Tooltip>
            {viewCodesModal}
        </div>
    )
}

export default TextCoder

import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
    const cookies = new Cookies();
    return cookies.get("token");
}


export function getTextCodes(text_code) {
    var url = process.env.REACT_APP_API_BASE_URL + "/text-codes/" + text_code;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function combineTextCodes(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/join-text-codes";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function createOrUpdateCodes(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/text-codes";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function deleteCodes(code_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + "/text-codes/" + code_uuid;
    return axios.delete(url, { headers: { authorization: `Bearer ${getToken()}` } });
}


export function getExcerpts(analysis_id) {
    var url = process.env.REACT_APP_API_BASE_URL + "/excerpts/" + analysis_id;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}
export function getExcerptsForCode(project_uuid, code_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + "/excerpts/" + project_uuid + "/code/" + code_uuid;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}
export function createOrUpdateExcerpt(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/excerpts";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function deleteExcerpt(excerpt_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + "/excerpts/" + excerpt_uuid;
    return axios.delete(url, { headers: { authorization: `Bearer ${getToken()}` } });
}


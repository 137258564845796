import React from 'react'

function UpgradedLoader({ w = 6, h = 6, bg = "bg-red-600" }) {
    return (
        <div className="flex justify-center items-center">
            <div
                className={" animate-ping  inline-block border-4 rounded-full text-black w-" + w + " h-" + h + " " + bg}
                role="status"
            >
                <span className="visually-hidden"></span>
            </div>
        </div>
    )
}

export default UpgradedLoader

import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
    const cookies = new Cookies();
    return cookies.get("token");
}



export function getProjects(team_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + `/projects/` + team_uuid;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getProject(project_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + `/project/` + project_uuid;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}


export function newAudioAnalysis(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/audio_analysis/create";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function processAudioAnalysis(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/audio_analysis/process";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getAnalysis(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/analysis/fetch";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}
export function getPublicAnalysis(analysis_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + "/analysis/public/" + analysis_uuid;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function updateFragment(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/fragment/update";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function updateSpeakers(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/analysis/speaker_names";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function deleteTranscript(analysis_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + "/analysis/transcript/delete/" + analysis_uuid;
    return axios.delete(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function deleteAudio(analysis_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + "/analysis/document/delete/" + analysis_uuid;
    return axios.delete(url, { headers: { authorization: `Bearer ${getToken()}` } });
}
export function deleteAnalysis(analysis_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + "/analysis/delete/" + analysis_uuid;
    return axios.delete(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function updateAnalysisVisibility(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/analysis/visibility";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}
export function updateAnalysisName(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/analysis/name";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}
export function updateAnalysisLanguage(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/analysis/change_language";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function analysisChat(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/analysis/chat";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getFileGptAnswer(uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + "/analysis/get_answer/" + uuid;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function analysisStatus(analysis_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + "/analysis/status/" + analysis_uuid;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function transcriptionAnalysis(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/transcription_analysis/create";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function requestTranscriptionProcess(analysis_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + "/manual_transcription/process/" + analysis_uuid;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GithubPicker } from 'react-color';
import Loader from '../Loader';
import { combineTextCodes } from '../../Requests/TextCoderRequests';

const colors_option_2 = ["#ddad9e", "#f8e0c5", "#fcd39e", "#eab976", "#dec487", "#e6dcab", "#f1efb9", "#e2f1b5", "#bae295", "#aeea84", "#bbe4ae", "#aceea4", "#8cdd85", "#85eca4", "#bdf3d7", "#87fdd1", "#6fdcb4", "#a2dcf1", "#8cd2ef", "#aad2ec", "#bedcf0", "#72b0db", "#9bbbfc", "#a4a3dd", "#a8a1e9", "#c7bdf2", "#b29fdf", "#d789fe", "#e5a3f6", "#f4bff8", "#e895b1", "#f597a4"]

function TextCodeCombinationModal({ handleClose, codes, handleCombineCodes }) {
    const [open, setOpen] = useState(true);
    const { t } = useTranslation()
    const { project_uuid } = useParams()
    const [uploading, setUploading] = useState(false)
    const [hex, setHex] = useState("#f67088")
    const [selectedCode1, setSelectedCode1] = useState('')
    const [selectedCode2, setSelectedCode2] = useState('')

    const updateColor = (color) => {
        setHex(color.hex)
    }

    const closeModal = () => {
        handleClose()
        setOpen(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setUploading(true);
        let data = {
            "name": event.target.code_name.value,
            "description": event.target.code_description.value,
            "color": hex,
            "text_code_uuid_1": selectedCode1,
            "text_code_uuid_2": selectedCode2
        };

        // Here you would typically make an API call to join the codes
        // For now, we'll simulate it with a timeout
        combineTextCodes(data).then((res) => {
            handleCombineCodes([selectedCode1, selectedCode2], res.data.new_text_code)
            setUploading(false);
            handleClose();
        }).catch((e) => {

        })
    }
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex w-full min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 h-full min-h-96">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                                >
                                    {t('CombineTextCode.Title')}
                                </Dialog.Title>

                                <form className='flex w-full h-full flex-col justify-between gap-8' onSubmit={handleSubmit}>
                                    <div>
                                        <label htmlFor="code1" className="block text-sm font-medium leading-6 text-gray-900">
                                            {t('CombineTextCode.TitleCode1')}
                                        </label>
                                        <select
                                            id="code1"
                                            name="code1"
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-black sm:text-sm sm:leading-6"
                                            value={selectedCode1}
                                            onChange={(e) => setSelectedCode1(e.target.value)}
                                            required
                                        >
                                            <option value="">{t('CombineTextCode.SelectPlaceholder')}</option>
                                            {codes?.map((code) => (
                                                <option key={code.uuid} value={code.uuid}>{code.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div>
                                        <label htmlFor="code2" className="block text-sm font-medium leading-6 text-gray-900">
                                            {t('CombineTextCode.TitleCode2')}
                                        </label>
                                        <select
                                            id="code2"
                                            name="code2"
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-black sm:text-sm sm:leading-6"
                                            value={selectedCode2}
                                            onChange={(e) => setSelectedCode2(e.target.value)}
                                            required
                                        >
                                            <option value="">{t('CombineTextCode.SelectPlaceholder')}</option>
                                            {codes?.map((code) => (
                                                <option key={code.uuid} value={code.uuid}>{code.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div>
                                        <label htmlFor="code_name" className="block text-sm font-medium leading-6 text-gray-900">
                                            {t('CombineTextCode.NewName')}
                                        </label>
                                        <div className='flex w-full flex-col mt-2 items-center'>
                                            <input
                                                type="text"
                                                name="code_name"
                                                id="code_name"
                                                required
                                                className="block w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-black focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="code_description" className="block text-sm font-medium leading-6 text-gray-900">
                                            {t('CombineTextCode.NewDescription')}
                                        </label>
                                        <div className='flex w-full flex-col mt-2 items-center'>
                                            <textarea
                                                name="code_description"
                                                id="code_description"
                                                className="block w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-black focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="code_color" className="block text-sm font-medium leading-6 text-gray-900">
                                            {t('CombineTextCode.NewColor')}
                                        </label>
                                        <div className='h-full w-full flex justify-center'>
                                            <GithubPicker
                                                width="full"
                                                triangle="hide"
                                                color={hex}
                                                onChangeComplete={updateColor}
                                                colors={colors_option_2}
                                            />
                                        </div>
                                    </div>



                                    <div className='flex flex-col sm:flex-row gap-2 w-full justify-between items-center mt-8'>
                                        <button
                                            type='button'
                                            onClick={handleClose}
                                            className='border text-black rounded-md px-12 py-2 w-fit'>
                                            {t('CombineTextCode.Cancel')}
                                        </button>
                                        {uploading ? (
                                            <button
                                                type='button'
                                                disabled
                                                className='bg-black text-white rounded-md px-12 py-2 w-fit'>
                                                <Loader />
                                            </button>
                                        ) : (
                                            <button
                                                type='submit'
                                                className='bg-black text-white rounded-md px-12 py-2 w-fit'>
                                                {t('CombineTextCode.Confirm')}
                                            </button>
                                        )}
                                    </div>
                                    <div className='flex justify-start text-sm text-red-600'>{t('CombineTextCode.Warning')}</div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default TextCodeCombinationModal

import { Card, DonutChart, Flex, Legend, Metric, Text } from '@tremor/react';
import React from 'react'

function OverallSentimentCard({ fragment, t, sentimentTranslations, mapSentimentAndColors, speakerNames }) {
    return (
        <Card className='max-h-full f'>
            {fragment.result ?
                <div className="max-w-full">
                    <Flex alignItems="start">
                        <Text>{t('ExpandedMeedingCard.OverallSentimentLavel')}</Text>

                    </Flex>
                    <Flex className="space-x-3 truncate" justifyContent="start" alignItems="baseline">
                        <Metric>{sentimentTranslations((Object.keys(JSON.parse(fragment.result))[0] || "Sentiments"), t('Language'))}</Metric>
                    </Flex>

                    <DonutChart
                        className="mt-6 max-h-32"
                        data={
                            Object.entries(JSON.parse(fragment.result)).map(([key, value]) => {
                                return { name: sentimentTranslations(key, t('Language')), value: parseFloat(value) };
                            })
                        }
                        category="value"
                        index="name"
                        colors={
                            Object.entries(JSON.parse(fragment.result)).map(([key, value]) => (
                                mapSentimentAndColors(key) ? mapSentimentAndColors(key) : "black"
                            ))
                        }
                        showAnimation={true}
                        variant='pie'
                    />
                    <div className='w-full flex justify-center items-center'>
                        <Legend
                            className="mt-3"
                            categories={
                                Object.entries(JSON.parse(fragment.result)).map(([key, value]) => (
                                    (sentimentTranslations(key, t('Language'))) + " (" + value + "%)"
                                ))
                            }
                            colors={
                                Object.entries(JSON.parse(fragment.result)).map(([key, value]) => (
                                    mapSentimentAndColors(key) ? mapSentimentAndColors(key) : "black"
                                ))
                            }
                        />
                    </div>
                </div> : <div className='min-w-full h-32 bg-gray-200 animate-pulse'></div>}
        </Card>
    )
}

export default OverallSentimentCard

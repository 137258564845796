import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
    const cookies = new Cookies();
    return cookies.get("token");
}

export function askGpt(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/projectGpt/ask";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getGptAnswer(uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + "/projectGpt/get_answer/" + uuid;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

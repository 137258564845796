import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { register } from "../../Requests/User/UserRequests";
import cassete from "../../Assets/casset.png";

import logo from "../../Assets/logo.png";
import SuccesModal from "../../Components/SuccesModal";
import ErrorModal from "../../Components/ErrorModal";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader";
import { sendAmplitudeData } from "../../utils/amplitude";
import Cookies from "universal-cookie";
import i18n from "../../i18n";
import { useMyContext } from "../../Components/ContextProviders/CreditsContextProvider";

function password_validate(password) {
  var re = {
    'capital': /[A-Z]/,
    'digit': /[0-9]/,
  };
  return re.capital.test(password) &&
    re.digit.test(password) &&
    (password.length > 7 && password.length < 20);
}


function Register() {
  const [goToLogin, setGoToLogin] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [passwordWarning, setPasswordWarning] = useState(false);
  const { setCredits } = useMyContext();
  const { language, invite_uuid } = useParams()
  const cookies = new Cookies();
  const [userLanguage, setUserLanguage] = useState(language || "en")

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
      localStorage.setItem("language", language)
    }

    invite_uuid && cookies.set("invite_uuid", invite_uuid, { path: '/' })
  }, [])

  let handleSubmit = (event) => {
    event.preventDefault();
    if (!password_validate(event.target.password.value)) {
      setPasswordWarning(t('RegisterPage.PasswordWarning'))
      setIsLoading(false)
      return
    }
    if (event.target.password.value != event.target.password_confirmation.value) {
      setPasswordWarning(t('RegisterPage.PasswordMismatchWarning'))
      setIsLoading(false)
      return
    }
    setFeedbackModal(false)
    setIsLoading(true);
    let data = {
      name: event.target.name.value,
      email: event.target.email.value,
      password: event.target.password.value,
      password_confirmation: event.target.password_confirmation.value,
      language: userLanguage || "en"
    }
    if (cookies.get("invite_uuid")) {
      data["referral"] = cookies.get("invite_uuid")
    }
    let response = register(data);
    response.then((res) => {
      setIsLoading(false);
      cookies.set("token", res.data.token, { path: '/' });
      cookies.set("user_id", res.data.user_id, { path: '/' });
      cookies.set("team_uuid", res.data?.team_uuid || null, { path: '/' });
      cookies.set("credits", res.data?.credits || null, { path: '/' });
      setCredits(parseFloat(res.data.credits))
      if (res.data.team_uuid && res.data.credits) {
        navigate("/dashboard/tutorial")
      } else {
        navigate("/onboarding")
      }

    })
      .catch((error) => {
        setIsLoading(false)
        error?.response?.data?.errors?.email ? (
          setFeedbackModal(
            <ErrorModal
              title={t('RegisterPage.FailModalTitle')}
              body={error?.response?.data?.errors?.email && t('RegisterPage.RegisterEmailError')}
              continue={t('RegisterPage.FailModalButton')}
            />
          )) : error?.response?.data?.errors?.password ? (
            setFeedbackModal(
              <ErrorModal
                title={t('RegisterPage.FailModalTitle')}
                body={error?.response?.data?.errors?.password}
                continue={t('RegisterPage.FailModalButton')}
              />
            )) : <ErrorModal
          title={t('RegisterPage.FailModalTitle')}
          body={t('RegisterPage.FailModalText')}
          continue={t('RegisterPage.FailModalButton')}
        />

      });
  };

  function redirectToLogin() {
    setGoToLogin(true);
  }

  const handleSelectChange = (event) => {
    setUserLanguage(event.target.value);
  };

  useEffect(() => {
    if (goToLogin) {
      navigate(`/login`);
    }
    sendAmplitudeData({ "type": "Navigation", "value": "Register" })
  }, [goToLogin]);

  return (
    <>
      <div className="flex min-h-full bg-white pb-24 md:pb-0">
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={cassete}
            alt=""
          />
        </div>
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className="w-full flex justify-center">
                <img className="h-32 w-auto" src={logo} alt="Your Company" />
              </div>
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                {t('RegisterPage.PageTitle')}
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form className="space-y-6 w-full" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('RegisterPage.NameField')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('RegisterPage.EmailField')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('RegisterPage.PasswordField')}
                    </label>
                    <div className="mt-1">
                      <input

                        onChange={() => { setPasswordWarning(null) }}
                        id="password"
                        name="password"
                        type="password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('RegisterPage.ConfirmPasswordField')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="password_confirmation"
                        name="password_confirmation"
                        type="password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                      {t('TranscriptionUploadModal.Language')}
                    </label>
                    <select
                      id="location"
                      name="location"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-black sm:text-sm sm:leading-6"
                      defaultValue={userLanguage}
                      onChange={handleSelectChange}
                    >
                      <option>  {t('Audio.AudioUpload.Step2.LanguageOption1')}</option>
                      <option>  {t('Audio.AudioUpload.Step2.LanguageOption2')}</option>
                    </select>
                  </div>
                  {passwordWarning && <div className="text-sm text-red-600">{passwordWarning}</div>}

                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <a
                        onClick={redirectToLogin}
                        className="font-medium text-black hover:text-black"
                      >
                        {t('RegisterPage.LoginLink')}
                      </a>
                    </div>
                  </div>

                  <div>{t('RegisterPage.Documents1')} <a className=" underline" href={t('RegisterPage.DocumentsTCUrl')} target="_blank"> {t('RegisterPage.DocumentsTC')}</a> {t('RegisterPage.Documents2')} <a className=" underline" href={t('RegisterPage.DocumentsPPUrl')} target="_blank">{t('RegisterPage.DocumentsPP')}</a></div>

                  <div><button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                  >
                    {isLoading ? <Loader /> :
                      t('RegisterPage.SubmitButton')
                    }
                  </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {feedbackModal}
      </div>
    </>
  );
}

export default Register;

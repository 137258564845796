import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { initAmplitude } from "./utils/amplitude";

import ReactGA from "react-ga4";
import CreditsContextProvider from "./Components/ContextProviders/CreditsContextProvider";
ReactGA.initialize("G-VG05Y70EEK");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CreditsContextProvider>
      <App />
    </CreditsContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

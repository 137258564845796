import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const MyContext = createContext();

function CreditsContextProvider({ children }) {
    // Initialize state from cookies or default to null if not present
    const [credits, setCredits] = useState(() => {
        const savedCredits = Cookies.get('credits');
        return savedCredits !== undefined ? JSON.parse(savedCredits) : null;
    });

    // Update cookies whenever 'credits' changes
    useEffect(() => {
        Cookies.set('credits', JSON.stringify(credits), { expires: 7 }); // Expires in 7 days
    }, [credits]);

    const contextValue = useMemo(() => ({
        credits,
        setCredits
    }), [credits, setCredits]);

    return (
        <MyContext.Provider value={contextValue}>
            {children}
        </MyContext.Provider>
    )
}

export function useMyContext() {
    return useContext(MyContext);
}

export default CreditsContextProvider;

import React, { useEffect, useState } from 'react'
import { getSpeakerColor, newFormatTime } from '../../../../utils/calcs'
import { CloudArrowDownIcon, PlayCircleIcon, SparklesIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { deleteExcerpt } from '../../../../Requests/TextCoderRequests';
import { getUserUuid } from '../../../../utils/cookies';
import { useTranslation } from 'react-i18next';
import { ExportToWord } from './ExportToWord';
const colors = [
    "#A7C7E7",
    "#F3A683",
    "#F7D794",
    "#B8E0D2",
    "#E77F67",
    "#786FA6",
    "#F8A5C2",
    "#63C7B2",
    "#EFA8E4",
    "#F19066",
    "#F3EAC2",
    "#F5D6BA",
    "#C5D1EB",
    "#D6AD60",
    "#92B4A7",
];
function TranscriptionMarker({ textCodes, setSelections, analysis, editableTranscriptions, groupedTranscriptions, speakerNames, pageActiveSelections, selections, selectableGroupUuid, setSelectableGroupUuid, teamMembers, setTeamMembers }) {


    const [selectableGroupIndex, setSelectableGroupIndex] = useState(null);
    const [timeVisibility, setTimeVisibility] = useState(true)
    const { t } = useTranslation()

    function mergeSelections(selections) {
        //console.log(selections)
        let segments = [];

        // Break down selections into start/end segments

        selections.forEach((sel, i) => {

            if (pageActiveSelections.includes(sel.name)) {

                segments.push({ index: sel.start, type: 'start', color: sel.color, id: i, "name": sel.name, "uuid": sel.uuid, "auuid": sel.auuid, author: sel.author });
                segments.push({ index: sel.end, type: 'end', color: sel.color, id: i, "name": sel.name, "uuid": sel.uuid, "auuid": sel.auuid, author: sel.author });
            }
        });

        // Sort by index, placing 'start' types before 'end' types to handle exact overlaps correctly
        segments.sort((a, b) => a.index - b.index || (a.type === 'start' ? -1 : 1));

        let activeSelections = [];
        let lastIdx = 0;
        const mergedSelections = [];

        segments.forEach(segment => {
            // At each 'start' segment, evaluate active selections to create a new segment if needed

            if (segment.type === 'start') {
                if (activeSelections.length > 0) {
                    // Use the first active selection's color; could be more complex in practice
                    mergedSelections.push({ start: lastIdx, end: segment.index, color: activeSelections[0].color, "name": activeSelections[0].name, "uuid": activeSelections[0].uuid, "auuid": activeSelections[0].auuid, "author": segment.author });
                }
                activeSelections.push(segment);
            } else {
                // End segment: finalize the active segment and remove from activeSelections
                mergedSelections.push({ start: lastIdx, end: segment.index, color: activeSelections[0].color, "name": segment.name, "uuid": segment.uuid, "auuid": segment.auuid, "author": segment.author });
                activeSelections = activeSelections.filter(sel => sel.id !== segment.id);
            }

            lastIdx = segment.index;
        });

        // Deduplicate segments by merging adjacent ones with the same color
        const deduplicatedSelections = mergedSelections.reduce((acc, cur) => {

            const last = acc[acc.length - 1];
            if (last && last.end === cur.start && last.color === cur.color) {
                last.end = cur.end; // Extend the last segment
            } else {
                acc.push(cur);
            }
            return acc;
        }, []);

        return deduplicatedSelections;
    }

    const removeSelectionByUuid = (deleted_selection) => {
        setSelections(currentSelections => currentSelections.filter(selection => selection.auuid !== deleted_selection.auuid));
        deleteExcerpt(deleted_selection.auuid)
    };

    const renderTextWithSelections = (uuid, text, selectionsForUuid) => {
        // First, filter and sort selections for the current UUID
        const filteredSelections = selectionsForUuid.filter(sel => sel.uuid === uuid);
        const visibleSelections = filteredSelections.filter(selection => {
            const author = teamMembers?.find(member => member.user_uuid === selection.author);
            if (author?.visible) {
                return selection
            }
        });
        const mergedSelections = mergeSelections(visibleSelections);


        const result = [];
        let lastIndex = 0;
        let segmentCounter = 0;

        mergedSelections.forEach(selection => {
            // Check if the author is visible
            const author = teamMembers.find(member => member.user_uuid === selection.author);
            if (!author || !author.visible) {
                return; // Skip this iteration if the author is not visible
            }

            if (selection.start > lastIndex) {
                // Add unselected text before the current selection
                const unselectedText = text.substring(lastIndex, selection.start);
                result.push(<span key={`unsel-${segmentCounter++}`}>{unselectedText}</span>);
            }

            // Add the selected text with the selection's color
            const selectedText = text.substring(selection.start, selection.end);
            let name = textCodes.find((code) => code.uuid === selection.name);
            result.push(
                <span className="relative shadow-md opacity-70 hover:opacity-100" data-tooltip-id="code-tooltip" data-tooltip-content={name?.name || ''} key={`sel-${segmentCounter++}`} style={{ backgroundColor: name?.color || 'transparent' }}>
                    <span className='whitespace-pre-wrap' >
                        {selectedText}
                    </span>
                    {(selection.author == getUserUuid() || selection.author == "AI") && <span className="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 text-red-600 text-xs p-0 rounded-full bg-transparent z-10"
                        onClick={() => removeSelectionByUuid(selection)}>
                        <XMarkIcon className="w-3 h-3 bg-red-400/40 hover:bg-red-400 text-white rounded-full" />
                    </span>}
                </span>
            );

            lastIndex = selection.end;
        });

        // Add any remaining unselected text
        if (lastIndex < text.length) {
            const remainingText = text.substring(lastIndex);
            result.push(<span key={`rem-${segmentCounter++}`}>{remainingText}</span>);
        }

        return result;
    };



    useEffect(() => {
        const handleMouseUp = () => {
            const selection = window.getSelection();
        };

        document.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [editableTranscriptions, selectableGroupUuid]);


    const toggleTextSelection = (index, uuid) => {
        setSelectableGroupUuid(uuid); // Use setState to update
        setSelectableGroupIndex(selectableGroupIndex === index ? null : index);
    };

    const changeVisibility = (userUuid) => {
        const updatedTeamMembers = teamMembers?.map(member => {
            if (member.user_uuid === userUuid) {
                return { ...member, visible: !member.visible };
            }
            return member;
        });
        setTeamMembers(updatedTeamMembers);
    };


    return (
        <div className='h-full flex flex-col w-full'>
            <div className='mt-4 bg-white  text-back w-full p-4  text-center rounded-lg shadow-xl flex justify-between items-center'>
                {analysis?.name}


                <div className='flex gap-2 items-center'>
                    <span className='text-sm'>{t('TextCoder.Team')}</span>
                    {teamMembers && teamMembers?.map((member) => {
                        return member.user_uuid == "AI" ? <button
                            key={member.user_uuid}
                            onClick={() => { changeVisibility(member.user_uuid) }}
                            className={'rounded-full flex justify-center items-center text-sm w-6 h-6 ' + (member.visible ? 'bg-black text-white' : 'bg-black/20 text-white')}>
                            <SparklesIcon className='w-4 h-4 ' />
                        </button> : <button
                            key={member.user_uuid}
                            onClick={() => { changeVisibility(member.user_uuid) }}
                            className={'rounded-full text-sm w-6 h-6 ' + (member.visible ? 'bg-black text-white' : 'bg-black/20 text-white')}>
                            {member.user_name?.substring(0, 1) || " "}
                        </button>
                    })}
                    {(groupedTranscriptions && speakerNames && selections) && <button
                        onClick={() => ExportToWord(groupedTranscriptions, speakerNames, selections, colors, textCodes, analysis, teamMembers)}
                        className='bg-black text-white text-sm rounded-md flex flex-nowrap w-fit px-4 py-2 gap-2 items-center ml-8'
                    >
                        <CloudArrowDownIcon className='w-6 h-6' />
                        Word
                    </button>}
                </div>

            </div>
            <div className='h-full bg-white p-8 overflow-scroll rounded-lg mt-4'>
                {(editableTranscriptions && groupedTranscriptions && teamMembers) &&

                    (<div>
                        {(groupedTranscriptions.map((group, groupIndex) => (
                            <div key={groupIndex}
                                className={`text-base mt-2 mb-4 ${selectableGroupIndex === groupIndex ? 'enable-selection' : 'disable-selection'}`}
                                onMouseDown={() => { if (selectableGroupIndex != groupIndex) { toggleTextSelection(groupIndex, group.uuid) } }}
                            >
                                <div className="text-base mt-2 flex flex-col">
                                    <div className="flex gap-2 disable-selection">
                                        <div style={{ backgroundColor: getSpeakerColor(speakerNames, speakerNames[group.speaker], colors) }} className="text-black rounded-full px-4 py-1 text-sm w-fit">{speakerNames[group.speaker]}</div>
                                        {timeVisibility &&
                                            <div className=" cursor-pointer underline text-gray-400 flex gap-1 text-sm items-center">
                                                {newFormatTime(group.startTime)} - {newFormatTime(group.endTime)}
                                            </div>}
                                    </div>
                                    <div className="mt-2  ml-2">
                                        <span className='markable'>
                                            {renderTextWithSelections(group.uuid, group.lines, selections)}

                                        </span>
                                    </div>
                                </div>
                            </div>
                        )))}
                    </div>)}
            </div>
        </div>
    )
}

export default TranscriptionMarker

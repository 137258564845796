import { Dialog, Transition } from '@headlessui/react'
import { ChatBubbleBottomCenterTextIcon, DocumentArrowUpIcon, SpeakerWaveIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';

function UploadFileWithTypes({ handleClose, handleNewFileUpload }) {
    const [open, setOpen] = useState(true);
    const { t } = useTranslation()
    const closeModal = () => {
        handleClose()
        setOpen(false)
    }


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex w-full min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 h-full min-h-96">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-bold leading-6 text-gray-900 text-center "
                                >
                                    {t("UploadFileWithTypes.Title")}
                                </Dialog.Title>
                                <div className='text-sm text-gray-600 my-2'>
                                    {t("UploadFileWithTypes.Subtitle")}
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-2 mt-4'>
                                    <button
                                        type='button'
                                        onClick={() => {
                                            handleNewFileUpload("Audio")
                                        }}
                                        className='w-full flex flex-col justify-center items-center gap-2 whitespace-nowrap p-12 shadow-lg border border-black rounded-lg hover:bg-black hover:text-white'>
                                        <SpeakerWaveIcon className='w-12 h-12' />
                                        {t("UploadFileWithTypes.Audio")}
                                    </button>
                                    <button
                                        type='button'
                                        onClick={() => {
                                            handleNewFileUpload("Transcription")
                                        }}
                                        className='w-full flex flex-col justify-center items-center gap-2 whitespace-nowrap p-12 shadow-lg border border-black rounded-lg hover:bg-black hover:text-white'>
                                        <ChatBubbleBottomCenterTextIcon className='w-12 h-12' />
                                        {t("UploadFileWithTypes.Upload transcription")}
                                    </button>
                                    <button
                                        type='button'
                                        onClick={() => {
                                            handleNewFileUpload("Pdf")
                                        }}
                                        className='w-full flex flex-col justify-center items-center gap-2 whitespace-nowrap p-12 shadow-lg border border-black rounded-lg hover:bg-black hover:text-white'>
                                        <DocumentArrowUpIcon className='w-12 h-12' />
                                        {t("UploadFileWithTypes.PDF")}
                                    </button>
                                </div>
                                <div className='flex justify-center mt-4'>
                                    <button className='border bg-black text-white shadow-sm rounded-lg px-4 py-2 text-sm'
                                        onClick={() => closeModal()}
                                    >
                                        {t("UploadFileWithTypes.CancelButton")}
                                    </button>
                                </div>
                            </Dialog.Panel>

                        </Transition.Child>

                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}

export default UploadFileWithTypes

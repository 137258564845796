import React from 'react';
import { useParams } from 'react-router-dom';

const DownloadDocument = () => {
    const { type, language } = useParams();
    const urls = {
        privacy: {
            pt: "https://teampulse.fra1.digitaloceanspaces.com/Documents/PP_1.pdf",
            en: "https://teampulse.fra1.digitaloceanspaces.com/Documents/PP_1.pdf",

        },
        terms: {
            pt: "https://teampulse.fra1.digitaloceanspaces.com/Documents/PP_PT_1.pdf",
            en: "https://teampulse.fra1.digitaloceanspaces.com/Documents/T&C_1.pdf",

        },
    };

    const handleDownload = async () => {
        try {
            const response = await fetch(urls[type][language]);
            if (!response.ok) {
                throw new Error('Failed to fetch document');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = 'document.pdf'; // You can specify the desired file name here.
            document.body.appendChild(a);

            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error downloading the document:', error);
        }
    };

    // Call handleDownload when the component is rendered
    handleDownload();

    return (
        <div>

        </div>
    );
};

export default DownloadDocument;

export function calculateQuestionCost(duration) {
    // Check if duration is a valid number
    if (typeof duration !== 'number' || isNaN(duration)) {
        console.error("Invalid duration:", duration);
        return 0; // or any default value you prefer
    }

    // Check if duration is zero or very small
    if (duration === 0 || Math.abs(duration) < 0.0001) {
        console.error("Duration is too small:", duration);
        return 0; // or any default value you prefer
    }

    // Perform the calculation
    return (duration * (8 / 3600));
}

export function calculateQuestionCostByToken(tokens) {
    return (tokens * 20 * (0.10 / 1000))
}

export function calculateProcessCost(duration) {
    return (duration * (1 / 36))
}
export function formatTime(seconds, decimalPlaces = 0) {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = ((seconds % 60)).toFixed(decimalPlaces);

    let result = '';

    // Add hours and minutes to the result if hours are present
    if (hrs > 0) {
        result += `${hrs}h:${mins.toString().padStart(2, '0')}m`;
    } else if (mins > 0) {
        // Add minutes and seconds to the result if only minutes are present
        result += `${mins}m:${secs.toString().padStart(2, '0')}s`;
    } else {
        // Add seconds to the result if only seconds are present
        result += `${secs}s`;
    }

    return result;
}

export function getSpeakerColor(speakerNames, speaker, colors) {
    const speakerKey = Object.keys(speakerNames).find(key => speakerNames[key] === speaker) || '';
    // Simple hash function on speaker name to get a consistent index
    let hash = 0;
    for (let i = 0; i < speakerKey.length; i++) {
        hash = speakerKey.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash; // Convert to 32bit integer
    }
    hash = Math.abs(hash);

    // Use the hash to select a color
    const colorIndex = hash % colors.length;
    return colors[colorIndex];
}

export function newFormatTime(time) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return [
        hours,
        minutes < 10 ? '0' + minutes : minutes,
        seconds < 10 ? '0' + seconds : seconds,
    ].join(':');
};

export function formatDateTime(dateTimeStr) {
    const months = {
        0: '01', 1: '02', 2: '03', 3: '04',
        4: '05', 5: '06', 6: '07', 7: '08',
        8: '09', 9: '10', 10: '11', 11: '12',
    };

    // Parse the date-time string into a Date object
    const date = new Date(dateTimeStr);

    // Extract individual components
    const day = String(date.getDate()).padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Format the date-time string
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}`;
    return formattedDateTime;
}

import React, { useEffect, useState } from 'react'
import { Card } from '@tremor/react';
import TextCodeModal from '../TextCoder/TextCodeModal';
import { ArrowDownIcon, ArrowUpIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { deleteAiCode, getAiCode, gettranscriptCodes, requestAiCodeFinding } from '../../Requests/AICodeFinder';
import { getTeamUuid } from '../../utils/cookies';
import { waitFor } from '@testing-library/react';
import UpgradedLoader from '../UpgradedLoader';
import { useTranslation } from 'react-i18next';
import NoCreditsModal from '../Billing/NoCreditsModal';
import { useMyContext } from '../ContextProviders/CreditsContextProvider';
import TranscriptionCodeFinder from './TranscriptionCodeFinder';
import { getTranscriptionsCodeFinder, getTranscriptionsCodeFinders, requestTranscriptionCodeFinding } from '../../Requests/TranscriptionCodeFinderRequests';
import { useNavigate } from 'react-router-dom';

function TranscriptionCodeView({ project, setUploadAudioModal, handleUpdateCode, handleAddNewCode, textCodes }) {
    const navigate = useNavigate();
    const [transcriptCodes, setTranscriptCodes] = useState([]);
    const { credits, setCredits } = useMyContext();
    const [waitingCodes, setWaitingCodes] = useState(null);
    const [isAsking, setIsAsking] = useState(true);
    const { t } = useTranslation()
    const [collapsedCards, setCollapsedCards] = useState({});
    const [insufficientCredits, setInsufficientCredits] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            const res = await getTranscriptionsCodeFinders({ "project_uuid": project.uuid, "team_uuid": getTeamUuid() });
            const temp_res = [];
            const waiting_for = [];

            res.data.forEach(coder_block => {
                if (coder_block.status === "Processed") {
                    temp_res.push({ "aiCoder": coder_block });
                } else if (coder_block.status === "Failed") {
                    temp_res.push(coder_block);
                }
                else {
                    waiting_for.push(coder_block);
                }
            });

            if (temp_res.length > 0) setTranscriptCodes(temp_res);
            if (waiting_for.length > 0) setWaitingCodes(waiting_for);
            if (waiting_for.length < 1) setIsAsking(false);
            if (temp_res.length == 0 && waiting_for.length == 0) setIsAsking(false);
        };

        fetchData();
    }, [project.uuid]);



    useEffect(() => {
        const interval = setInterval(async () => {
            if (waitingCodes && waitingCodes.length > 0) {
                let allFailed = true; // Assume all codes have failed initially
                for (const waitingCode of waitingCodes) {
                    if (waitingCode.status != "Failed") {
                        const res = await getTranscriptionsCodeFinder({ "project_uuid": project.uuid, "team_uuid": getTeamUuid(), "uuid": waitingCode.uuid });

                        if (res.data.status === "Processed") {
                            setTranscriptCodes(prev => [{ "aiCoder": waitingCode }, ...prev]);
                            setWaitingCodes(prev => prev.filter(code => code.uuid !== waitingCode.uuid));
                            if (waitingCodes.length === 1) setIsAsking(false);
                        } else if (res.data.status === "Failed") {
                            allFailed = true; // If any code is not failed, update the flag

                            setTranscriptCodes(prev => [res.data, ...prev]);
                            setWaitingCodes(prev => prev.filter(code => code.uuid !== waitingCode.uuid));
                        } else {
                            allFailed = false;
                        }
                    }
                }
                if (allFailed) {
                    clearInterval(interval); // Clear interval if all codes have failed
                    setIsAsking(false);
                }
            } else {
                clearInterval(interval);
            }
        }, 5000); // Check every 5 seconds.

        return () => clearInterval(interval);
    }, [waitingCodes, project.uuid]);


    const requestCodeFind = (data, cost) => {
        setIsAsking(true)
        requestTranscriptionCodeFinding(data).then((res) => {
            setWaitingCodes(prev => {
                setCredits(credits - cost)
                const updatedCodes = [res.data, ...(prev || [])];
                return updatedCodes;
            });
        }).catch((e) => {
            if (JSON.parse(e.request.response)["message"] == "Insufficient credits") {
                setInsufficientCredits(<NoCreditsModal
                    title={t('CreditsModal.Title')}
                    body={t('CreditsModal.Body')}
                    continue={t('CreditsModal.Continue')}
                    navLink="/pricing/credits"
                    handleClose={() => { setInsufficientCredits(null) }} />)
            }
            setIsAsking(false)
        })
    }

    return <div>
        <TranscriptionCodeFinder project={project} isAsking={isAsking} setIsAsking={setIsAsking} requestCodeFind={requestCodeFind} textCodes={textCodes} />
        {transcriptCodes && (
            <>
                <div className='flex flex-col w-full gap-2 my-2'>
                    {transcriptCodes?.map((current_code) => {
                        return (current_code?.aiCoder ? <Card>

                            <div className='flex justify-between items-center gap-4 '>
                                <div className='font-bold '>
                                    {t("AiCodeFinder.Results")}
                                </div>
                                <button className='bg-black text-white rounded-md px-4 py-2 w-fit' onClick={() => { navigate("/text_coder/" + project.uuid) }}>
                                    {t("AiCodeFinder.View")}
                                </button>
                            </div>
                            <div className='text-sm'>
                                {t("AiCodeFinder.Files")}: {project?.analysis?.map((current_analysis) => {
                                    if (JSON.parse(current_code?.aiCoder?.analysis_uuids)?.includes(current_analysis.uuid)) {
                                        return current_analysis.name + "  "
                                    }
                                })}
                            </div>
                            <div className='text-sm'>
                                {t("AiCodeFinder.Codes")}: {textCodes?.map((current_text_code) => {
                                    if (JSON.parse(current_code?.aiCoder?.code_uuids)?.includes(current_text_code.uuid)) {
                                        return current_text_code.name + "  "
                                    }
                                })}
                            </div>
                        </Card> : <Card>

                            <div className='flex justify-between items-center gap-4 '>
                                <div className='font-bold text-red-700'>
                                    {t("AiCodeFinder.Error")}
                                </div>
                            </div>
                        </Card>
                        )
                    })}
                    {waitingCodes?.map((current_waiting_for) => {
                        return (<Card>

                            <div className='flex flex-col gap-4 '>
                                <div className='font-bold '>
                                    {t('AiCodeFinder.FindingCodes')}
                                </div>
                                <UpgradedLoader w={6} h={6} bg="bg-black" />
                            </div>
                        </Card>
                        )
                    })}
                </div>


            </>
        )}

        {insufficientCredits}
    </div>;
}



export default TranscriptionCodeView

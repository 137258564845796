import React, { useEffect, useRef, useState } from 'react'
import { useMyContext } from '../ContextProviders/CreditsContextProvider';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CalculatorIcon, ChatBubbleLeftIcon, CpuChipIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { calculateQuestionCost, calculateQuestionCostByToken, formatDateTime } from '../../utils/calcs';
import { Card, MultiSelect, MultiSelectItem } from '@tremor/react';
import Loader from '../Loader';
import { getTeamUuid } from '../../utils/cookies';
import { askGpt, getGptAnswer } from '../../Requests/ProjectGptRequests';
import NoCreditsModal from '../Billing/NoCreditsModal';
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";

function ProjectGpt({ project }) {
    const { project_uuid } = useParams()
    const { credits, setCredits } = useMyContext();
    const [isAsking, setIsAsking] = useState(false)
    const [sizeWarning, setSizeWarning] = useState(null)
    const [cost, setCost] = useState(null)
    const { t } = useTranslation()
    const [chats, setChats] = useState(null)
    const [canAsk, setCanAsk] = useState(true)

    const [values, setValues] = useState(null)

    useEffect(() => {
        setValues(project?.analysis
            ?.filter(current_analysis => (current_analysis.status === "Processed"))
            .map(current_analysis => current_analysis.uuid))
        setChats(project?.gpt_chats)
    }, [project])

    const [insufficientCredits, setInsufficientCredits] = useState(null)


    const handleAskQuestion = (event) => {
        event.preventDefault()
        setIsAsking(true)
        let data = { "project_uuid": project_uuid, "prompt": event.target.question.value, "analysis_elements": values.join(), "team_uuid": getTeamUuid() }
        setCredits(credits - cost)
        askGpt(data).then((res) => {
            setChats(currentChats => [...currentChats, res.data]);
        }).catch((e) => {
            if (JSON.parse(e.request.response)["message"] == "Insufficient credits") {
                setInsufficientCredits(<NoCreditsModal
                    title={t('CreditsModal.Title')}
                    body={t('CreditsModal.Body')}
                    continue={t('CreditsModal.Continue')}
                    navLink="/pricing/credits"
                    handleClose={() => { setInsufficientCredits(null) }} />)
            }
            setIsAsking(false)
        })
    }

    useEffect(() => {
        let total_duration = 0;
        let token_price = 0
        let size = 0
        project?.analysis?.map((current_analysis) => {
            if (values?.includes(current_analysis.uuid)) {
                if (current_analysis?.tokens) {
                    token_price += parseFloat(calculateQuestionCostByToken(current_analysis.tokens))
                    size += current_analysis.tokens * 2
                } else {
                    total_duration += parseFloat(current_analysis.duration) || 0;
                    size += (7500 * current_analysis.duration / 1800) * 2
                }
            }

        })
        if (size > 100000) { //100000
            setSizeWarning(<p className='mt-2 text-center w-full text-red-600 text-xs'>{t('ProjectGPT.DataWarning')}</p>)
            setCanAsk(false)
        } else {
            setSizeWarning(null)
            setCanAsk(true)
        }
        setCost((parseFloat(calculateQuestionCost(total_duration)) + parseFloat(token_price)))

    }, [values])

    const scrollContainerRef = useRef(null);

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        }
    }, [chats]);

    const fetchChatUpdates = async (uuid) => {
        try {
            const updatedChat = await getGptAnswer(uuid);
            return updatedChat;
        } catch (error) {
            throw error; // Rethrow to handle in the caller
        }
    };

    const updateChatStatus = (uuid) => {
        fetchChatUpdates(uuid).then(updatedChat => {
            if (updatedChat.data?.status === "Processing") {
                setTimeout(() => updateChatStatus(uuid), 3000);
            } else if (updatedChat.data?.status === "Processed") {
                setChats(currentChats => {
                    return currentChats.map(chat => {
                        setIsAsking(false)
                        if (chat.uuid === uuid) {
                            return updatedChat.data;
                        }
                        return chat;
                    });
                });
            } else {
                // In any other case, set the result to "Failed to process the question"
                setChats(currentChats => {
                    setIsAsking(false)
                    return currentChats.map(chat => {
                        if (chat.uuid === uuid) {
                            // Update both the result and status properties of the chat
                            return { ...chat, result: "Failed to process the question", status: "Failed to process" };

                        }
                        return chat;
                    });
                });
            }
        }).catch(error => {
            setChats(currentChats => {
                setIsAsking(false)
                return currentChats.map(chat => {
                    if (chat.uuid === uuid) {
                        return { ...chat, result: "Failed to process the question", status: "Failed to process" };

                    }
                    return chat;
                });
            })
        }
        )
    };

    useEffect(() => {
        chats?.forEach(chat => {
            if (chat.uuid && chat.status === "Processing") {
                updateChatStatus(chat.uuid);
            }
        });
    }, [chats]);


    return (
        <div className='w-full h-full flex flex-col'>
            <div>
                <div className='flex justify-between flex-col md:flex-row'>
                    <div className='flex items-center justify-start mb-4 ml-1'>
                        <MagnifyingGlassIcon className='w-9 h-9' />
                        <div className=' pl-4'>
                            <div className='text-base font-semibold  text-gray-900'>
                                {project?.name}
                            </div>

                            <div className='text-sm text-gray-500'>{formatDateTime(project?.created_at)}</div>

                        </div>

                    </div>

                </div>
            </div>

            <div ref={scrollContainerRef} className='flex flex-col grow bg-gray-100 p-4  overflow-y-scroll my-4 rounded-lg max-h-96'>
                {chats && chats.map((chat) => {
                    return (
                        <div className='py-2' key={chat?.uuid}>
                            <div><span className='font-bold'>{t('ProjectGPT.DocumentsLabel')} </span>
                                {project?.analysis?.map((an) => {
                                    if ((chat.analysis_elements?.split(","))?.includes(an.uuid)) {
                                        return <>{an.name}  </>
                                    }
                                })}
                            </div>
                            <div><span className='font-bold text-lg'>{t('Analysis.QuestionLabel')} </span>{chat.input}</div>
                            <div><span className='font-bold text-lg'>{t('Analysis.AnsweLabel')} </span>
                                <ReactMarkdown
                                    remarkPlugins={[remarkGfm]} className={"py-2" + (chat.result ? " markdown" : " animate-pulse")}>{chat.result || t('Analysis.GeneratingResponse')}</ReactMarkdown>

                            </div>
                        </div>
                    )
                })}
            </div>
            <div>
                <div className='text-sm my-4 print:hidden'>
                    <Card>
                        <div className='flex gap-2 items-center text-lg mb-4'>
                            <ChatBubbleLeftIcon className='border border-black text-black bg-white rounded-md w-6 h-6 px-0.5 py-0.5'></ChatBubbleLeftIcon>
                            Chat
                        </div>
                        <form
                            onSubmit={handleAskQuestion}
                            className='flex flex-col w-full gap-2 items-center'>
                            <div className='w-full'>
                                <p className='ml-1 mb-1 font-bold w-full text-start'>{t('ProjectGPT.DocumentsLabel')}:</p>
                                <MultiSelect onValueChange={setValues} value={values || []} className="z-50 w-full" placeholder={t('Analysis.NewAnalysisModalMeetingsPlaceholder')}>
                                    {project?.analysis?.filter(current_analysis => (current_analysis.status === "Processed")).map((current_analysis) => {
                                        return (<MultiSelectItem key={current_analysis.uuid} value={current_analysis.uuid} icon={CalculatorIcon}>
                                            {current_analysis.name}
                                        </MultiSelectItem>)
                                    })}
                                </MultiSelect>
                            </div>
                            <div className='flex flex-col md:flex-row w-full gap-2 items-end'>
                                <textarea
                                    type="text"
                                    name="question"
                                    id="question"
                                    required
                                    className="block  w-full text-start rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                                    placeholder={t('Analysis.ChatPlaceholder')}
                                >
                                </textarea>
                                <button className={' rounded-lg px-4 py-2 w-full md:w-auto h-fit ' + (canAsk ? "bg-black text-white" : "bg-gray-400 text-white")} disabled={!canAsk}
                                >
                                    {isAsking ? <Loader /> :

                                        <div className='flex justify-between items-center gap-3 '>
                                            <div>
                                                {t('Analysis.ChatButtonLabel')}
                                            </div>
                                            <div className='flex items-center gap-1'>
                                                <div className='font-bold'>{cost?.toFixed(2)}</div>
                                                <CpuChipIcon className='w-5 h-5' />
                                            </div>
                                        </div>
                                    }

                                </button>
                            </div>


                        </form>
                        {sizeWarning}
                    </Card>
                </div>

            </div>
            {insufficientCredits}
        </div>
    )
}

export default ProjectGpt

import { CloudArrowUpIcon } from '@heroicons/react/20/solid';
import { PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Card } from '@tremor/react';
import React, { useState } from 'react';
import { updateMeetingRequest } from '../../Requests/MeetingRequests';
import Loader from '../Loader';
import { useTranslation } from 'react-i18next';

export default function MeetingSection({ icon: Icon, title, content, meeting_uuid, lines, content_name, updateMeeting, view_mode }) {
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [textAreaContent, setTextAreaContent] = useState(content);
    const { t } = useTranslation()

    const handleSave = () => {
        setIsLoading(true)
        let data = { "uuid": meeting_uuid, [content_name]: textAreaContent };
        updateMeetingRequest(data).then((res) => {
            updateMeeting()
            setIsLoading(false)
            setIsEditing(false);
        }).catch(() => {
            setIsLoading(false)
        });
    };

    return (
        <div className='text-sm my-4'>
            <Card>
                <div className='flex justify-between items-center mb-4'>
                    <div className='flex gap-2 items-center text-lg '>
                        <Icon className='border border-black text-black bg-white rounded-md w-6 h-6 px-0.5 py-0.5' />
                        {title}
                    </div>
                    {isLoading ? <div className='border border-black w-20 bg-black text-white p-2 rounded-md gap-2 items-center shadow-md text-sm flex justify-center'>
                        <Loader />
                    </div> : (!isEditing ? (
                        (view_mode != "public") && <button className='border border-black bg-white text-black p-2 rounded-md flex gap-2 items-center shadow-md text-sm'
                            onClick={() => setIsEditing(true)}>
                            <PencilIcon className='w-4 h-4' /> {t('MeetingSection.EditLabel')}
                        </button>
                    ) : (
                        <div className='flex items-center gap-4'>
                            <button className='border border-black bg-white text-black p-2 rounded-md flex gap-2 items-center shadow-md text-sm'
                                onClick={() => setIsEditing(false)}>
                                <XMarkIcon className='w-4 h-4' /> {t('MeetingSection.CancelLabel')}
                            </button>
                            <button className='border border-black bg-black text-white p-2 rounded-md flex gap-2 items-center shadow-md text-sm'
                                onClick={handleSave}>
                                <CloudArrowUpIcon className='w-4 h-4' /> {t('MeetingSection.SaveLabel')}
                            </button>
                        </div>
                    ))}
                </div>
                {!isEditing ? (
                    content ? <div dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, '<br />') }} />
                        : <div className='min-w-full h-32 bg-gray-200 animate-pulse'></div>
                ) : (
                    <textarea
                        type="text"
                        rows={lines}
                        className="py-2 w-full text-sm"
                        value={textAreaContent}
                        onChange={(e) => setTextAreaContent(e.target.value)}
                    />
                )}
            </Card>
        </div>
    );
};

import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
    const cookies = new Cookies();
    return cookies.get("token");
}

export function createOrUpdateProject(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/project";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getProjects(team_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + `/projects/` + team_uuid;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getProject(project_uuid) {
    var url = process.env.REACT_APP_API_BASE_URL + `/project/` + project_uuid;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

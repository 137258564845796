import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getMeeting } from '../../../../Requests/MeetingRequests';
import ExpandedMeetingCard from '../../../../Components/Meetings/ExpandedMeetingCard';
import { AreaChart, Card, Title } from "@tremor/react";


function Meeting() {
    const { meeting_uuid } = useParams()
    const [meetingInfo, setMeetingInfo] = useState(null);

    useEffect(() => {
        if (meeting_uuid) {
            getMeeting(meeting_uuid).then((res) => {
                setMeetingInfo(res.data)
            })
        }
    }, [meeting_uuid])

    const updateMeeting = () => {
        getMeeting(meeting_uuid).then((res) => {
            setMeetingInfo(res.data)
        }).catch((e) => {

        })
    }

    return (
        <div className='md:pl-64 py-12 w-full flex justify-center'>
            <div className='flex flex-col w-full justify-center items-center'>
                <div className='bg-white max-w-7xl rounded-2xl w-full h-full shadow-xl mx-8'>
                    {meetingInfo && <ExpandedMeetingCard meeting={meetingInfo} updateMeeting={updateMeeting} view_mode='private' />}
                </div>
            </div>

        </div>
    )
}

export default Meeting

import { Dialog, Transition } from '@headlessui/react'
import React, { useEffect } from 'react'
import { Fragment, useState } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';

function TutorialVideoModal({ handleClose }) {
    const [open, setOpen] = useState(true);
    const { t } = useTranslation()

    const closeModal = () => {
        handleClose()
        setOpen(false)
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex w-full min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:p-6 h-full ">
                                <Dialog.Title
                                    as="h3"
                                    className="text-xl leading-6 text-gray-900 text-center font-bold"
                                >
                                    {t('Dashboard.TutorialModal')}
                                </Dialog.Title>
                                <div className='flex justify-center'>
                                    <div className='w-full max-w-7xl  aspect-video h-auto'>
                                        <iframe width="100%" height="100%" controls src={t('Dashboard.Video')} className='pt-12'
                                        ></iframe>
                                    </div>
                                </div>
                                <div className='flex justify-center py-4'>
                                    <button className='text-white bg-black rounded-md font-sm px-4 py-2' type='button'
                                        onClick={closeModal}
                                    >
                                        {t('Dashboard.CloseButton')}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}

export default TutorialVideoModal

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getStatus, requestMeetingProcess } from '../../Requests/MeetingRequests'
import { calculateProcessCost, formatDateTime } from '../../utils/calcs'
import { getTeamUuid } from '../../utils/cookies'
import { useMyContext } from '../ContextProviders/CreditsContextProvider'
import Loader from '../Loader'
import { useTranslation } from 'react-i18next'

function AnalysisCard({ analysis }) {

    const { credits, setCredits } = useMyContext();
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [timerId, setTimerId] = useState(null);


    return (
        <div className='flex flex-col shadow-2xl px-4 py-4 w-full bg-white rounded-2xl h-full '>
            <div className='flex justify-between flex-col sm:flex-row'>
                <div className='flex items-center justify-start mb-4 ml-1'>
                    <MagnifyingGlassIcon className='w-9 h-9' />
                    <div className=' pl-4'>
                        <div className='text-base font-semibold  text-gray-900'>
                            {analysis.name}
                        </div>
                        <div className='text-sm text-gray-500'>{formatDateTime(analysis.created_at)}</div>
                    </div>

                </div>
                <button className='text-sm bg-black text-white rounded-xl h-fit px-3 py-2 '
                    onClick={() => {
                        navigate("/deprecated_analysis/" + analysis.uuid)
                    }}
                >
                    {t('Analysis.AnalysisCardButton')}
                </button>
            </div>

        </div >
    )
}

export default AnalysisCard

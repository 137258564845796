export function mapSentimentAndColors(sentiment) {
    let mapping = {
        "Engagement": "green-600",
        "Satisfaction": "blue-600",
        "Confidence": "indigo-600",
        "Enthusiasm": "yellow-600",
        "Frustration": "red-600",
        "Disappointment": "purple-600",
        "Confusion": "gray-600",
        "Stress": "orange-600",
        "Curiosity": "teal-600",
        "Hesitance": "amber-600"
    }
    return mapping[sentiment]
}

export function sentimentTranslations(sentiment, language = "en") {
    if (language == "pt") {
        let mapping = {
            "Engagement": "Foco",
            "Satisfaction": "Satisfação",
            "Confidence": "Confiança",
            "Enthusiasm": "Entusiasmo",
            "Frustration": "Frustração",
            "Disappointment": "Desapontamento",
            "Confusion": "Confusão",
            "Stress": "Stress",
            "Curiosity": "Curiosidade",
            "Hesitance": "Hesitação"
        }
        return mapping[sentiment]
    } else if (language == "es") {
        let mapping = {
            "Engagement": "Compromiso",
            "Satisfaction": "Satisfacción",
            "Confidence": "Confianza",
            "Enthusiasm": "Entusiasmo",
            "Frustration": "Frustración",
            "Disappointment": "Decepción",
            "Confusion": "Confusión",
            "Stress": "Estrés",
            "Curiosity": "Curiosidad",
            "Hesitance": "Vacilación"
        }
        return mapping[sentiment]
    } else {
        return sentiment
    }
}

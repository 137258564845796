import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
    const cookies = new Cookies();
    return cookies.get("token");
}

export function createOrEditAnalysis(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/analysis/create`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getAiCodes(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/getAiCodeFinders`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function requestAiCodeFinding(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/requestAiCodeFinding`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getAiCode(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/getAiCodeFinder`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function deleteAiCode(data) {
    var url = process.env.REACT_APP_API_BASE_URL + `/deleteAiCodeFinder`;
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

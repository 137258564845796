import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getInviteLink } from '../../Requests/User/UserRequests'
import SuccessPopup from '../PopupNotifications/SuccessPopup'



function TeamMemberTable({ teamMembers }) {


    const { t } = useTranslation()
    const [copyModal, setCopyModal] = useState(false)

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle px-4 lg:px-8">
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 ">
                                        {t('Team.TeamMemberTable.NameColumn')}
                                    </th>
                                    <th scope="col" className="hidden md:table-cell  px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        {t('Team.TeamMemberTable.RoleColumn')}
                                    </th>
                                    <th scope="col" className="hidden md:table-cell  px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        {t('Team.TeamMemberTable.StatusColumn')}
                                    </th>
                                    <th scope="col" className="hidden md:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        {t('Team.TeamMemberTable.MoodColumn')}
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 text-right">
                                        {t('Team.TeamMemberTable.ActionColumn')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {teamMembers.map((teamMember) => (
                                    <tr key={teamMember.email}>
                                        <td className="whitespace-nowrap py-5 pl-0 pr-3 text-sm sm:pl-0">
                                            <div className="flex items-center">
                                                <div className="ml-4">
                                                    <div className="font-medium text-gray-900">{teamMember.name || t('Team.TeamMemberTable.MemberNotRegistered')}</div>
                                                    <div className="mt-1 text-gray-500">{teamMember.email}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="hidden md:table-cell whitespace-nowrap px-3 py-5 text-sm text-gray-500">{teamMember.role}</td>
                                        <td className="hidden md:table-cell whitespace-nowrap px-3 py-5 text-sm text-gray-500">{teamMember.name ? t('Team.TeamMemberTable.OnboardedStatus') : t('Team.TeamMemberTable.OnboardingStatus')}</td>
                                        <td className="hidden md:table-cell whitespace-nowrap px-3 py-5 text-sm text-gray-500">{t('Team.TeamMemberTable.MoodUnavailable')}</td>
                                        <td className=" relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                            <button className="text-black hover:text-black" onClick={() => {
                                                getInviteLink(teamMember.uuid).then((res) => {
                                                    setCopyModal(true)
                                                }).catch((e) => {

                                                })
                                            }}>
                                                {teamMember.name ? t('Team.TeamMemberTable.ActionButton') : t('Team.TeamMemberTable.ActionButtonBeforeActive')}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {copyModal && <SuccessPopup text={t('Team.TeamMemberTable.CopyMoodalLabel')} handleClose={() => { setCopyModal(false) }} />}
        </div>
    )
}

export default TeamMemberTable

import { CalculatorIcon, CalendarIcon, ChatBubbleLeftIcon, CpuChipIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { calculateQuestionCost, formatDateTime } from '../../utils/calcs'
import { useParams } from 'react-router-dom'
import { askAnalysis, createOrEditAnalysis, getAnalysis } from '../../Requests/AnalysisRequests'
import { Card, Flex, Icon, MultiSelect, MultiSelectItem, Text } from '@tremor/react'
import Loader from '../Loader'
import CreateAnalysis from './CreateAnalysis'
import { getTeamUuid } from '../../utils/cookies'
import { useTranslation } from 'react-i18next'
import { useMyContext } from '../ContextProviders/CreditsContextProvider'
import NoCreditsModal from '../Billing/NoCreditsModal'

function ExpandedAnalysisCard() {
    const { credits, setCredits } = useMyContext();
    const { analysis_uuid } = useParams()
    const [analysis, setAnalysis] = useState(null)
    const [questionAnswer, setQuestionAnswer] = useState(null)
    const [isAsking, setIsAsking] = useState(false)
    const [answer, setAnswer] = useState(false)
    const [meeting_uuids, setMeetingUuids] = useState(null)
    const { t } = useTranslation()

    useEffect(() => {
        if (analysis_uuid) {
            getAnalysis({ "analysis_uuid": analysis_uuid }).then((res) => {
                setAnalysis(res.data.analysis)
                if (res.data.analysis.meetings?.length > 0) {
                    let temp_meetings = []
                    res.data.analysis.meetings.map((meeting) => {
                        temp_meetings.push(meeting.uuid)
                    })
                    setMeetingUuids(temp_meetings)
                }
                setQuestionAnswer(res.data.questions_and_aswers)
            }).catch((e) => {

            })
        }
    }, [analysis_uuid])

    const [values, setValues] = useState(null)

    const [insufficientCredits, setInsufficientCredits] = useState(null)

    const handleCreateAnalysis = (event) => {
        event.preventDefault();
        let data = { "name": event.target.name.value, "meeting_uuids": values, "language": analysis.language, "team_uuid": getTeamUuid() }
        createOrEditAnalysis(data).then((res) => {
            window.location.reload(false)
        }).catch((e) => {

        })
    }

    const handleAskQuestion = (event) => {
        setIsAsking(true)
        event.preventDefault()
        let data = { "prompt": event.target.question.value, "analysis_uuid": analysis_uuid, "team_uuid": getTeamUuid() }
        askAnalysis(data).then((res) => {
            setCredits((credits - parseFloat(analysis.question_cost)))
            setQuestionAnswer(prevQuestionAnswer => [...prevQuestionAnswer, res.data]);
            setIsAsking(false)
        }).catch((e) => {
            if (JSON.parse(e.request.response)["message"] == "Insufficient credits") {
                setInsufficientCredits(<NoCreditsModal
                    title={t('CreditsModal.Title')}
                    body={t('CreditsModal.Body')}
                    continue={t('CreditsModal.Continue')}
                    navLink="/pricing/credits"
                    handleClose={() => { setInsufficientCredits(null) }} />)
            }
            setIsAsking(true)
        })
    }

    return (
        <div className='shadow-2xl px-4 py-4 w-full bg-white rounded-2xl h-full flex flex-col'>
            <div>
                <div className='flex justify-between flex-col md:flex-row'>
                    <div className='flex items-center justify-start mb-4 ml-1'>
                        <MagnifyingGlassIcon className='w-9 h-9' />
                        <div className=' pl-4'>
                            <div className='text-base font-semibold  text-gray-900'>
                                {analysis?.name}
                            </div>

                            <div className='text-sm text-gray-500'>{formatDateTime(analysis?.created_at)}</div>

                        </div>

                    </div>

                </div>

                {analysis &&
                    <div className="flex flex-col gap-2 mt-2">
                        <label className=" whitespace-nowrap text-sm text-gray-500">
                            {t('Analysis.NewAnalysisModalMeetingsLabel')}
                        </label>
                        <MultiSelect disabled value={meeting_uuids} className="z-50 w-full">
                            {analysis.meetings?.map((meeting) => {
                                return (
                                    <MultiSelectItem disabled value={meeting.uuid} icon={CalculatorIcon}>
                                        {meeting.name}
                                    </MultiSelectItem>)
                            })}
                        </MultiSelect>

                    </div>
                }
            </div>

            <div className='flex flex-col grow bg-gray-100 p-4  overflow-y-scroll my-4 rounded-lg'>
                {questionAnswer && questionAnswer.map((questionPair) => {
                    return (
                        <div className='py-2'>
                            <div><span className='font-bold'>{t('Analysis.QuestionLabel')} </span>{questionPair.prompt}</div>
                            <div><span className='font-bold'>{t('Analysis.AnsweLabel')} </span>{questionPair.answer}</div>
                        </div>
                    )
                })}
            </div>
            <div>
                <div className='text-sm my-4 print:hidden'>
                    <Card>
                        <div className='flex gap-2 items-center text-lg mb-4'>
                            <ChatBubbleLeftIcon className='border border-black text-black bg-white rounded-md w-6 h-6 px-0.5 py-0.5'></ChatBubbleLeftIcon>
                            Chat
                        </div>
                        <form
                            onSubmit={handleAskQuestion}
                            className='flex flex-col md:flex-row gap-2 items-center'>
                            <textarea
                                type="text"
                                name="question"
                                id="question"
                                className="block  w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                                placeholder={t('Analysis.ChatPlaceholder')}
                            >
                            </textarea>
                            <button className='bg-black text-white rounded-lg px-4 py-2 w-full md:w-auto'
                            >
                                {isAsking ? <Loader /> :
                                    <div className='flex justify-between items-center gap-3'>
                                        <div>
                                            {t('Analysis.ChatButtonLabel')}
                                        </div>
                                        <div className='flex items-center gap-1'>
                                            <div className='font-bold'>{analysis?.question_cost?.toFixed(2)}</div>
                                            <CpuChipIcon className='w-5 h-5' />
                                        </div>
                                    </div>}

                            </button>
                        </form>

                    </Card>
                </div>

            </div>
            {insufficientCredits}
        </div>
    )
}

export default ExpandedAnalysisCard

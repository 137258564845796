import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { getTeamUuid } from '../../utils/cookies'
import { createOrUpdateProject } from '../../Requests/ProjectRequests'
import Loader from '../Loader'

export default function CreateEditProjectSlideover({ project = null, setProject = null, handleClose = null }) {
    const [open, setOpen] = useState(true)
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const [language, setLanguage] = useState(project?.language ? project.language : "pt")


    const handleSelectChange = (event) => {
        setLanguage(event.target.value);
    };

    const close = () => {
        setOpen(false);
        handleClose()
    }

    const handleSubmitProject = (event) => {
        event.preventDefault();
        setLoading(true)
        let data = {
            "team_uuid": getTeamUuid(),
            "name": event.target.name.value
        }
        if (project) {
            data["uuid"] = project.uuid
        }
        if (event.target.description.value) {
            data["description"] = event.target.description.value
        }
        data["language"] = language;
        createOrUpdateProject(data).then((res) => {
            window.location.reload(false)
        }).catch(() => {
            setLoading(false)
        })


    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={close}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl rounded-l-xl">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                    {t('ProjectSlideover.Title')}
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                                                        onClick={close}
                                                    >
                                                        <span className="absolute -inset-2.5" />
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                            <form className='flex flex-col h-full justify-between' onSubmit={handleSubmitProject}>
                                                <div className='flex gap-2 w-full  flex-col mt-4 items-center justify-center gap-4'>
                                                    <div className='w-full'>
                                                        <label className='text-sm text-gray-700 '>
                                                            {t('ProjectSlideover.NameLabel')}
                                                            :<span className='text-red-600 ml-0.5'>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            required
                                                            className="block max-w-md w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black text-sm  mt-2"
                                                            placeholder={t('ProjectSlideover.NamePlaceholder')}
                                                            defaultValue={project?.name}
                                                        >
                                                        </input>
                                                    </div>
                                                    <div className='w-full'>
                                                        <label className='text-sm text-gray-700 '>
                                                            {t('ProjectSlideover.DescriptionLabel')}
                                                            :</label>
                                                        <textarea
                                                            type="text"
                                                            name="description"
                                                            id="description"
                                                            rows={3}
                                                            className="block max-w-md w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black text-sm  mt-2"
                                                            placeholder={t('ProjectSlideover.DescriptionPlaceholder')}
                                                            defaultValue={project?.description}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <div className='w-full'>
                                                        <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                                                            {t('Audio.AudioUpload.Step2.LanguageLabel')}
                                                        </label>
                                                        <select
                                                            id="location"
                                                            name="location"
                                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-black sm:text-sm sm:leading-6"
                                                            defaultValue={project?.language}
                                                            onChange={handleSelectChange}
                                                        >
                                                            <option>  {t('Audio.AudioUpload.Step2.LanguageOption1')}</option>
                                                            <option>  {t('Audio.AudioUpload.Step2.LanguageOption2')}</option>
                                                            <option>  {t('Audio.AudioUpload.Step2.LanguageOption3')}</option>
                                                        </select>
                                                        <div className='text-xs mt-2 text-gray-700'>{t('ProjectSlideover.LanguageWarning')}</div>
                                                    </div>
                                                </div>
                                                <div className='flex justify-end'>
                                                    {loading ? <div className='bg-black text-white rounded-md px-12 py-2 w-fit'>
                                                        <Loader></Loader>
                                                    </div> : <button
                                                        className='bg-black text-white rounded-md px-12 py-2 w-fit'>
                                                        {project ? t('ProjectSlideover.ButtonUpdate') : t('ProjectSlideover.ButtonCreate')}
                                                    </button>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

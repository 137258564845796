import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
    const cookies = new Cookies();
    return cookies.get("token");
}


export function textAnalysis(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/document/upload";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function processtextAnalysis(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/pdf/process";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

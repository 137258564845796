import { Bars2Icon, Bars3Icon, ChatBubbleLeftIcon, CheckIcon, CircleStackIcon, CpuChipIcon, EyeIcon, ListBulletIcon, TrashIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, CategoryBar, DonutChart, Flex, Grid, Legend, Metric, Text, Title } from "@tremor/react";
import { audioChat, getMeetingTranscript, updateSpeakers } from '../../Requests/MeetingRequests';
import { mapSentimentAndColors, sentimentTranslations } from '../../utils/mappings';
import { Tracker } from "@tremor/react";
import { calculateQuestionCost, formatDateTime, formatTime } from '../../utils/calcs';
import { useMyContext } from '../ContextProviders/CreditsContextProvider';
import ViewTranscription from './ViewTranscription';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';
import IdentifyUsersModal from './IdentifyUsersModal';
import MeetingOptionsMenu from './MeetingOptionsMenu';
import DeleteMeetingBlocksModal from './DeleteMeetingBlocksModal';
import MeetingSection from './MeetingSection';
import MeetingPermissions from './MeetingPermissions';
import NoCreditsModal from '../Billing/NoCreditsModal';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

/*Confirm if deprecated */
function ExpandedMeetingCard({ meeting, updateMeeting, view_mode = "private" }) {
    const { t } = useTranslation()
    const [answer, setAnswer] = useState(null);
    const { credits, setCredits } = useMyContext();
    const [transcription, setTranscription] = useState(null)
    const [modal, setModal] = useState(null)
    const [isAsking, setIsAsking] = useState(false)
    const [speakerNames, setSpeakerNames] = useState(false)
    const [deletePopUp, setDeletePopUp] = useState(null)
    const [insufficientCredits, setInsufficientCredits] = useState(null)


    const handleQuestion = (e) => {
        e.preventDefault()
        setIsAsking(true)
        let data = {
            "meeting_uuid": meeting.uuid,
            "prompt": e.target.question.value
        }
        audioChat(data).then((res) => {
            setAnswer(res.data)

            setCredits((credits - calculateQuestionCost(meeting.duration)))
            setIsAsking(false)
        }).catch((e) => {
            if (JSON.parse(e.request.response)["message"] == "Insufficient credits") {
                setInsufficientCredits(<NoCreditsModal
                    title={t('CreditsModal.Title')}
                    body={t('CreditsModal.Body')}
                    continue={t('CreditsModal.Continue')}
                    navLink="/pricing/credits"
                    handleClose={() => { setInsufficientCredits(null) }} />)
            }
            setIsAsking(false)
        })
    }

    const navigate = useNavigate()

    const SpeakerColors = [
        "emerald-500", "yellow-500", "purple-500", "red-500", "lime-500",
        "zinc-500", "gray-500", "indigo-500", "orange-500", "black",
        "teal-500", "cyan-500", // ... More colors if needed
    ];

    const getSpeakerColorRaw = (speaker) => {
        // Extract the letter (e.g., 'A', 'B', etc.) from the speaker identifier (e.g., 'Speaker A')
        const letter = (speaker.split(' ')[1]).split('-')[0];
        if (!letter) return SpeakerColors[0]; // Default color if no letter is found

        // Get the index based on the ASCII code of the letter
        const index = letter.charCodeAt(0) - 'A'.charCodeAt(0);
        return SpeakerColors[index % SpeakerColors.length];
    };

    // Function to get color for a speaker
    const getSpeakerColor = (letter) => {

        // Get the index based on the ASCII code of the letter
        const index = letter.charCodeAt(0) - 'A'.charCodeAt(0);
        return SpeakerColors[index % SpeakerColors.length];
    };

    const getLegendData = (sentimentData) => {
        const parsedData = JSON.parse(sentimentData);


        // Extract the speaker letters
        const letters = [];
        Object.keys(parsedData).forEach(speaker => {
            // Extract the letter (e.g., 'A', 'B', etc.) from the speaker identifier (e.g., 'Speaker A')
            const parts = speaker?.includes('Speaker ') ? speaker.split(' ')[1] : speaker;
            const letter = parts.split('-')[0];

            // If the letter is not already in the array, add it
            if (!letters?.includes(letter)) {
                letters.push(letter);
            }
        });
        return letters.map((letter) => {
            return {
                label: `Speaker ${letter}`,
                color: getSpeakerColor(letter)
            };
        });
    };



    useEffect(() => {
        let names = null
        if (meeting.speaker_names) {
            names = JSON.parse(meeting.speaker_names)
            setSpeakerNames(names)
        }
        getMeetingTranscript(meeting.uuid).then((res) => {
            const lines = res.data.split("\n").map((line) => {
                const parts = line.split(":");
                const timeAndSpeaker = parts[0].trim(); // Trim any leading/trailing whitespace
                const speakerMatch = timeAndSpeaker.match(/(Speaker\s+[A-Z])$/); // Regex to match 'Speaker <letter>' at the end of the string
                const speakerKey = speakerMatch ? speakerMatch[0] : ""; // Extract the speaker key if it exists
                const time = speakerKey ? timeAndSpeaker.replace(speakerKey, "").trim() : timeAndSpeaker; // Extract the time part

                // Replace speakerKey with the value from speakerNames if it exists
                let speakerName = speakerKey
                if (names) {
                    speakerName = names[speakerKey] ? names[speakerKey] : speakerKey;
                }

                const final_line = {
                    staticPart: `${time} ${speakerName}:`,
                    editablePart: parts.slice(1).join(":").trim() || "", // Join back the remaining parts if there was a ':' in the editable part
                };
                if (final_line?.editablePart != "") {
                    return final_line
                } else {
                    return
                }
            });
            const filteredLines = lines.filter(line => line !== undefined);

            if (!filteredLines.every(element => element === undefined)) {
                setTranscription(filteredLines);
            } else {
                setTranscription(null)
            }
        });

    }, [meeting])



    const handleSaveNames = (names) => {
        let data = { "speaker_names": JSON.stringify(names), "meeting_uuid": meeting.uuid }
        updateSpeakers(data).then((res) => {
            window.location.reload(false)
        }).catch((e) => {

        })
    }


    const handleEditNames = () => {
        setModal(<IdentifyUsersModal handleClose={() => { setModal(null) }} speakerNames={speakerNames} handleSaveNames={handleSaveNames} />)
    }

    const handleDeleteData = () => {
        setModal(<DeleteMeetingBlocksModal handleClose={() => { setModal(null) }} meeting={meeting} setDeletePopUp={setDeletePopUp} updateMeeting={updateMeeting} />)

    }

    const handleVisibilitySettings = () => {
        setModal(<MeetingPermissions handleClose={() => { setModal(null) }} meeting={meeting} setDeletePopUp={setDeletePopUp} updateMeeting={updateMeeting} />)
    }


    return (
        <div className='shadow-2xl px-4 py-4 w-full bg-white rounded-2xl'>
            <div className='flex justify-between flex-col md:flex-row'>
                <div className='flex items-center justify-start mb-4 ml-1'>
                    <CircleStackIcon className='w-9 h-9' />
                    <div className=' pl-4'>
                        <div className='text-base font-semibold  text-gray-900'>
                            {meeting.name}
                        </div>
                        <div className='text-sm text-gray-500'>{formatDateTime(meeting.created_at)}</div>
                    </div>

                </div>
                <div className='flex flex-col md:flex-row gap-4 justify-between items-center print:hidden mb-4 md:mb-0 '>

                    {/*speakerNames && <button className='text-sm border-2 border-black  rounded-xl h-fit px-4 py-2 shadow-lg'
                        onClick={() => {
                            setModal(<IdentifyUsersModal handleClose={() => { setModal(null) }} speakerNames={speakerNames} handleSaveNames={handleSaveNames} />)
                        }}
                    >
                        <div className='flex justify-between gap-2 items-center'>
                            <UserGroupIcon className=' w-4 h-4' />
                            {t('TranscriptionModal.UpdateNamesTitle')}
                        </div>
                    </button>
                    */}

                    {transcription && <button className='text-sm border-2 border-black bg-black text-white rounded-xl h-fit px-4 py-2 shadow-lg'
                        onClick={() => {
                            setModal(<ViewTranscription handleClose={() => { setModal(null) }} transcription={transcription} setTranscription={setTranscription} meeting={meeting} view_mode={view_mode} />)
                        }}
                    >
                        <div className='flex justify-between gap-2 items-center'>
                            <EyeIcon className=' w-4 h-4' />
                            {t('ExpandedMeedingCard.ViewTranscriptionButton')}
                        </div>
                    </button>
                    }
                    {!(view_mode == "public") && <div className="py-1">
                        <button
                            type='button'
                            onClick={() => handleDeleteData()}
                            href="#"
                            className={'group flex items-center px-4 py-2 text-sm w-full'}
                        >
                            <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            {t('ExpandedMeetingMenu.DeleteItemsLabel')}
                        </button>
                    </div>}

                    {!(view_mode == "public") && <MeetingOptionsMenu meeting={meeting} handleEditNames={handleEditNames} handleDeleteData={handleDeleteData} handleVisibilitySettings={handleVisibilitySettings} />}


                    {/*<button className='text-sm bg-black text-white rounded-xl h-fit px-4 py-2 border-2 border-black shadow-lg'

                >{t('ExpandedMeedingCard.EditButton')}</button>*/}
                </div>
            </div>

            <Grid numItemsSm={2} numItemsLg={2} className="gap-6">
                <Card className='max-h-96'>
                    {meeting.overall_sentiment ?
                        <div className="max-w-lg">
                            <Flex alignItems="start">
                                <Text>{t('ExpandedMeedingCard.OverallSentimentLavel')}</Text>

                            </Flex>
                            <Flex className="space-x-3 truncate" justifyContent="start" alignItems="baseline">
                                <Metric></Metric>
                            </Flex>

                            <DonutChart
                                className="mt-6 max-h-32"
                                data={
                                    Object.entries(JSON.parse(meeting.overall_sentiment)).map(([key, value]) => {
                                        return { name: sentimentTranslations(key, t('Language')), value: parseFloat(value) };
                                    })
                                }
                                category="value"
                                index="name"
                                colors={
                                    Object.entries(JSON.parse(meeting.overall_sentiment)).map(([key, value]) => (
                                        mapSentimentAndColors(key) ? mapSentimentAndColors(key) : "black"
                                    ))
                                }
                                showAnimation={true}
                                variant='pie'
                            />
                            <div className='w-full flex justify-center items-center'>
                                <Legend
                                    className="mt-3"
                                    categories={
                                        Object.entries(JSON.parse(meeting.overall_sentiment)).map(([key, value]) => (
                                            (sentimentTranslations(key, t('Language'))) + " (" + value + "%)"
                                        ))
                                    }
                                    colors={
                                        Object.entries(JSON.parse(meeting.overall_sentiment)).map(([key, value]) => (
                                            mapSentimentAndColors(key) ? mapSentimentAndColors(key) : "black"
                                        ))
                                    }
                                />
                            </div>
                        </div> : <div className='min-w-full h-32 bg-gray-200 animate-pulse'></div>}
                </Card>
                <Card className='flex flex-col h-full justify-between max-h-96'>
                    {meeting.overall_participation ?
                        <>
                            <div>
                                <Flex alignItems="start">
                                    <Text>{t('ExpandedMeedingCard.TimeSpokenLabel')}</Text>
                                </Flex>
                                <Flex className="space-x-3 truncate" justifyContent="start" alignItems="baseline">
                                    <Metric>{formatTime(meeting.duration)}</Metric>
                                </Flex>
                            </div>

                            <div>

                                <DonutChart
                                    className="mt-6 max-h-32"
                                    data={
                                        Object.entries(JSON.parse(meeting.overall_participation)).map(([key, value]) => {
                                            return { name: key, value: parseFloat(value) };
                                        })
                                    }
                                    category="value"
                                    index="name"
                                    colors={["emerald-500", "yellow-500", "purple-500", "red-500", "lime-500", "zinc-500", "gray-500", "indigo-500", "orange-500", "black", "teal-500", "cyan-500"]}
                                    showAnimation={true}
                                    showLabel={false}
                                />

                            </div>





                            <div className='w-full flex justify-center items-center'>
                                <Legend
                                    className="mt-3"
                                    categories={
                                        Object.entries(JSON.parse(meeting.overall_participation)).map(([key, value]) => (
                                            (key) + " (" + value + "%)"
                                        ))
                                    }
                                    colors={["emerald-500", "yellow-500", "purple-500", "red-500", "lime-500", "zinc-500", "gray-500", "indigo-500", "orange-500", "black", "teal-500", "cyan-500"]}
                                />
                            </div>
                        </>
                        : <div className='min-w-full h-32 bg-gray-200 animate-pulse'></div>}
                </Card>

            </Grid>
            <MeetingSection
                icon={Bars2Icon}
                title={t('ExpandedMeedingCard.SummaryLabel')}
                content={meeting.text_summary}
                meeting_uuid={meeting.uuid}
                lines={8}
                content_name="text_summary"
                updateMeeting={updateMeeting}
                view_mode={view_mode}
            />

            <MeetingSection
                icon={ListBulletIcon}
                title={t('ExpandedMeedingCard.SummaryByPointsLabel')}
                content={meeting.bullets_summary}
                meeting_uuid={meeting.uuid}
                lines={20}
                content_name="bullets_summary"
                updateMeeting={updateMeeting}
                view_mode={view_mode}
            />

            <MeetingSection
                icon={CheckIcon}
                title={t('ExpandedMeedingCard.TasksLavel')}
                content={meeting.tasks}
                meeting_uuid={meeting.uuid}
                lines={12}
                content_name="tasks"
                updateMeeting={updateMeeting}
                view_mode={view_mode}
            />

            {/** */}
            <Card className='flex flex-col h-full justify-between my-4'>
                <Flex className="space-x-3 truncate mb-4" justifyContent="start" alignItems="baseline">
                    <Metric>{t('ExpandedMeedingCard.MeetingProgressionLabel')}</Metric>
                </Flex>

                {meeting.time_based_participation ?
                    <>
                        <div>
                            <Flex alignItems="start">
                                <Text>{t('ExpandedMeedingCard.SpeakerOverTimeLabel')}</Text>
                            </Flex>

                        </div>

                        <div>
                            <CategoryBar className="mt-4"
                                values={
                                    Object.entries(JSON.parse(meeting.time_based_participation)).map(([key, value]) => {
                                        return value / meeting.duration
                                    })
                                }
                                colors={
                                    Object.entries(JSON.parse(meeting.time_based_participation)).map(([key, value]) => {
                                        return getSpeakerColorRaw(key)
                                    })

                                }
                                showLabels={false}
                            />

                        </div>
                        <div className='w-full flex justify-center items-center'>
                            <Legend
                                className="mt-3"
                                categories={
                                    getLegendData(meeting.time_based_participation).map(item => item.label)
                                }
                                colors={
                                    getLegendData(meeting.time_based_participation).map(item => item.color)
                                }
                            />
                        </div>

                    </>
                    : <div className='min-w-full h-32 bg-gray-200 animate-pulse'></div>}


                {meeting.time_based_sentiment ?
                    <>

                        <div>
                            <Flex alignItems="start">
                                <Text>{t('ExpandedMeedingCard.DominantSentimentOverTime')}</Text>
                            </Flex>
                        </div>

                        <div>
                            <Tracker className="mt-4"
                                data={
                                    Object.entries(JSON.parse(meeting.time_based_sentiment)).map(([key, value]) => {
                                        return { tooltip: (sentimentTranslations(value, t('Language'))), color: mapSentimentAndColors(value) };
                                    })
                                }
                            />

                        </div>
                        {meeting.time_based_sentiment && <div className='w-full flex justify-center items-center'>
                            <Legend
                                className="mt-3"
                                categories={
                                    [...new Set(Object.values(JSON.parse(meeting.time_based_sentiment)).map(value => (
                                        sentimentTranslations(value, t('Language'))
                                    )))]
                                }
                                colors={
                                    [...new Set(Object.values(JSON.parse(meeting.time_based_sentiment)).map(value => (
                                        mapSentimentAndColors(value) ? mapSentimentAndColors(value) : "black"
                                    )))]
                                }
                            />
                        </div>}

                    </>
                    : <div className='min-w-full h-32 bg-gray-200 animate-pulse'></div>}
            </Card>

            {/*meeting.people_based_sentiment &&
                <Card className='flex w-full justify-evenly'>
                    {JSON.parse(meeting.people_based_sentiment).map((person_sentiment) => {
                        return (Object.entries(person_sentiment).map(([speaker, attributes]) => {
                            return (<div className='w-full flex flex-col justify-center'>
                                <div className='text-center'>{speaker}</div>
                                <DonutChart
                                    className="mt-6 max-h-32"
                                    data={
                                        Object.entries(attributes).map(([key, value]) => {
                                            { console.log(key, parseFloat(value.replace("%", ""))) }
                                            return { name: key, value: parseFloat(value.replace("%", "")) };
                                        })
                                    }
                                    category="value"
                                    index="name"
                                    colors={
                                        Object.entries(attributes).map(([key, value]) => (
                                            mapSentimentAndColors(key) ? mapSentimentAndColors(key) : "black"
                                        ))
                                    }
                                    showAnimation={true}
                                    variant='pie'
                                />
                                {<div className='w-full flex justify-center items-center'>
                                    <Legend
                                        className="mt-3"
                                        categories={
                                            Object.entries(attributes).map(([key, value]) => (
                                                (key)
                                            ))
                                        }
                                        colors={
                                            Object.entries(attributes).map(([key, value]) => (
                                                mapSentimentAndColors(key) ? mapSentimentAndColors(key) : "black"
                                            ))
                                        }
                                    />
                                </div>}
                            </div>)
                        }));
                    })}

                </Card>
                */}
            {view_mode != "public" &&
                <>
                    <div className='text-sm my-4 print:hidden'>
                        <Card>
                            <div className='flex gap-2 items-center text-lg mb-4'>
                                <ChatBubbleLeftIcon className='border border-black text-black bg-white rounded-md w-6 h-6 px-0.5 py-0.5'></ChatBubbleLeftIcon>
                                {t('ExpandedMeedingCard.ChatLabel')}
                            </div>
                            <form onSubmit={handleQuestion} className='flex flex-col md:flex-row gap-2 items-center'>
                                <input
                                    type="text"
                                    name="question"
                                    id="question"
                                    className="block  w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                                    placeholder={t('ExpandedMeedingCard.ChatPlaceholder')}
                                >
                                </input>
                                <button className='bg-black text-white rounded-lg px-4 py-2 w-full md:w-auto'>
                                    {isAsking ? <Loader /> :
                                        <div className='flex justify-between items-center gap-3'>
                                            <div>Ask</div>
                                            <div className='flex items-center gap-1'>
                                                <div className='font-bold'>{calculateQuestionCost(meeting.duration)}</div>
                                                <CpuChipIcon className='w-5 h-5' />
                                            </div>
                                        </div>}

                                </button>
                            </form>
                            {answer && <div className='mt-8'>  {t('ExpandedMeedingCard.AnswerLabel')}: <br />{answer.response}</div>}

                        </Card>
                    </div>
                    <div className='prints hidden print:block'>
                        <Card >
                            <div className='flex gap-2 items-center text-lg mb-4'>
                                <ChatBubbleLeftIcon className='border border-black text-black bg-white rounded-md w-6 h-6 px-0.5 py-0.5' />
                                {t('TranscriptionModal.TranscriptionLabel')}
                            </div>
                            <div >
                                {transcription?.map((line, index) => {
                                    if (line?.staticPart?.length > 1) {
                                        return <div className="text-base my-2">
                                            <div className="text-base mt-0">{line.staticPart.replace("-", "s-").replace("s]")} {line.editablePart}</div>
                                        </div>
                                    }
                                })}

                            </div>
                        </Card>
                    </div>
                </>
            }
            <div className='flex justify-center mt-4 print:hidden' onClick={() => { window.print() }}>
                <button className='bg-black text-white rounded-lg px-8 py-2'>
                    <div className='flex justify-between items-center gap-3'>
                        <div>  {t('ExpandedMeedingCard.PrintLabel')}</div>
                    </div>
                </button>
            </div>
            {modal}
            {deletePopUp}
            {insufficientCredits}
        </div>
    )
}

export default ExpandedMeetingCard

import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  HomeIcon,
  BuildingLibraryIcon,
  RectangleStackIcon,
  XMarkIcon,
  MicrophoneIcon,
  CalendarIcon,
  UserGroupIcon,
  CpuChipIcon,
  ClockIcon,
  FolderIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import logo from "../../Assets/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import Plans from "./Settings/Payments/ViewPlans";
import BillingController from "./Settings/Payments/BillingController";
import UserSettings from "./Settings/UserSettings";
import SideBar from "../../Components/SideBar";
import SectionHeader from "../../Components/GeneralComponents/SectionHeader";
import { useTranslation } from "react-i18next";
import { Card, Grid, Metric, Text } from "@tremor/react";
import { getTeamUuid } from "../../utils/cookies";
import { getTeamStatistics, updateCredits } from "../../Requests/TeamRequests";
import { formatTime } from "../../utils/calcs";
import { useMyContext } from "../../Components/ContextProviders/CreditsContextProvider";
import TutorialVideoModal from "../../Components/GeneralComponents/TutorialVideoModal";

export default function Dashboard() {
  const [statistics, setStatistics] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation()
  const { credits, setCredits } = useMyContext();
  const { tutorial } = useParams()
  const [modal, setModal] = useState(null)



  useEffect(() => {
    getTeamStatistics(getTeamUuid()).then((res) => {
      setStatistics(res.data)
    }).catch((e) => {

    })
    updateCredits(getTeamUuid()).then((res) => {
      setCredits(res.data)
    }).catch((e) => {

    })
    tutorial && setModal(<TutorialVideoModal handleClose={() => { setModal(null) }}></TutorialVideoModal>)
  }, [])

  return (
    <div className='md:pl-64 pt-4 md:pt-12 w-full flex justify-center px-4 '>
      <div className='flex flex-col w-full justify-center items-center gap-4 md:px-4 '>
        <div className='bg-white max-w-7xl rounded-2xl w-full h-full '>
          <SectionHeader title={t('Dashboard.Title')} subtitle={t('Dashboard.Subtitle')} />

        </div>
        {/*<div className="w-full max-w-7xl">
          <Grid numItemsSm={1} numItemsMd={2} numItemsLg={3} className="gap-6">

            <Card key={"Meetings processed"}>
              <div className="flex gap-2 items-center">
                <FolderIcon className="w-5 h-5" /> <Text>{t('Dashboard.StatisticsMeetingCardLabel')}</Text>
              </div>
              <Metric>{statistics ? (statistics.projects ? statistics.projects : 0) : <span className="bg-gray-300 animate-pulse"></span>}</Metric>
            </Card>
            <Card key={"Time saved"}>

              <div className="flex gap-2 items-center">
                <DocumentTextIcon className="w-5 h-5" />
                <Text>{t('Dashboard.DurationMeetingCardLavel')}</Text>
              </div>
              <Metric>{statistics ? (statistics.files ? statistics.files : 0) : <span className="bg-gray-300 animate-pulse"></span>}</Metric>

            </Card>
            <Card key={"Credits"}>

              <div className="flex gap-2 items-center">
                <CpuChipIcon className="w-5 h-5" />
                <Text>{t('Dashboard.CreditsCardLabel')}</Text>
              </div>
              <Metric>{credits?.toFixed(2) || 0}</Metric>
            </Card>
          </Grid>
        </div>*/}
        <div className="w-full max-w-7xl">
          <div className="grid grids-col-1 sm:grid-cols-2 w-full gap-4 ">
            <button className="shadow-2xl " onClick={() => {
              navigate("/audio")
            }}>
              <Card className="h-full flex items-center justify-between gap-10 flex-col hover:bg-black hover:text-white">
                <p className="text-md font-light"> {t('Dashboard.ActionCardRecordTitle')}</p>
                <div className="flex gap-2">
                  <MicrophoneIcon className="w-12 h-12" />
                  <DocumentTextIcon className="w-12 h-12" />
                </div>
                <p className="text-sm font-light"> {t('Dashboard.ActionCardRecordDescription')}</p>
              </Card>
            </button>
            <button className="shadow-2xl " onClick={() => {
              navigate("/projects")
            }}>
              <Card className="h-full flex items-center justify-between gap-10 flex-col hover:bg-black hover:text-white">
                <p className="text-md font-light"> {t('Dashboard.ActionCardMeetingTitle')}</p>
                <FolderIcon className="w-12 h-12" />
                <p className="text-sm font-light"> {t('Dashboard.ActionCardMeetingDescription')}</p>
              </Card>
            </button>
            <button className="shadow-2xl " onClick={() => {
              navigate("/team")
            }}>
              <Card className="h-full flex items-center justify-between gap-10 flex-col hover:bg-black hover:text-white">
                <p className="text-md font-light"> {t('Dashboard.ActionCardTeamTitle')}</p>
                <UserGroupIcon className="w-12 h-12" />
                <p className="text-sm font-light"> {t('Dashboard.ActionCardTeamDescription')}</p>
              </Card>
            </button>
            <button className="shadow-2xl " onClick={() => {
              navigate("/pricing/plans")
            }}>
              <Card className="h-full flex items-center justify-between gap-10 flex-col hover:bg-black hover:text-white">
                <p className="text-md font-light"> {t('Dashboard.ActionCardCreditsTitle')}</p>
                <CpuChipIcon className="w-12 h-12" />
                <p className="text-sm font-light"> {t('Dashboard.ActionCardCreditsDescription')}</p>
              </Card>
            </button>
          </div>
        </div>
      </div>
      {modal}
    </div>
  );
}

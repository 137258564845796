import { Dialog, Transition } from '@headlessui/react'
import { PhotoIcon } from '@heroicons/react/24/outline'
import React, { useEffect } from 'react'
import { Fragment, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FileUploader } from "react-drag-drop-files";
import Loader from '../Loader';
import 'react-datepicker/dist/react-datepicker.css';
import cryptoJs from 'crypto-js';
import { getTeamUuid } from '../../utils/cookies';
import { newAudioAnalysis } from '../../Requests/NewAnalysisRequests';
import { createOrUpdateCodes } from '../../Requests/TextCoderRequests';
import { GithubPicker } from 'react-color';
const fileTypes = ["3ga", "8svx", "aac", "ac3", "aif", "aiff", "alac", "amr", "ape", "au", "dss", "flac", "flv", "m4a", "m4b", "m4p", "m4r", "mp3", "mpga", "ogg", "oga", "mogg", "opus", "qcp", "tta", "voc", "wav", "wma", "wv", "mp4"];

let colors_option_2 = ["#ddad9e", "#f8e0c5", "#fcd39e", "#eab976", "#dec487", "#e6dcab", "#f1efb9", "#e2f1b5", "#bae295", "#aeea84", "#bbe4ae", "#aceea4", "#8cdd85", "#85eca4", "#bdf3d7", "#87fdd1", "#6fdcb4", "#a2dcf1", "#8cd2ef", "#aad2ec", "#bedcf0", "#72b0db", "#9bbbfc", "#a4a3dd", "#a8a1e9", "#c7bdf2", "#b29fdf", "#d789fe", "#e5a3f6", "#f4bff8", "#e895b1", "#f597a4"]
let colors_option_1 = ["#f67088", "#f7736a", "#f77732", "#df8631", "#ce8f31", "#bf9631", "#b29b31", "#a59f31", "#96a331", "#85a831", "#6bac31", "#37b231", "#32b165", "#33af7e", "#34ae8d", "#35ad99", "#35aca4", "#36abad", "#37aab7", "#37a9c2", "#38a7d0", "#3aa4e3", "#5a9ef4", "#8595f4", "#a38cf4", "#bd81f4", "#d673f4", "#f05df4", "#f461dd", "#f566c8", "#f56ab4", "#f66da1"
]

function TextCodeModal({ handleClose, code, handleUpdateCode, handleAddNewCode }) {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation()
    const { project_uuid, analysis_uuid } = useParams()
    const [uploading, setUploading] = useState(false)
    const [hex, setHex] = useState(code?.color || "#f67088")

    const updateColor = (color) => {
        setHex(color.hex)
    }

    const closeModal = () => {
        handleClose()
        setOpen(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let data = {
            "name": event.target.code_name.value,
            "description": event.target.code_description.value,
            "project_id": project_uuid,

            "color": hex,
            ...code?.uuid && { "uuid": code.uuid }
        };

        if (code && code?.uuid) {
            data["excerpts"] = code.excerpts
            createOrUpdateCodes(data).then((res) => {
                handleUpdateCode(data);
                handleClose();
            });
        } else {
            createOrUpdateCodes(data).then((res) => {
                handleAddNewCode && handleAddNewCode(res.data);
                handleClose();
            }).catch((e) => {

            });
        }
    }



    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex w-full min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 h-full min-h-96">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                                >
                                    {t('Audio.AudioUpload.Title')}
                                </Dialog.Title>

                                <form className='flex w-full h-full flex-col justify-between gap-8' onSubmit={handleSubmit}>
                                    <div>
                                        <label htmlFor="code_name" className="block text-sm font-medium leading-6 text-gray-900">
                                            {t('TextCoder.Code name')}
                                        </label>
                                        <div className='flex w-full  flex-col mt-2 items-center'>
                                            <input
                                                type="text"
                                                name="code_name"
                                                id="code_name"
                                                defaultValue={code?.name}
                                                required
                                                className="block w-full  text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-black focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"

                                            >
                                            </input>
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="code_description" className="block text-sm font-medium leading-6 text-gray-900">
                                            {t('TextCoder.Code description')}
                                        </label>
                                        <div className='flex w-full  flex-col mt-2 items-center'>
                                            <textarea
                                                type="text"
                                                name="code_description"
                                                id="code_description"
                                                defaultValue={code?.description}
                                                className="block w-full  text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-black focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"

                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="code_description" className="block text-sm font-medium leading-6 text-gray-900">
                                            {t('TextCoder.Color')}
                                        </label>
                                        <div className='h-full w-full flex justify-center'>

                                            <GithubPicker width="full"
                                                triangle="hide"
                                                color={hex}
                                                onChangeComplete={updateColor}
                                                colors={colors_option_2}
                                            />
                                        </div>
                                    </div>


                                    <div className='flex flex-col sm:flex-row gap-2 w-full justify-between items-center mt-8'>
                                        <button
                                            type='button'
                                            onClick={() => { handleClose() }}
                                            className='border  text-black rounded-md px-12 py-2 w-fit'>
                                            {t('Audio.AudioUpload.Step2.BackButton')}
                                        </button>
                                        {uploading ? <button
                                            type='button'
                                            disabled
                                            className='bg-black text-white rounded-md px-12 py-2 w-fit'>
                                            <Loader></Loader>
                                        </button> : <button
                                            type='submit'
                                            className='bg-black text-white rounded-md px-12 py-2 w-fit'>
                                            {t('Audio.AudioUpload.Step2.NextButton')}
                                        </button>}
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}

export default TextCodeModal

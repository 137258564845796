import React, { useEffect, useState } from "react";
import { login } from "../../Requests/User/UserRequests";
import { useNavigate, useParams } from "react-router-dom";
import highlight_text from "../../Assets/highlight_text.png";
import logo from "../../Assets/logo.png";
import Cookies from "universal-cookie";
import ErrorModal from "../../Components/ErrorModal";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader";
import { sendAmplitudeData } from "../../utils/amplitude";
import { useMyContext } from "../../Components/ContextProviders/CreditsContextProvider";
import i18n from "../../i18n";


function Login() {
  const { language } = useParams()
  const [goToRegister, setGoToRegister] = useState(false);
  const [goToPasswordChange, setGoToPasswordChange] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setCredits } = useMyContext();

  let handleSubmit = (event) => {
    setFeedbackModal(false)
    setIsLoading(true)
    event.preventDefault();
    let response = login(event.target.email.value, event.target.password.value);


    response
      .then((res) => {
        if (res.data.token) {
          setIsLoading(false);
          const cookies = new Cookies();
          cookies.set("token", res.data.token, { path: '/' });
          cookies.set("user_id", res.data.user_id, { path: '/' });
          cookies.set("credits", parseFloat(res.data.credits), { path: '/' })
          setCredits(parseFloat(res.data.credits))
          if (res.data.team_uuid == null) {
            navigate("/onboarding")
          } else {
            cookies.set("team_uuid", res.data.team_uuid, { path: '/' })
            navigate(`/dashboard`);
          }
        }
      })
      .catch(() => {
        setIsLoading(false);
        setFeedbackModal(<ErrorModal
          title={t('LoginPage.FailModalTitle')}
          body={t('LoginPage.FailModalText')}
          continue={t('LoginPage.FailModalButton')}
        />)
      });
  };

  function redirectToRegister() {
    setGoToRegister(true);
  }
  function redirectToPasswordReset() {
    setGoToPasswordChange(true);
  }

  useEffect(() => {
    if (goToRegister) {
      navigate(`/register`);
    } else if (goToPasswordChange) {
      navigate('/request_password_change')
    }
    sendAmplitudeData({ "type": "Navigation", "value": "Login" })
  }, [goToRegister, goToPasswordChange]);


  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
      localStorage.setItem("language", language)
    }
  }, [])

  return (
    <>
      <div className="flex min-h-full bg-white pb-24 md:pb-0">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className="w-full flex justify-center">
                <img className="h-32 w-auto" src={logo} alt="Your Company" />
              </div>
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                {t('LoginPage.pageTitle')}
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form className="space-y-6 w-full" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('LoginPage.EmailField')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('LoginPage.PasswordField')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <a
                        href=""
                        className="font-medium text-black hover:text-black"
                        onClick={redirectToPasswordReset}
                      >
                        {t('LoginPage.ForgotPasswordLink')}
                      </a>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <a
                        href=""
                        className="font-medium text-black hover:text-black"
                        onClick={redirectToRegister}
                      >
                        {t('LoginPage.RegisterLink')}
                      </a>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                    >
                      {isLoading ? <Loader /> : t('LoginPage.SubmitButton')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={highlight_text}
            alt=""
          />
        </div>
      </div>
      {feedbackModal}
    </>
  );
}

export default Login;

import React, { useState, useEffect } from 'react';
import { Card, CategoryBar, Flex, Legend, Metric, Text } from '@tremor/react';

function TimeBasedParticipationCard({ duration, fragment, t, getSpeakerColorRaw, getLegendData, speakerNames }) {
    const [legendData, setLegendData] = useState([]);

    useEffect(() => {
        // Assuming getLegendData already returns an array of { label, color } objects
        // and needs to be updated to replace speaker identifiers with names.
        if (fragment.result && speakerNames) {
            const updatedLegendData = getLegendData(fragment.result).map(item => {
                const speakerName = speakerNames[item.label] ? speakerNames[item.label] : item.label;
                return { ...item, label: speakerName };
            });
            setLegendData(updatedLegendData);
        }
    }, [fragment.result, speakerNames, getLegendData]);

    return (
        <Card className='flex flex-col h-full justify-between my-1'>
            {fragment.result ?
                <>
                    <div>
                        <Flex alignItems="start">
                            <p className='font-semibold text-gray-700'>{t('ExpandedMeedingCard.SpeakerOverTimeLabel')}</p>
                        </Flex>
                    </div>
                    <div>
                        <CategoryBar className="mt-4"
                            values={
                                Object.entries(JSON.parse(fragment.result)).map(([key, value]) => {
                                    return value / duration;
                                })
                            }
                            colors={
                                Object.entries(JSON.parse(fragment.result)).map(([key]) => {
                                    return getSpeakerColorRaw(key);
                                })
                            }
                            showLabels={false}
                        />
                    </div>
                    <div className='w-full flex justify-center items-center'>
                        <Legend
                            className="mt-3"
                            categories={legendData.map(item => item.label)}
                            colors={legendData.map(item => item.color)}
                        />
                    </div>
                </>
                : <div className='min-w-full h-32 bg-gray-200 animate-pulse'></div>}
        </Card>
    );
}

export default TimeBasedParticipationCard;

import React from 'react'

function SectionHeader({ title, subtitle, actionTitle, actionFunction, breadcrubs = null }) {
    return (
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 rounded-2xl ">
            {breadcrubs &&
                <div className='flex gap-2 mb-2'>
                    {breadcrubs.map((breadcrub, index) => {
                        return <a className='text-xs text-gray-600 underline' href={breadcrub?.href}>{breadcrub?.name}  {(index != breadcrubs.length - 1) && " / "}</a>
                    })}
                </div>
            }
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div className="ml-4 mt-4">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>
                    <p className="mt-0 text-sm text-gray-500">
                        {subtitle}
                    </p>
                </div>
                {actionTitle && <div className="ml-4 mt-4 flex-shrink-0">
                    <button
                        type="button"
                        onClick={() => {
                            actionFunction && actionFunction()
                        }}
                        className="relative inline-flex items-center rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                    >
                        {actionTitle}
                    </button>
                </div>
                }
            </div>
        </div>
    )
}

export default SectionHeader

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import SectionHeader from '../../../../Components/GeneralComponents/SectionHeader';
import { CheckIcon, ClipboardIcon, CpuChipIcon } from '@heroicons/react/24/outline';
import { Card } from '@tremor/react';
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { getTeamUuid } from '../../../../utils/cookies';
import { buyCredits } from '../../../../Requests/Payments';
import { useNavigate, useParams } from 'react-router-dom';
import PurchaseSuccessModal from '../../../../Components/Billing/PurchaseSuccessModal';
import { getTeamPlan, updateCredits } from '../../../../Requests/TeamRequests';
import { useMyContext } from '../../../../Components/ContextProviders/CreditsContextProvider';
import PurchaseFailedModal from '../../../../Components/Billing/PurchaseFailedModal';
import { cancelSubscriptionRenewal, getInviteLink, getRefees, getReferalLink } from '../../../../Requests/User/UserRequests';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SuccessPopup from '../../../../Components/PopupNotifications/SuccessPopup';
import CancelPlansModal from '../../../../Components/Billing/CancelPlansModal';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function PricingPage() {
    const { credits, setCredits } = useMyContext();
    const { status, tab } = useParams()
    const { t } = useTranslation();
    const [modal, setModal] = useState(false)
    const [notification, setNotification] = useState(null)
    const [selectedTab, setSelectedTab] = useState("Plan")

    const [currency, setCurrency] = useState("€")
    const subscriptionPriceMap = {
        "€": { "tier-free": "0€", "tier-startup": "19,99€", "tier-pro": "49,99€", "tier-company": "249,99€" },
        "R$": { "tier-free": "R$ 0", "tier-startup": "R$ 109,99 ", "tier-pro": "R$ 274,99", "tier-company": "R$ 1299,99" },
        "$": { "tier-free": "$ 0", "tier-startup": "$19.99", "tier-pro": "$49.99", "tier-company": "$249.99" },
    }

    const availableCurrencies = [
        { "name": "€", "promoValue": 5.99, "value": 9.99, "tier-startup": 5.99, "tier-pro": 4.49, "tier-company": 4.99, "tier-free": 9.99 },
        { "name": "R$", "promoValue": 32.16, "value": 53.55, "tier-startup": 31.99, "tier-pro": 27.99, "tier-company": 26.49, "tier-free": 54.99 },
        { "name": "$", "promoValue": 5.99, "value": 9.99, "tier-startup": 5.99, "tier-pro": 4.49, "tier-company": 4.99, "tier-free": 9.99 }]

    const extraPerHour = {
        "€": { "tier-startup": "5,99€", "tier-pro": "4,99€", "tier-company": "4,99€", "tier-free": "9,99€" },
        "R$": { "tier-startup": "R$31,99 ", "tier-pro": "R$27,99", "tier-company": "R$26,99", "tier-free": "R$54" },
        "$": { "tier-startup": "$5,99", "tier-pro": "$4,99", "tier-company": "$4,99", "tier-free": "$9,99" },
    }

    let tiers = [
        {
            name: t('Landing.freeTier'),
            id: 'tier-free',
            href: '#',
            priceMonthly: '$0',
            description: t('Landing.tierFreeDesc'),
            features: [
                t('Landing.freeTierFeature1'),
                t('Landing.freeTierFeature2'),
                t('Landing.freeTierFeature3'),
                t('Landing.freeTierFeature4') + extraPerHour[currency]["tier-free"],
                t('Landing.teamSentimentOverTime'),
            ],
            mostPopular: false,
        },
        {
            name: t('Landing.startupTier'),
            id: 'tier-startup',
            href: '#',
            priceMonthly: '$14,99',
            description: t('Landing.tierStartupDesc'),
            features: [
                t('Landing.startupTierFeature1'),
                t('Landing.startupTierFeature2'),
                t('Landing.startupTierFeature3'),
                t('Landing.startupTierFeature4') + extraPerHour[currency]["tier-startup"],
                t('Landing.teamSentimentOverTime'),
            ],
            mostPopular: true,
        },
        {
            name: t('Landing.proTier'),
            id: 'tier-pro',
            href: '#',
            priceMonthly: '$54,99',
            description: t('Landing.tierProDesc'),
            features: [
                t('Landing.proTierFeature1'),
                t('Landing.proTierFeature2'),
                t('Landing.proTierFeature3'),
                t('Landing.proTierFeature4') + extraPerHour[currency]["tier-pro"],
                t('Landing.teamSentimentOverTime'),
            ],
            mostPopular: false,
        },
    ];


    const [sliderValue, setSliderValue] = useState([0, 1]);
    const [creditsPurchase, setCreditsPurchase] = useState(100);
    const [hourPrice, setHourPrice] = useState(9.99);
    const [hourPricePromotion, setHourPricePromotion] = useState(9.99);
    const hourMin = 60;
    const questionsLow = 10;
    const questionsHigh = 20;
    const [referralLink, setReferalLink] = useState(null)
    const [referees, setReferees] = useState(null)
    const [userPlan, setUserPlan] = useState("tier-free")
    const navigate = useNavigate()





    // Event handler to update the state variable when the slider value changes
    const handleSliderChange = (value) => {
        setSliderValue(value);
        setCreditsPurchase(100 * value[1])
    };

    const handlePurchase = (tier = null) => {
        let data = {
            "team_uuid": getTeamUuid(),
            "quantity": creditsPurchase / 100,
            "language": "pt",
            "currency": currency
        }
        if (tier) {
            data["tier"] = tier;
            if (tier == "tier-startup") {
                data["quantity"] = 1
            } else if (tier == "tier-pro") {
                data["quantity"] = 1
            } else if (tier == "tier-company") {
                data["quantity"] = 1
            }
        }
        buyCredits(data).then((res) => {
            res?.data?.url && window.open(res.data["url"], "_self");
        }).catch(() => {
            window.open("/pricing/failed", "_self");
        })

    }

    useEffect(() => {
        if (status) {
            if (status == "success") {
                setModal(<PurchaseSuccessModal handleClose={() => { setModal(null) }} />)
                updateCredits(getTeamUuid()).then((res) => {
                    setCredits(res.data)
                }).catch((e) => {

                })
            } else if (status == "failed") {
                setModal(<PurchaseFailedModal handleClose={() => { setModal(null) }} />)
            }
        }

        getTeamPlan(getTeamUuid()).then((res) => {
            setUserPlan(res.data || "tier-free")
            if (res.data == "tier-startup") {
                setHourPricePromotion(5.99)
            } else if (res.data == "tier-pro") {
                setHourPricePromotion(4.99)
            } else {
                setHourPricePromotion(9.99)
            }
        }).catch((e) => {

        })
        if (tab) {
            if (tab == "success" || tab == "failed") {
                setSelectedTab("plan");
                navigate("/pricing/plan/" + tab)
                window.location.reload(false)

            } else {
                setSelectedTab(tab)
            }
        }
    }, [])


    return (
        <div className='md:pl-64 pt-4 md:pt-12 w-full flex justify-center'>
            <div className='flex flex-col w-full justify-center items-center gap-4 px-4'>
                <div className='bg-white max-w-7xl rounded-2xl w-full '>
                    <SectionHeader title={t('Pricing.SuperTitle')} subtitle={t('Pricing.SuperDescription')} />
                </div>
                <Card className='w-flull max-w-7xl'>
                    <div className='w-full flex gap-2 text-sm py-4'>
                        <button className={'px-4 py-2 rounded-full text-xs ' + (selectedTab == "plan" ? "bg-black text-white" : "bg-gray-100")}
                            onClick={() => {
                                setSelectedTab("plan");
                                navigate("/pricing/plan")
                            }}
                        >
                            {t('Pricing.PlansTab')}
                        </button>
                        <button className={'px-4 py-2 rounded-full text-xs ' + (selectedTab == "credits" ? "bg-black text-white" : "bg-gray-100")}
                            onClick={() => {
                                setSelectedTab("credits");
                                navigate("/pricing/credits")
                            }}
                        >
                            {t('Pricing.CreditsTab')}
                        </button>
                        {/*<button className={'px-4 py-2 rounded-full text-xs ' + (selectedTab == "referal" ? "bg-black text-white" : "bg-gray-100")}
                            onClick={() => {
                                setSelectedTab("referal");
                                navigate("/pricing/referal")
                            }}
                        >
                            {t('Pricing.WinCreditsTab')}
                        </button>*/}
                    </div>

                    {/*(referralLink && selectedTab == "referal") && <div className='bg-white max-w-7xl rounded-2xl w-full  '>
                        <SectionHeader title={t('Pricing.ReferralTitle')}
                            subtitle={t('Pricing.ReferralDescription')} />
                        <div className='  text-base flex p-8 items-center flex-col md:flex-row gap-2'>
                            <div className='flex items-center gap-2 flex-grow flex-col md:flex-row'>
                                <span className='font-semibold'>{t('Pricing.RefarralLinkLabel')}
                                </span>
                                <div className=" flex-grow border-2 border-black bg-white text-black rounded-md px-4 py-2 text-sm  flex justify-center items-center gap-2">
                                    {referralLink}
                                </div>
                            </div>
                            <CopyToClipboard text={referralLink}>
                                <button className="w-full md:w-fit border-2 bg-black border-black text-white rounded-md px-4 py-2 text-sm hover:bg-white hover:text-black flex justify-center items-center gap-2"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setNotification(<SuccessPopup text="Copiado" handleClose={() => { setNotification(null) }}></SuccessPopup>)
                                    }}
                                >
                                    <ClipboardIcon className=" w-4 h-4" />{t('Pricing.CopyLink')}
                                </button>
                            </CopyToClipboard>
                        </div>
                        {referees?.length > 0 &&
                            <div className='px-8 pb-12 pt-4'>
                                <div className='font-semibold mb-2'>
                                    {t('Pricing.Invited')}
                                </div>
                                <div className='font-base text-gray-600 flex justify-between mb-2'>
                                    <div>
                                        {t('Pricing.TableEmail')}
                                    </div>
                                    <div>
                                        {t('Pricing.TableCreditsWon')}
                                    </div>
                                </div>
                                {
                                    Object.entries(referees).map(([email, amount]) => (
                                        <div key={email} className='flex justify-between my-2 border-b'>
                                            <div>{email}</div>
                                            <div className='font-bold'> {amount}</div>
                                        </div>
                                    ))}
                            </div>
                        }
                    </div>
                    */}
                    {selectedTab == "plan" &&
                        <>
                            <div className='bg-white max-w-7xl rounded-2xl w-full '>
                                <SectionHeader title={t('Landing.subscriptionTitle')} subtitle={t('Landing.subscriptionSubitle')} />
                            </div>
                            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                                <div className='flex justify-center gap-4 py-4 '>
                                    {availableCurrencies.map((currency_iterator) => {
                                        return (
                                            <button className={'w-10 aspect-square border rounded-md p-2 text-sm ' + (currency == currency_iterator.name ? "bg-black text-white" : "text-black bg-white")}
                                                onClick={() => {
                                                    setCurrency(currency_iterator.name)
                                                    setHourPricePromotion(currency_iterator[userPlan])
                                                    setHourPrice(currency_iterator.value)
                                                }}
                                            >{currency_iterator.name}</button>
                                        )
                                    })}
                                </div>
                                <div className="isolate mx-auto  grid max-w-md grid-cols-1 gap-y-8  lg:mx-0 lg:max-w-none lg:grid-cols-3 gap-8">
                                    {tiers.map((tier, tierIdx) => (
                                        <div
                                            key={tier.id}
                                            className="flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-black xl:p-10"
                                        >
                                            <div>
                                                <div className="flex items-center justify-between gap-x-4">
                                                    <h3
                                                        id={tier.id}
                                                        className={classNames(
                                                            tier.mostPopular ? 'text-black' : 'text-black',
                                                            'text-lg font-semibold leading-8'
                                                        )}
                                                    >
                                                        {tier.name}
                                                    </h3>
                                                    {tier.id == userPlan && <div className='px-4 py-2 rounded-full bg-black text-white text-sm'>{t('Landing.CurrentPlan')}</div>}
                                                </div>
                                                <p className="mt-4 text-sm leading-6 text-black">{tier.description}</p>
                                                <p className="mt-6 flex items-baseline gap-x-1">
                                                    <span className="text-4xl font-bold tracking-tight text-black">{subscriptionPriceMap[currency][tier.id]}</span>
                                                    <span className="text-sm font-semibold leading-6 text-black">{t('Landing.monthLabel')}</span>
                                                </p>
                                                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-black">
                                                    {tier.features.map((feature) => (
                                                        <li key={feature} className="flex gap-x-3">
                                                            <CheckIcon className="h-6 w-5 flex-none text-black" aria-hidden="true" />
                                                            {feature}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            {tier.id == userPlan ? <button
                                                href={tier.href}
                                                aria-describedby={tier.id}
                                                type='button'
                                                className='bg-gray-200 mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black'
                                                disabled
                                            >
                                                {t('Landing.CurrentPlan')}
                                            </button>
                                                :
                                                <button
                                                    href={tier.href}
                                                    aria-describedby={tier.id}
                                                    type='button'
                                                    onClick={() => { handlePurchase(tier.id) }}
                                                    className={classNames(
                                                        tier.mostPopular
                                                            ? 'bg-black text-white shadow-sm hover:bg-whit border border-blacke'
                                                            : 'text-black ring-1 ring-inset ring-black hover:ring-black',
                                                        'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black'
                                                    )}
                                                >
                                                    {t('Landing.CTA')}
                                                </button>}
                                            {(tier.id == userPlan && userPlan != "tier-free") &&
                                                <button className='mt-4 text-xs text-red-800 underline'
                                                    onClick={() => {
                                                        setModal(<CancelPlansModal handleClose={() => { setModal(null) }} />)
                                                    }}
                                                >{t('Landing.CancelPlan')}</button>
                                            }
                                        </div>
                                    ))}

                                </div>

                            </div>
                        </>

                    }

                    {selectedTab == "credits" &&
                        <>
                            <div className='bg-white max-w-7xl rounded-2xl w-full '>
                                <SectionHeader title={t('Pricing.Title')} subtitle={t('Pricing.Subtitle')} />
                            </div>
                            <div className="mx-auto mt-2 max-w-2xl rounded-3xl  lg:mx-0 lg:flex lg:max-w-none">
                                <div className="px-4 flex gap-8 w-full">
                                    <div className=' bg-white text-black rounded-xl w-full border border-black'>
                                        <div className='flex justify-center gap-4 py-4 '>
                                            {availableCurrencies.map((currency_iterator) => {
                                                return (
                                                    <button className={'w-10 aspect-square border rounded-md p-2 text-sm ' + (currency == currency_iterator.name ? "bg-black text-white" : "text-black bg-white")}
                                                        onClick={() => {
                                                            setCurrency(currency_iterator.name)
                                                            setHourPricePromotion(currency_iterator[userPlan])
                                                            setHourPrice(currency_iterator.value)
                                                        }}
                                                    >{currency_iterator.name}</button>
                                                )
                                            })}
                                        </div>
                                        <div className='flex flex-col py-4 '>
                                            <div className='flex flex-col items-center mb-8'>
                                                <div className=" flex  text-lg gap-2 items-end mb-8 justify-center w-full">
                                                    <div className='text-5xl'> {creditsPurchase} </div>

                                                    <CpuChipIcon className='text-black w-6 h-6 mr-2 mb-1' />

                                                    <div className='flex items-center'>
                                                        {t('Pricing.ForLabel')}
                                                        <div className='text-xl ml-4 flex flex-col'>
                                                            {/* <span className='text-end text-sm line-through'>{(creditsPurchase / 100 * hourPrice).toFixed(2)} {" " + currency}</span>*/}
                                                            <span className='text-end text-xl text-green-700 font-bold'>{(creditsPurchase / 100 * hourPricePromotion).toFixed(2)}{" " + currency}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex flex-col'>
                                                    <div className='px-8 text-sm text-justify'><span className="text-2xl font-bold">{(creditsPurchase / 100 * hourMin).toFixed(0)}</span> {t('Pricing.MinsLabel')}</div>
                                                    <div className='px-8 text-sm w-full py-4'>{t('Pricing.orLabel')}</div>
                                                    <div className='px-8 text-sm flex flex-col text-justify'><div className="font-bold text-2xl ">{(creditsPurchase / 100 * questionsLow).toFixed(0)} {t('Pricing.quesitions1')} {(creditsPurchase / 100 * questionsHigh).toFixed(0)}
                                                        {t('Pricing.quesitions2')}</div>
                                                        <div> {t('Pricing.quesitions3')}</div>
                                                    </div>
                                                </div>
                                                <div className='w-1/2'>
                                                    <RangeSlider
                                                        className="single-thumb"
                                                        defaultValue={[1, 1]}
                                                        max={20} step={1} min={1}
                                                        thumbsDisabled={[true, false]}
                                                        rangeSlideDisabled={true}
                                                        onInput={handleSliderChange}
                                                    />

                                                </div>
                                            </div>

                                            <button
                                                onClick={(event) => { event.preventDefault(); handlePurchase(null) }}
                                                className='bg-white rounded-md mx-4 py-2 text-black border border-black'>{t('Pricing.buttonLabel')}</button>
                                        </div>
                                        <div className='text-sm text-center mb-4'>
                                            {t('Help.SupportEmail')}
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </>

                    }


                    <div className=' underline mt-4 text-center w-full text-sm'>
                        <a
                            href={'mailto:support@meetpulp.com'}
                        >{t('PurchaseSuccessModal.Help')}</a>
                    </div>
                </Card>

            </div>

            {modal}
            {notification}

        </div>
    )
}

export default PricingPage

import { Dialog, Transition } from '@headlessui/react'
import React, { useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../Loader';
import 'react-datepicker/dist/react-datepicker.css';
import { transcriptionAnalysis } from '../../Requests/NewAnalysisRequests';
import { getTeamUuid } from '../../utils/cookies';

function TextTranscriptionUploadModal({ handleClose, projects = null }) {
    const navigate = useNavigate();
    const { project_uuid } = useParams();
    const [open, setOpen] = useState(true);
    const [step, setStep] = useState(1);
    const [name, setName] = useState('');
    const [language, setLanguage] = useState("pt");
    const [text, setText] = useState('');
    const [editedText, setEditedText] = useState('');
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [project, setProject] = useState(project_uuid ? project_uuid : projects ? projects[0].uuid : null)

    const closeModal = () => {
        handleClose();
        setOpen(false);
    };

    const handleInputChange = (e) => {
        if (e.target.name === 'name') {
            setName(e.target.value);
        } else if (e.target.name === 'text') {
            setText(e.target.value);
        } else if (e.target.name === 'editedText') {
            setEditedText(e.target.value);
        }
    };

    const handleSelectChange = (event) => {
        setLanguage(event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (step === 1) {
            setStep(2);
        } else if (step === 2) {
            const lines = text.split('\n');
            let parsedText = "";
            let currentSpeaker = null;
            let expectDialogueNext = false; // Flag to indicate if the next line should be dialogue for the current speaker

            lines.forEach((line, index) => {
                const trimmedLine = line.trim();
                // Check for speaker names followed by a colon or dash
                const speakerPattern = /^(.*?)([:\-])(.*)$/;
                let match = trimmedLine.match(speakerPattern);

                if (match) {
                    // Found a speaker and dialogue on the same line
                    currentSpeaker = match[1].trim();
                    const dialogue = match[3].trim();
                    parsedText += `${currentSpeaker}: ${dialogue}\n`;
                    expectDialogueNext = false; // Reset the flag as we've handled dialogue on the same line
                } else {
                    if (trimmedLine && expectDialogueNext) {
                        // Current line is dialogue for the last identified speaker
                        parsedText += `${currentSpeaker}: ${trimmedLine}\n`;
                        expectDialogueNext = false; // Reset the flag after attaching dialogue
                    } else if (trimmedLine) {
                        // Possible speaker name on a standalone line
                        currentSpeaker = trimmedLine;
                        expectDialogueNext = true; // Expecting dialogue on the next line
                    } else {
                        // Empty line signifies possible end of this speaker's dialogue
                        parsedText += `\n`;
                        expectDialogueNext = false; // Reset the flag as there's no immediate dialogue expected
                    }
                }
            });

            setEditedText(parsedText.trim()); // Trim any trailing newlines
            setStep(3);
        } else if (step === 3) {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('project_uuid', project ? project : project_uuid);
            if (language) { formData.append('language', language) };
            formData.append('team_uuid', getTeamUuid());
            formData.append('edited_transcription', editedText);
            // Extract unique speaker names from the edited text
            const uniqueNames = new Set();
            editedText.split('\n\n').forEach(block => {
                const lines = block.split(':');
                if (lines.length > 0) {
                    uniqueNames.add(lines[0].trim().replace());
                }
            });
            formData.append('speakers', Array.from(uniqueNames).join(', '));
            transcriptionAnalysis(formData).then((res) => {
                if (project_uuid) { window.location.reload(false) } else {
                    navigate("/project/" + project)
                }
            }).catch((e) => {

            })
        }
    };



    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex w-full min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-5xl sm:p-6">
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                                {t('TranscriptionUploadModal.Upload a transcription')}
                            </Dialog.Title>
                            {step === 1 && (
                                <form className='flex flex-col gap-4' onSubmit={handleSubmit}>
                                    <div>
                                        <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                                            {t('TranscriptionUploadModal.Name')}
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={name}
                                            onChange={handleInputChange}
                                            className="block w-full  text-start rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-black focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                                            {t('TranscriptionUploadModal.Language')}
                                        </label>
                                        <select
                                            id="location"
                                            name="location"
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-black sm:text-sm sm:leading-6"
                                            defaultValue={t('Audio.AudioUpload.Step2.LanguageOption1')}
                                            onChange={handleSelectChange}
                                        >
                                            <option>  {t('Audio.AudioUpload.Step2.LanguageOption1')}</option>
                                            <option>  {t('Audio.AudioUpload.Step2.LanguageOption2')}</option>
                                            <option>  {t('Audio.AudioUpload.Step2.LanguageOption3')}</option>
                                        </select>
                                    </div>
                                    {projects && <div>
                                        <label htmlFor="projects" className="block text-sm font-medium leading-6 text-gray-900">
                                            {t('Audio.AudioUpload.Step2.ProjectLabel')}
                                        </label>
                                        < select
                                            value={project}
                                            onChange={(e) => setProject(e.target.value)}
                                            className="text-black rounded px-4 py-1 text-sm w-full"
                                        >
                                            {projects && (projects?.map((project) => {
                                                return <option key={project.uuid} value={project.uuid}>{project.name}</option>
                                            }))
                                            }
                                        </select>
                                    </div>}
                                    <div className='flex flex-col sm:flex-row gap-2 w-full justify-between items-center mt-8'>
                                        <button
                                            type='button'
                                            onClick={() => { handleClose() }}
                                            className='border  text-black rounded-md px-12 py-2 w-fit'>
                                            {t('Audio.AudioUpload.Step2.BackButton')}
                                        </button>
                                        <button
                                            type='submit'
                                            className='bg-black text-white rounded-md px-12 py-2 w-fit'>
                                            {t('Audio.AudioUpload.Step2.NextButton')}
                                        </button>
                                    </div>
                                </form>
                            )}
                            {step === 2 && (
                                <form className='flex flex-col py-4' onSubmit={handleSubmit}>
                                    <div className='text-sm mb-4'>
                                        {t('TranscriptionUploadModal.Make sure your transcription is formated like one of the following')}:
                                        <div className='flex flex-col gap-2 mt-2'>
                                            <div className='bg-gray-200 rounded-md px-4 py-2 text-sm'>
                                                {t('TranscriptionUploadModal.Speaker one name')} : {t('TranscriptionUploadModal.Hi')}
                                                <br /><br />
                                                {t('TranscriptionUploadModal.Speaker two name')} : {t('TranscriptionUploadModal.Hello! How are you')}
                                            </div>
                                            <div className='bg-gray-200 rounded-md px-4 py-2 text-sm'>
                                                {t('TranscriptionUploadModal.Speaker one name')} - {t('TranscriptionUploadModal.Hi')}
                                                <br /><br />
                                                {t('TranscriptionUploadModal.Speaker two name')} - {t('TranscriptionUploadModal.Hello! How are you')}
                                            </div>
                                            <div className='bg-gray-200 rounded-md px-4 py-2 text-sm'>
                                                {t('TranscriptionUploadModal.Speaker one name')}
                                                <br />
                                                {t('TranscriptionUploadModal.Hi')}
                                                <br /><br />
                                                {t('TranscriptionUploadModal.Speaker two name')}
                                                <br />{t('TranscriptionUploadModal.Hello! How are you')}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                                            {t('TranscriptionUploadModal.Paste transcription')}:
                                        </label>
                                        <textarea
                                            name="text"

                                            value={text}
                                            onChange={handleInputChange}
                                            placeholder={t('TranscriptionUploadModal.Speaker one name') + " : " + t('TranscriptionUploadModal.Hi') + "\n" + t('TranscriptionUploadModal.Speaker two name') + " : " + t('TranscriptionUploadModal.Hello! How are you')}
                                            className="block w-full  text-start rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-black focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            required
                                            rows={15}
                                        />
                                    </div>
                                    <div className='flex flex-col sm:flex-row gap-2 w-full justify-between items-center mt-8'>
                                        <button
                                            type='button'
                                            onClick={() => { if (step == 1) { handleClose() } else { setStep(step - 1) } }}
                                            className='border  text-black rounded-md px-12 py-2 w-fit'>
                                            {t('Audio.AudioUpload.Step2.BackButton')}
                                        </button>
                                        <button
                                            type='submit'
                                            className='bg-black text-white rounded-md px-12 py-2 w-fit'>
                                            {t('Audio.AudioUpload.Step2.NextButton')}
                                        </button>
                                    </div>
                                </form>
                            )}
                            {step === 3 && (
                                <form className='flex flex-col' onSubmit={handleSubmit}>
                                    <div>
                                        <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                                            {t('TranscriptionUploadModal.Review your transcription')}
                                        </label>
                                        <textarea
                                            name="editedText"
                                            className="block w-full  text-start rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-black focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            value={editedText}
                                            onChange={handleInputChange}
                                            rows={15}
                                            disabled
                                            required
                                        />
                                    </div>
                                    <div className='flex flex-col sm:flex-row gap-2 w-full justify-between items-center mt-8'>
                                        <button
                                            type='button'
                                            onClick={() => { setStep(step - 1) }}
                                            className='border  text-black rounded-md px-12 py-2 w-fit'>
                                            {t('Audio.AudioUpload.Step2.BackButton')}
                                        </button>
                                        <button
                                            type='submit'
                                            className='bg-black text-white rounded-md px-12 py-2 w-fit'>
                                            {t('TranscriptionUploadModal.Upload')}
                                        </button>
                                    </div>
                                </form>
                            )}
                            {loading && <Loader />}
                        </Dialog.Panel>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default TextTranscriptionUploadModal;

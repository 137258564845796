import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

import purchaseSuccessIcon from "../../Assets/Purchase/success.png"
import { useTranslation } from "react-i18next";
import { onboardTeamMember } from "../../Requests/TeamRequests";
import { getTeamUuid } from "../../utils/cookies";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function PurchaseSuccessModal({ handleClose }) {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation()
    const [step, setStep] = useState(0)
    const [inviteLink, setInviteLink] = useState(null)
    const closeModal = () => {
        handleClose()
        setOpen(false)
    }

    const createTeamMember = (event) => {
        event.preventDefault()
        let data = { "email": event.target.email.value, "team_uuid": getTeamUuid() }

        onboardTeamMember(data).then((res) => {
            setInviteLink(res.data.token)
            setStep(1)
        }).catch((e) => {

        })
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                                >
                                    {t('PurchaseSuccessModal.Title')}
                                </Dialog.Title>
                                <div className="py-4">
                                    <div className="mx-auto flex h-64 w-64 items-center justify-center rounded-full ">
                                        <img src={purchaseSuccessIcon} />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">

                                        <div className="mt-2">
                                            <p className="text-sm text-black font-bold">
                                                {t('PurchaseSuccessModal.Description')}
                                            </p>
                                        </div>
                                        <div className="text-center text-xs mt-4">{t('PurchaseSuccessModal.Help')}</div>
                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    );
}

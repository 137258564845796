import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import teamIcon from "../../Assets/Teams/team.png"
import { useTranslation } from "react-i18next";
import { onboardTeamMember } from "../../Requests/TeamRequests";
import { getTeamUuid } from "../../utils/cookies";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ClipboardIcon, CloudArrowUpIcon } from "@heroicons/react/24/outline";
import Loader from "../Loader";
import { deleteAudio, deleteMeeting, deleteTranscript, updateMeetingRequest, updateMeetingVisibilityRequest } from "../../Requests/MeetingRequests";
import SuccessPopup from "../PopupNotifications/SuccessPopup";

export default function MeetingPermissions({ meeting, handleClose, updateMeeting }) {
    const [open, setOpen] = useState(true);
    const { t } = useTranslation()
    const [meetingLink, setMeetingLink] = useState(meeting.public_uuid ? (meeting.public_uuid) : null)

    const [saving, setSaving] = useState(false)

    const closeModal = () => {
        handleClose()
        setOpen(false)
    }

    const [selectedLocation, setSelectedLocation] = useState(meeting.visibility || "private");

    // Step 2: Handle the onChange event for the <select>
    const handleLocationChange = (event) => {
        setSelectedLocation(event.target.value);
    };

    // Step 3: Create a handleSubmit function
    const handleSubmit = (event) => {
        setSaving(true)
        event.preventDefault();
        let data = { "uuid": meeting.uuid, "visibility": selectedLocation }
        updateMeetingVisibilityRequest(data).then((res) => {
            if (selectedLocation == "public") {

                setMeetingLink(res.data)
            } else {
                closeModal()
            }
            updateMeeting()
            setSaving(false)
        }).catch(() => {
            setSaving(false)
        })
    };


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full w-full md:max-w-2xl sm:p-6">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                                >
                                    {t('MeetingPermissions.Title')}
                                </Dialog.Title>
                                <div className="py-4">
                                    <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                                        {t('MeetingPermissions.Description')}
                                    </label>
                                    <form onSubmit={handleSubmit}>
                                        <div className="flex flex-col gap-4 items-center justify-center ">
                                            <select
                                                id="location"
                                                name="location"
                                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-black sm:text-sm sm:leading-6"
                                                value={selectedLocation}
                                                onChange={handleLocationChange}
                                            >
                                                <option value="private">{t('MeetingPermissions.private')}</option>
                                                <option value="public">{t('MeetingPermissions.public')}</option>
                                            </select>
                                            {meetingLink &&
                                                <div>
                                                    <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('MeetingPermissions.LinkLabel')}
                                                    </label>
                                                    <div className="flex gap-2 items-center">
                                                        <div className="overflow-scroll text-sm">{process.env.REACT_APP_BASE_URL + "/meeting/public/" + meetingLink} </div>
                                                        <CopyToClipboard text={process.env.REACT_APP_BASE_URL + "/meeting/public/" + meetingLink}>
                                                            <button
                                                                type="button"
                                                                className="border-2 border-black bg-white text-black rounded-md px-4 py-2 text-sm hover:bg-black hover:text-white flex justify-center items-center gap-2">
                                                                <ClipboardIcon className=" w-4 h-4" />
                                                                {t('MeetingPermissions.CopyLabel')}
                                                            </button>
                                                        </CopyToClipboard>
                                                    </div>
                                                </div>
                                            }
                                            <div className='flex flex-col sm:flex-row gap-2 w-full justify-end items-center mt-2'>
                                                <button
                                                    type='button'
                                                    onClick={() => { !saving && handleClose() }}
                                                    className='border  text-black rounded-md px-12 py-2 w-fit'>
                                                    {t('MeetingPermissions.CancelLabel')}
                                                </button>
                                                {saving ? <button
                                                    type='button'
                                                    disabled
                                                    className='bg-black text-white rounded-md px-12 py-2 w-fit'>
                                                    <Loader></Loader>
                                                </button> : <button
                                                    type='submit'
                                                    className='bg-black text-white rounded-md px-12 py-2 w-fit'>
                                                    {t('MeetingPermissions.SubmitLabel')}
                                                </button>}
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    );
}

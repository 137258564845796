import React, { useEffect, useRef, useState } from 'react'
import { useMyContext } from '../ContextProviders/CreditsContextProvider';
import Loader from '../Loader';
import { CalculatorIcon, ChatBubbleLeftIcon, CpuChipIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Card, MultiSelect, MultiSelectItem } from '@tremor/react';
import { calculateQuestionCost, calculateQuestionCostByToken, formatDateTime } from '../../utils/calcs';
import NoCreditsModal from '../Billing/NoCreditsModal';
import { askGpt, getGptAnswer } from '../../Requests/ProjectGptRequests';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getTeamUuid } from '../../utils/cookies';
import { requestAiCodeFinding } from '../../Requests/AICodeFinder';
import { SparklesIcon } from '@heroicons/react/20/solid';

function AICodeFinder({ project, isAsking, setIsAsking, requestCodeFind }) {
    const { project_uuid } = useParams()
    const [sizeWarning, setSizeWarning] = useState(null)
    const [cost, setCost] = useState(null)
    const { t } = useTranslation()
    const [aiCodeFinders, setAiCodeFinders] = useState(null)

    const [values, setValues] = useState(null)

    useEffect(() => {
        setValues(project?.analysis
            ?.filter(current_analysis => (current_analysis.status === "Processed"))
            .map(current_analysis => current_analysis.uuid))
        setAiCodeFinders(project?.gpt_aiCodeFinders)
    }, [project])

    const [insufficientCredits, setInsufficientCredits] = useState(null)


    const handleAskQuestion = (event) => {
        event.preventDefault()
        if (values.length != 0) {
            let data = { "project_uuid": project_uuid, "analysis_uuids": values.join(), "team_uuid": getTeamUuid() }
            requestCodeFind(data, cost)
        }

    }

    useEffect(() => {
        let total_duration = 0;
        let token_price = 0
        let size = 0
        project?.analysis?.map((current_analysis) => {
            if (values?.includes(current_analysis.uuid)) {
                if (current_analysis?.tokens) {
                    token_price += parseFloat(calculateQuestionCostByToken(current_analysis.tokens))
                    size += current_analysis.tokens * 2
                } else {
                    total_duration += parseFloat(current_analysis.duration) || 0;
                    size += parseFloat(7500 * current_analysis.duration / 1800) * 2
                }
            }

        })

        let cost = parseFloat(calculateQuestionCost(total_duration)) + parseFloat((token_price))
        if (size > 100000) {
            cost *= parseFloat(Math.ceil(size / 100000))
        }
        setCost(cost)
    }, [values])

    const scrollContainerRef = useRef(null);



    const fetchChatUpdates = async (uuid) => {
        try {
            const updatedChat = await getGptAnswer(uuid);
            return updatedChat;
        } catch (error) {
            throw error; // Rethrow to handle in the caller
        }
    };



    const updateaiCodeFinderstatus = (uuid) => {
        fetchChatUpdates(uuid).then(updatedChat => {
            if (updatedChat.data?.status === "Processing") {
                setTimeout(() => updateaiCodeFinderstatus(uuid), 3000);
            } else if (updatedChat.data?.status === "Processed") {
                setAiCodeFinders(currentaiCodeFinders => {
                    return currentaiCodeFinders.map(chat => {
                        setIsAsking(false)
                        if (chat.uuid === uuid) {
                            return updatedChat.data;
                        }
                        return chat;
                    });
                });
            } else {
                // In any other case, set the result to "Failed to process the question"
                setAiCodeFinders(currentaiCodeFinders => {
                    setIsAsking(false)
                    return currentaiCodeFinders.map(chat => {
                        if (chat.uuid === uuid) {
                            // Update both the result and status properties of the chat
                            return { ...chat, result: "Failed to process the question", status: "Failed to process" };

                        }
                        return chat;
                    });
                });
            }
        }).catch(error => {
            setAiCodeFinders(currentaiCodeFinders => {
                setIsAsking(false)
                return currentaiCodeFinders.map(chat => {
                    if (chat.uuid === uuid) {
                        return { ...chat, result: "Failed to process the question", status: "Failed to process" };

                    }
                    return chat;
                });
            })
        }
        )
    };

    useEffect(() => {
        aiCodeFinders?.forEach(chat => {
            if (chat.uuid && chat.status === "Processing") {
                updateaiCodeFinderstatus(chat.uuid);
            }
        });
    }, [aiCodeFinders]);


    return (
        <div className='w-full h-full flex flex-col'>

            <div>
                <div className='text-sm my-4 print:hidden'>
                    <Card>
                        <div className='flex gap-2 items-center text-lg mb-4'>
                            <SparklesIcon className='border border-black text-black bg-white rounded-md w-6 h-6 px-0.5 py-0.5' />
                            {t("AiCodeDiscovery.Title")}
                            <span className='bg-black rounded-full text-white px-4 py-0.5 text-sm animate-pulse'>Beta</span>
                        </div>
                        <form
                            onSubmit={handleAskQuestion}
                            className='flex flex-col w-full gap-2 items-center'>
                            <div className='w-full'>
                                <p className='ml-1 mb-1 font-bold w-full text-start'>
                                    {t("AiCodeDiscovery.Description")}
                                </p>

                            </div>
                            <div className='flex flex-col md:flex-row w-full gap-2'>
                                <MultiSelect onValueChange={setValues} value={values || []} className="z-50 w-full" placeholder={t('Analysis.NewAnalysisModalMeetingsPlaceholder')}>
                                    {project?.analysis?.filter(current_analysis => (current_analysis.status === "Processed" && (current_analysis.file_type == "audio" || current_analysis.file_type == "manual_transcription"))).map((current_analysis) => {
                                        return (<MultiSelectItem key={current_analysis.uuid} value={current_analysis.uuid} icon={CalculatorIcon}>
                                            {current_analysis.name}
                                        </MultiSelectItem>)
                                    })}
                                </MultiSelect>
                                <button className={'grow whitespace-nowrap rounded-lg px-4 py-2 w-full md:w-auto ' + (!isAsking ? "bg-black text-white" : "bg-gray-400 text-white")} disabled={isAsking}
                                >
                                    {isAsking ? <Loader /> :

                                        <div className='flex justify-between items-center gap-3'>
                                            <div>
                                                {t("AiCodeDiscovery.ButtonLabel")}
                                            </div>
                                            <div className='flex items-center gap-1'>
                                                <div className='font-bold'>{cost?.toFixed(2)}</div>
                                                <CpuChipIcon className='w-5 h-5' />
                                            </div>
                                        </div>
                                    }

                                </button>
                            </div>


                        </form>
                        {sizeWarning}
                    </Card>
                </div>

            </div>
            {insufficientCredits}
        </div>
    )
}

export default AICodeFinder

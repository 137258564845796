import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import teamIcon from "../../Assets/Teams/team.png"
import { useTranslation } from "react-i18next";
import { onboardTeamMember } from "../../Requests/TeamRequests";
import { getTeamUuid } from "../../utils/cookies";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import Loader from "../Loader";

export default function IdentifyUsersModal({ handleClose, speakerNames, handleSaveNames }) {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation()
    const [saving, setSaving] = useState(false)
    const [currentNames, setCurrentNAmes] = useState(speakerNames)
    const closeModal = () => {
        handleClose()
        setOpen(false)
    }


    const handleInputChange = (key, newValue) => {
        setCurrentNAmes({
            ...currentNames,
            [key]: newValue,
        });
    };


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full w-full md:max-w-lg sm:p-6">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                                >
                                    {t('UpdateNamesModal.Title')}
                                </Dialog.Title>
                                <div className="flex flex-col gap-2 mt-2">
                                    {Object.entries(currentNames).map(([key, value]) => (
                                        <div key={key} className="w-full flex flex-col md:flex-row items-start md:items-center gap-2">
                                            <label className=" whitespace-nowrap">
                                                {key}:
                                            </label>
                                            <input
                                                type="text"
                                                defaultValue={value}
                                                onChange={(e) => handleInputChange(key, e.target.value)}
                                                className="flex md:ml-1 border rounded w-full h-fit text-sm"
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div className="w-full flex justify-end mt-2">
                                    <button className='text-sm border-2 border-black bg-black text-white rounded-xl h-fit px-4 py-2 shadow-lg '
                                        disabled={saving}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setSaving(true)
                                            handleSaveNames(currentNames)
                                        }}
                                    >
                                        <div className='flex justify-between gap-2 items-center'>
                                            {saving ? <Loader className="w-6 h-6" /> :
                                                <>
                                                    <CloudArrowUpIcon className=' w-4 h-4' />
                                                    {t('UpdateNamesModal.SaveButton')}
                                                </>
                                            }
                                        </div>
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    );
}

import { Card } from '@tremor/react'
import React, { useEffect, useState } from 'react'
import SectionHeader from '../../../../Components/GeneralComponents/SectionHeader'
import { useTranslation } from 'react-i18next'
import { getReferalLink } from '../../../../Requests/User/UserRequests'
import SuccessPopup from '../../../../Components/PopupNotifications/SuccessPopup'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ClipboardIcon } from '@heroicons/react/24/outline'
import UpgradedLoader from '../../../../Components/UpgradedLoader'

function RewardsPage() {
    const { t } = useTranslation()
    const [referralLink, setReferralLink] = useState()
    const [referalCount, setReferalCount] = useState()
    const [notification, setNotification] = useState(null)
    useEffect(() => {
        getReferalLink().then((res) => {
            setReferralLink(process.env.REACT_APP_BASE_URL + "/register/" + (t('Language') || "en") + "/" + res.data.referral)
            setReferalCount(res.data.referrals_count)
        }).catch((e) => {

        })
    }, [])
    return (
        <div>

            <div className='md:pl-64 pt-4 md:pt-12 w-full flex justify-center'>
                <div className='flex flex-col w-full justify-center items-center px-4 gap-4'>
                    <div className='bg-white max-w-7xl rounded-2xl w-full '>
                        <SectionHeader title={t('RewardsPage.title')} subtitle={t('RewardsPage.subtitle')} />
                    </div>
                    <Card className='w-full max-w-7xl'>
                        {referralLink ?
                            <div className='w-full flex gap-2 text-sm py-2 flex-col'>
                                <div className='flex flex-col gap-2'>
                                    <div className='text-lg font-bold'>{t('RewardsPage.referrals')}</div>
                                    <div className='text-sm'>{t('RewardsPage.inviteColleagues')}</div>
                                    <div className='  text-base flex py-4 items-center flex-col md:flex-row gap-2'>
                                        <div className='flex items-center gap-4 flex-grow flex-col md:flex-row text-start '>
                                            <span className='font-semibold text-sm whitespace-nowrap text-start w-full lg:w-fit shrink'>{t('Pricing.RefarralLinkLabel')}
                                            </span>
                                            <div className="w-full shrink border-2 border-black bg-white text-black rounded-md px-3 py-1 text-sm  flex justify-center items-center gap-2">
                                                {referralLink}
                                            </div>
                                        </div>
                                        <CopyToClipboard text={referralLink}>
                                            <button className="w-full whitespace-nowrap md:w-fit border-2 bg-black border-black text-white rounded-md px-3 py-1 text-sm hover:bg-white hover:text-black flex justify-center items-center gap-2"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setNotification(<SuccessPopup text="Copiado" handleClose={() => { setNotification(null) }}></SuccessPopup>)
                                                }}
                                            >
                                                <ClipboardIcon className=" w-4 h-4" />{t('Pricing.CopyLink')}
                                            </button>
                                        </CopyToClipboard>
                                    </div>
                                    {[2, 5, 10].map((count, index) => (
                                        <div key={index} className='flex flex-col lg:flex-row justify-between w-full items-center mt-8 gap-2'>
                                            <div className='flex gap-2 flex-col mt-2 w-full'>
                                                <div className='font-bold'>{t('RewardsPage.invitePeople', { count })}</div>
                                                {(count - referalCount) > 0 ? <div className='text-xs'>
                                                    {t('RewardsPage.inviteMorePeople', { count: count - referalCount, plural: count - referalCount > 1 ? 's' : '' })}
                                                </div> : <div className='text-sm'>{t('RewardsPage.AfterCreditsAddedMessage')}</div>}
                                            </div>
                                            {(count - referalCount) > 0 ?
                                                <div className='flex flex-col-reverse lg:flex-row gap-2 lg:gap-4 items-center mt-4 lg:mt-0 w-full justify-end'>
                                                    <div className='flex flex-col'>
                                                        <div className='text-xs lg:text-sm'>
                                                            {t('RewardsPage.invitesAccepted', { count: referalCount, total: count })}
                                                        </div>
                                                    </div>
                                                    <div className='text-sm px-4 py-2 bg-black text-white rounded-lg w-full lg:w-fit whitespace-nowrap '>
                                                        {t('RewardsPage.reward', { credits: count * 50 })}
                                                    </div>
                                                </div> :
                                                <div className='text-sm px-4 py-2 border-2 border-black text-black rounded-lg w-full lg:w-fit whitespace-nowrap '>
                                                    {t('RewardsPage.creditsAddedToAccount', { credits: count * 50 })}
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div> : <UpgradedLoader bg="bg-black-600" />}
                    </Card>
                    <Card className='w-full max-w-7xl'>
                        {referralLink ?
                            <div className='w-full flex gap-2 text-sm py-2 flex-col'>
                                <div className='flex flex-col gap-2'>
                                    <div className='text-lg font-bold'>{t('RewardsPage.publicationsAndTestimonials')}</div>
                                    <div className='text-sm'>{t('RewardsPage.referencedMeetpulp')}</div>
                                    <div className='flex flex-col lg:flex-row justify-between w-full items-center mt-8 gap-2'>
                                        <div className='flex flex-col items-start gap-2 lg:gap-4 mt-4 lg:mt-0 w-full'>
                                            <div>{t('RewardsPage.publicationAndTestimonial')}</div>
                                            <div className='text-xs'>
                                                {t('RewardsPage.toApplySendEmail')} <a className='underline' href={`mailto:${t('RewardsPage.supportEmail')}`}>{t('RewardsPage.supportEmail')}</a>
                                            </div>
                                        </div>
                                        <div className='flex gap-4 items-center w-full justify-end'>
                                            <div className='text-sm px-4 py-2 bg-black text-white rounded-lg w-full lg:w-fit'>
                                                {t('RewardsPage.reward', { credits: 100 })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : <UpgradedLoader bg="bg-black-600" />}
                    </Card>
                </div>
            </div>
            {notification}
        </div>
    )
}

export default RewardsPage

import React from 'react'
import TextCodeModal from '../../../../Components/TextCoder/TextCodeModal'
import { EyeIcon, EyeSlashIcon, ListBulletIcon, PencilIcon } from '@heroicons/react/24/outline'
import TextCodeExcerptsModal from '../../../../Components/TextCoder/TextCodeExcerptsModal';
import { useTranslation } from 'react-i18next';

function TextCodes({ textCodes, setTextCodes, handleSaveSelection, createCodeModal, setCreateCodeModal, pageActiveSelections, setPageActiveSelections, isEditingTextCodes, setIsEditingTextCodes, occurrencesByName, setViewCodesModal }) {
    const { t } = useTranslation()
    function handleUpdateCode(updatedCode) {
        setTextCodes(prevCodes => prevCodes.map(code => code.uuid === updatedCode.uuid ? updatedCode : code));
    }
    function handleAddNewCode(newCode) {
        setTextCodes(prevCodes => [...prevCodes, newCode]);
        setPageActiveSelections(prevSelections => [...prevSelections, newCode.uuid]);
    }

    function toggleActiveSelection(uuid) {
        if (pageActiveSelections.includes(uuid)) {
            setPageActiveSelections(prevSelections => prevSelections.filter(selection => selection !== uuid));
        } else {
            setPageActiveSelections(prevSelections => [...prevSelections, uuid]);
        }
    }

    function isActive(uuid) {
        return pageActiveSelections.includes(uuid);
    }





    return (
        <div className='no-select'>
            <div className=' w-full h-full  flex flex-col justify-between'>
                <div className='flex flex-col h-screen'>
                    <div className='bg-black text-white w-full p-4  text-center flex flex-col items-end justify-center rounded-lg mt-4'>
                        <button className='w-full justify-end items-center text-xs flex gap-2 z-30  '
                            type='button'
                            onClick={() => { setIsEditingTextCodes(!isEditingTextCodes) }}
                        >
                            <svg className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor" aria-hidden="true">
                                <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                            </svg>

                        </button>
                        <div className='flex-grow -mt-4 w-full'>{t('TextCoder.Codes')}</div>
                    </div>
                    <div className='flex flex-col gap-2 w-64  overflow-y-scroll my-4 flex-grow'>
                        {textCodes && textCodes.map((textCode) => {
                            return (
                                <div className='flex gap-2 items-center' key={textCode.uuid}>
                                    <button
                                        onClick={() => handleSaveSelection(textCode.color, textCode.uuid)}
                                        style={{ backgroundColor: textCode.color }}
                                        className='w-full text-start p-2 rounded-xl border-2  truncate shadow-xl flex items-center gap-2 justify-between'>
                                        <span className='truncate'>{textCode.name}</span>{occurrencesByName[textCode.uuid] ?
                                            <div className='rounded-full w-7 h-7 bg-white text-black p-2 flex justify-center items-center text-xs border-2 border-black font-bold'>
                                                {occurrencesByName[textCode.uuid]}
                                            </div>
                                            :
                                            <div className='w-7 h-7'></div>
                                        }
                                    </button>
                                    <button
                                        onClick={() => {
                                            toggleActiveSelection(textCode.uuid);
                                        }}
                                    >
                                        {isActive(textCode.uuid) ?
                                            <EyeIcon className={`bg-black w-10 h-10 text-start p-2 rounded-xl text-white border border-black`} /> :
                                            <EyeSlashIcon className={`bg-white text-black w-10 h-10 text-start p-2 rounded-xl  border border-black`} />
                                        }

                                    </button>
                                    {isEditingTextCodes && <button className=''
                                        onClick={() => {
                                            setCreateCodeModal(
                                                <TextCodeModal
                                                    handleClose={() => { setCreateCodeModal(null) }}
                                                    code={textCode}
                                                    handleUpdateCode={handleUpdateCode}
                                                />)
                                        }}
                                    >
                                        <PencilIcon
                                            className='w-10 h-10 text-start p-2 rounded-xl text-white bg-black border border-black'
                                        ></PencilIcon>
                                    </button>
                                    }
                                    {isEditingTextCodes && <button className=''
                                        onClick={() => {
                                            setViewCodesModal(<TextCodeExcerptsModal handleClose={() => { setViewCodesModal(null) }} code={textCode} />)
                                        }}
                                    >
                                        <ListBulletIcon
                                            className='w-10 h-10 text-start p-2 rounded-xl text-white bg-black border border-black'
                                        ></ListBulletIcon>
                                    </button>
                                    }

                                </div>
                            )
                        })}
                    </div>
                    <button
                        onClick={() => {
                            setCreateCodeModal(
                                <TextCodeModal
                                    handleClose={() => { setCreateCodeModal(null) }}
                                    handleAddNewCode={handleAddNewCode}
                                />)
                        }}
                        className='bg-black text-white rounded-md py-2 shadow-md h-fit mb-2'
                    >
                        {t('TextCoder.Create code')}
                    </button>
                </div>

                {createCodeModal}
            </div>

        </div>
    )
}

export default TextCodes

import React, { useEffect, useState } from 'react'
import SectionHeader from '../../../../Components/GeneralComponents/SectionHeader'
import { useTranslation } from 'react-i18next'
import MeetingCard from '../../../../Components/Meetings/MeetingCard'
import { getMeetings } from '../../../../Requests/MeetingRequests'
import { getCredits, getTeamUuid } from '../../../../utils/cookies'
import { getProjects } from '../../../../Requests/ProjectRequests'
import CreateEditProjectSlideover from '../../../../Components/Projects/CreateEditProjectSlideover'
import ProjectCard from '../../../../Components/Projects/ProjectCard'
import { useMyContext } from '../../../../Components/ContextProviders/CreditsContextProvider'
import { updateCredits } from '../../../../Requests/TeamRequests'

function Projects() {
    const { t } = useTranslation()
    const [projects, setProjects] = useState(null)
    const [projectSlideover, setProjectSlideOver] = useState(null)

    const { credits, setCredits } = useMyContext();

    useEffect(() => {
        getProjects(getTeamUuid()).then((res) => {
            setProjects(res.data)
        }).catch((error) => {

        });
        updateCredits(getTeamUuid()).then((res) => {
            setCredits(res.data)
        }).catch((e) => {

        })
    }, [])


    return (
        <div className='md:pl-64 pt-4 md:pt-12 w-full flex justify-center'>
            <div className='flex flex-col w-full justify-center items-center px-4'>
                <div className='bg-white max-w-7xl rounded-2xl w-full h-full shadow-xl mx-8'>
                    <SectionHeader title={t('Projects.Title')} subtitle={t('Projects.Subtitle')} actionTitle={t('Projects.ActionTitle')} actionFunction={() => { setProjectSlideOver(<CreateEditProjectSlideover handleClose={() => { setProjectSlideOver(null) }} />) }} />


                </div>
                {projects && <div className='grid grid-cols-1 sm:grid-cols-2 justify-start py-4 max-w-7xl w-full flex-wrap gap-4'>
                    {projects.map((project) => {
                        return <ProjectCard project={project} key={project.uuid} />
                    })}

                </div>
                }
            </div>
            {projectSlideover}
        </div>
    )
}

export default Projects

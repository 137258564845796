import amplitude, { options } from 'amplitude-js';
import Cookies from "universal-cookie";

function generate_analitics_token() {
    var uuid = require("uuid");
    return uuid.v4();
}

export const initAmplitude = () => {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE, "", { apiEndpoint: process.env.REACT_APP_API_URL_LOGS, saveEvents: false });
    const cookies = new Cookies();
    let analitics_token = cookies.get("analitics_token");
    if (!cookies.get("analitics_token")) {
        let user_uuid = cookies.get("user_id");
        analitics_token = user_uuid ? user_uuid : generate_analitics_token();
        cookies.set("analitics_token", analitics_token)
    }
    setAmplitudeUserId(analitics_token);
};

export const setAmplitudeUserDevice = installationToken => {
    amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = userId => {
    amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = properties => {
    amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventProperties) => {
    amplitude.getInstance().logEvent(process.env.REACT_APP_NAME, eventProperties);

};

import React, { useEffect, useState } from 'react'
import SectionHeader from '../../../../Components/GeneralComponents/SectionHeader'
import NewTeamMemberModal from '../../../../Components/Team/NewTeamMemberModal'
import { getTeamMembers } from '../../../../Requests/TeamRequests'
import TeamMembersTable from '../../../../Components/Team/TeamMembersTable'
import { useTranslation } from 'react-i18next'
import { getTeamUuid } from '../../../../utils/cookies'

function Team() {
    const [teamMemberModal, setTeamMemberModal] = useState(false)
    const [teamMembers, setTeamMembers] = useState(null)
    const { t } = useTranslation()

    useEffect(() => {
        getTeamMembers(getTeamUuid()).then((res) => {
            setTeamMembers(res.data["members"])
        }).catch((error) => {

        });
    }, [])



    return (
        <div className='md:pl-64 pt-4 md:pt-12 w-full flex justify-center px-4'>
            <div className='bg-white max-w-7xl rounded-2xl w-full h-full shadow-xl mx-8'>
                <SectionHeader title={t('Team.Header.Title')} subtitle={t('Team.Header.Subtitle')} actionTitle={t('Team.Header.ActionTitle')} actionFunction={() => { setTeamMemberModal(true) }}></SectionHeader>
                {teamMembers && <TeamMembersTable teamMembers={teamMembers}></TeamMembersTable>}
            </div>

            {teamMemberModal && <NewTeamMemberModal handleClose={() => { setTeamMemberModal(false) }}></NewTeamMemberModal>}
        </div>
    )
}

export default Team

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CalculatorIcon, CheckIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MultiSelect, MultiSelectItem } from "@tremor/react";
import { createOrEditAnalysis } from "../../Requests/AnalysisRequests";
import { getTeamUuid } from "../../utils/cookies";


export default function CreateAnalysis({ meetings }) {
    const [language, setLanguage] = useState("pt");
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [values, setValues] = useState("");


    const handleSelectChange = (event) => {
        setLanguage(event.target.value);
    };

    const handleCreateAnalysis = (event) => {
        event.preventDefault();
        let data = { "name": event.target.name.value, "meeting_uuids": values, "language": language, "team_uuid": getTeamUuid() }
        createOrEditAnalysis(data).then((res) => {
            window.location.reload(false)
        }).catch((e) => {

        })
    }

    return (
        <div className='bg-white max-w-7xl rounded-2xl w-full h-full shadow-xl mx-8 px-4 py-4 mt-4'>
            <form onSubmit={handleCreateAnalysis} className="w-full">
                <div className="font-semibold">
                    {t('Analysis.NewAnalysisModalTitle')}
                </div>
                <div className="flex flex-col gap-2 mt-2">
                    <label className=" whitespace-nowrap text-sm text-gray-500">
                        {t('Analysis.NewAnalysisModalNameLabel')}
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        className="flex  border rounded w-full h-fit text-sm"
                        required
                    />
                </div>
                <div className="flex flex-col gap-2 mt-2">
                    <label className=" whitespace-nowrap text-sm text-gray-500">
                        {t('Analysis.NewAnalysisModalMeetingsLabel')}
                    </label>
                    <MultiSelect onValueChange={setValues} className="z-50 w-full" placeholder={t('Analysis.NewAnalysisModalMeetingsPlaceholder')}>
                        {meetings?.map((meeting) => {
                            return (<MultiSelectItem value={meeting.uuid} icon={CalculatorIcon}>
                                {meeting.name}
                            </MultiSelectItem>)
                        })}
                    </MultiSelect>
                </div>
                <div className="flex flex-col gap-2 mt-2">
                    <label className=" whitespace-nowrap text-sm text-gray-500">
                        {t('Analysis.NewAnalysisModalLanguageLabel')}
                    </label>
                    <select
                        id="location"
                        name="location"
                        className=" block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-black-600 sm:text-sm sm:leading-6"
                        defaultValue={t('Audio.AudioUpload.Step2.LanguageOption1')}
                        onChange={handleSelectChange}
                    >
                        <option>  {t('Audio.AudioUpload.Step2.LanguageOption1')}</option>
                        <option>  {t('Audio.AudioUpload.Step2.LanguageOption2')}</option>
                        <option>  {t('Audio.AudioUpload.Step2.LanguageOption3')}</option>
                    </select>
                </div>
                <div className="flex justify-end mt-4">
                    <button className='text-sm border-2 border-black bg-black text-white rounded-xl h-fit px-4 py-2 shadow-lg '

                    >
                        {t('Analysis.NewAnalysisModalButton')}
                    </button>
                </div>
            </form>
        </div>
    );
}

import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
  const cookies = new Cookies();
  return cookies.get("token");
}

export function removeToken() {
  const cookies = new Cookies();
  cookies.remove("user_id")
  cookies.remove("credits")
  cookies.remove("token");
  cookies.remove("team_uuid");
  return 200
}

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response && error.response.status === 401) {
      const cookies = new Cookies();
      cookies.remove("user_id");
      cookies.remove("token");
      if (!error.config.url.includes("/api/login") &&
        !error.config.url.includes("/api/end_user_login") &&
        !error.config.url.includes("request_end_user_password_change")) {
        window.location.href = '/';
      }
    }
    return Promise.reject(error);
  }
);


export function login(email, password) {
  var url = process.env.REACT_APP_API_BASE_URL + `/login`;
  return axios.post(url, {
    email: email,
    password: password,
  });
}

export function logout() {
  removeToken()
  var url = process.env.REACT_APP_API_BASE_URL + `/logout`;
  return axios.get(url);
}

export function register(data) {
  var url = process.env.REACT_APP_API_BASE_URL + `/register`;
  return axios.post(url, data);
}

export function confirmEmail(confirmation_token) {
  var url = process.env.REACT_APP_API_BASE_URL + `/confirm_email`;
  return axios.post(url, {
    confirmation_token: confirmation_token,
  });
}


export function getAvailablePlans() {
  var url = process.env.REACT_APP_API_BASE_URL + "/plans";

  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function createNewSubscription(data) {
  var url = process.env.REACT_APP_API_BASE_URL + "/create_new_subscription";
  return axios.post(
    url,
    data,
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}


export function getSubscriptions() {
  var url = process.env.REACT_APP_API_BASE_URL + "/subscriptions";
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function cancelSubscriptionRenewal(data) {
  var url = process.env.REACT_APP_API_BASE_URL + "/cancel_subscription_renewal";
  return axios.post(
    url,
    data,
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function reactivateSubscriptionRenewal(subscription_id) {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/reactivate_subscription_renewal";
  return axios.post(
    url,
    { subscription_id: subscription_id },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function requestPasswordChange(email) {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/request_password_change";
  return axios.post(
    url,
    {
      email: email
    },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function changePassword(confirmation_token, password, password_confirmation) {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/change_password";
  return axios.post(
    url,
    {
      confirmation_token: confirmation_token, password: password,
      password_confirmation: password_confirmation
    },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function updatePassword(old_password, password, password_confirmation) {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/setup_new_password";
  return axios.post(
    url,
    {
      old_password: old_password,
      password: password,
      password_confirmation: password_confirmation
    },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function updateEmailSettings(email_updates, email_promotions) {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/update_email_settings";
  return axios.post(
    url,
    {
      email_updates: email_updates,
      email_promotions: email_promotions
    },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function getEmailSettings() {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/email_settings";
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function deleteUser() {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/delete";
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function registerByLink(data) {
  var url = process.env.REACT_APP_API_BASE_URL + `/register_by_link`;
  return axios.post(url, data);
}

export function getInviteLink(uuid) {
  var url = process.env.REACT_APP_API_BASE_URL + `/get_invite_link/` + uuid;
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } })
}

export function getReferalLink() {
  var url = process.env.REACT_APP_API_BASE_URL + `/get_referral_link`;
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } })
}

export function getRefees() {
  var url = process.env.REACT_APP_API_BASE_URL + `/get_referees`;
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } })
}

import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
    ArchiveBoxIcon,
    ArrowRightCircleIcon,
    ChevronDownIcon,
    DocumentDuplicateIcon,
    HeartIcon,
    PencilSquareIcon,
    TrashIcon,
    UserPlusIcon,
    UserGroupIcon,
    CloudArrowDownIcon,
    GlobeAmericasIcon
} from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'
import { LockClosedIcon } from '@heroicons/react/24/outline'
import ChangeLanguageModal from '../NewAnalysisElements/ChangeLanguageModal'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function MeetingOptionsMenu({ meeting, handleEditNames, handleDeleteData, handleVisibilitySettings }) {
    const { t } = useTranslation()
    const [modal, setModal] = useState(null)
    return (
        <div className='w-full'>
            <Menu as="div" className="relative inline-block text-left w-full ">
                <div>
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-xl bg-white px-4 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {t('ExpandedMeetingMenu.OptionsLabel')}
                        <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 " aria-hidden="true" />
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="w-full md:w-56 absolute right-0 z-10 mt-2  origin-top-right divide-y divide-gray-100 rounded-xl bg-white shadow-lg ring-1 ring-black  focus:outline-none opacity-100">
                        <div className="py-1 w-full">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        type="button"
                                        onClick={handleEditNames}
                                        className={classNames(
                                            active ? 'bg-black text-white' : 'text-gray-700',
                                            'group flex items-center px-4 py-2 text-sm w-full'
                                        )}
                                    >
                                        <UserGroupIcon
                                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                            aria-hidden="true"
                                        />
                                        {t('ExpandedMeetingMenu.UpdateNamesLabel')}
                                    </button>
                                )}
                            </Menu.Item>
                        </div>

                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        type='button'
                                        onClick={() => handleVisibilitySettings()}
                                        href="#"
                                        className={classNames(
                                            active ? 'bg-black text-white' : 'text-gray-700',
                                            'group flex items-center px-4 py-2 text-sm w-full'
                                        )}
                                    >
                                        <LockClosedIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        {t('ExpandedMeetingMenu.VisibilityLabel')}
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        type='button'
                                        onClick={() => setModal(<ChangeLanguageModal handleClose={() => setModal(null)} analysis={meeting}></ChangeLanguageModal>)}
                                        href="#"
                                        className={classNames(
                                            active ? 'bg-black text-white' : 'text-gray-700',
                                            'group flex items-center px-4 py-2 text-sm w-full'
                                        )}
                                    >
                                        <GlobeAmericasIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        {t('ExpandedMeetingMenu.ChangeLanguageLabel')}
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                        {meeting?.file?.url && <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        type='button'
                                        onClick={() =>
                                            window.open(meeting?.file?.url, "_blank", "noreferrer")
                                        }
                                        href="#"
                                        className={classNames(
                                            active ? 'bg-black text-white' : 'text-gray-700',
                                            'group flex items-center px-4 py-2 text-sm w-full'
                                        )}
                                    >
                                        <CloudArrowDownIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        Download
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                        }
                    </Menu.Items>
                </Transition>
            </Menu>
            {modal}
        </div>
    )
}

export default MeetingOptionsMenu

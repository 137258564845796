import { CircleStackIcon } from '@heroicons/react/24/outline';
import React from 'react'
import { formatDateTime } from '../../utils/calcs';
import { Navigate, useNavigate } from 'react-router-dom';
import { Card, DonutChart, Flex, Legend, Metric, Text } from '@tremor/react';
import logo from "../../Assets/logo.png"
import SectionHeader from '../../Components/GeneralComponents/SectionHeader';


const results = [
    { "color": "amber-400", "position": 1, "image": "https://teampulse.fra1.digitaloceanspaces.com/samples/Mariana_Mort%C3%A1gua,_legislativas_2024_(53527512817)_(cropped).jpg", "name": "Mariana Mortágua (BE)", "explanation": "Demonstrou uma análise cuidada e ponderada dos temas, colocando questões pertinentes aos outros partidos de forma construtiva." },
    { "color": "gray-600", "position": 2, "image": "https://teampulse.fra1.digitaloceanspaces.com/samples/1524953.jpg", "name": "Rui Tavares (Livre)", "explanation": "Abordou os assuntos de forma equilibrada e com propostas concretas, nomeadamente sobre a justiça." },
    { "color": "amber-800", "position": 3, "image": "https://teampulse.fra1.digitaloceanspaces.com/samples/paulo_raimundo.jpg", "name": "Paulo Raimundo (CDU)", "explanation": "Enfatizou as preocupações dos cidadãos e opôs-se claramente à austeridade, defendendo o SNS." },
    { "color": "zinc-700", "position": 4, "image": "https://teampulse.fra1.digitaloceanspaces.com/samples/Pedro_Nuno_Santos,_projecto_da_alta_velocidade_Lisboa-Porto-Vigo_(2022-10-01),_cropped.png", "name": "Pedro Nuno Santos (PS)", "explanation": "Respondeu às questões de forma clara sobre a posição do PS, embora tenha havido alguma troca acesa com André Ventura." },
    { "color": "zinc-700", "position": 5, "image": "https://teampulse.fra1.digitaloceanspaces.com/samples/2022-06-20-Rui-Rocha.webp", "name": "Rui Rocha (IL)", "explanation": "Apresentou a visão do partido de forma assertiva, apoiando uma solução que inclua a IL e o PSD." },
    { "color": "zinc-700", "position": 6, "image": "https://teampulse.fra1.digitaloceanspaces.com/samples/Lu%C3%ADs_Montenegro.jpg", "name": "Luís Montenegro (PSD)", "explanation": "Criticou o PS mas sem apresentar soluções tão concretas, embora tenha defendido a aliança à direita." },
    { "color": "zinc-700", "position": 7, "image": "https://teampulse.fra1.digitaloceanspaces.com/samples/Andr%C3%A9_Ventura_(Agencia_LUSA,_Entrevista_Presidenciais_2021),_cropped.png", "name": "André Ventura (Chega)", "explanation": "Colocou questões pertinentes mas com alguma demagogia, tendo havido troca de acusações com Pedro Nuno Santos." },
    { "color": "zinc-700", "position": 8, "image": "https://teampulse.fra1.digitaloceanspaces.com/samples/pan-cabecas-lista-eleicoes-legislativas-2022-8.jpg", "name": "Inês Souza Real (PAN)", "explanation": "Interveio pouco no debate conforme a transcrição fornecida, não dando para avaliar bem a performance." }]

function Legislativas2024Debate() {
    const navigate = useNavigate()
    const winnerCounts = {};

    const formattedWinnerCounts = Object.keys(winnerCounts).map(key => ({
        name: key,
        value: winnerCounts[key]
    })).sort((a, b) => b.value - a.value);

    const winnerNames = formattedWinnerCounts.map(winner => winner.name + " - " + winner.value + (winner.value > 1 ? " debates" : " debate"));
    return (
        <div className='flex flex-col items-center'>
            <div className="flex flex-col justigy-center items-center z-10 bg-black text-white w-full">
                <header className="bg-white fixed t top-0 z-40 w-full">
                    <nav className="mx-auto flex items-center justify-between py-0 lg:px-8 z-50" aria-label="Global" >
                        <div className="flex lg:flex-1">
                            <a className="-m-1.5 p-1.5 flex items-center" href='https://meetpulp.com/pt' target='_blank'>
                                <img className="h-24 w-auto" src={logo} alt="" />
                                <p className="text-xl ml-4 font-semibold text-black text-start flex items-end">Meetpulp <span className='px-2 hidden md:block '>|</span><span className='text-sm text-gray-400 hidden md:block'> Grave, transcreva, resuma e obtenha tarefas apartir do seu áudio, com o poder de IA.</span></p>
                            </a>
                        </div>
                        <div className="flex ">
                            <a
                                type="button"
                                className="mr-2 text-md font-semibold leading-6 bg-black rounded-xl px-2 py-2 text-white"
                                href='https://meetpulp.com/pt' target='_blank'
                            >
                                Começar agora
                            </a>
                        </div>
                    </nav>
                    <div className='text-sm text-gray-400 md:hidden block px-4 text-center'>Grave, transcreva, resuma e obtenha tarefas apartir do seu áudio, com o poder de IA.</div>
                </header>

                <h1 className="font-black text-5xl sm:text-7xl mt-8 mb-12 mx-8 text-center max-w-5xl leading-none mt-48">
                    Perguntámos à IA o que achou do <br /> Debate dos partidos com assento parlamentar
                </h1>
                <h1 className=" font-medium text-2xl sm:text-4xl mb-16 mx-8 text-center max-w-6xl leading-tight ">
                    Obtivemos estes resultados*:
                </h1>
            </div>


            <div className='max-w-7xl px-4 py-4 '>
                <Card>
                    <Flex className="start flex flex-col items-start">
                        <div className='text-black font-bold'>Perguntámos:</div>
                        <div className=' '> <span className=' italic'>"Ordena todos os 9 oradores com base na sua performance, e justifica as escolhas."</span></div>
                    </Flex>
                    <div className='w-full justify-center mt-8'>
                        {results?.map((result) => {
                            return (<div className='w-full flex justify-start sm:gap-8 sm:px-8 py-2 border-b border-dotted '>
                                <div className='flex flex-col sm:flex-row gap-2 '>
                                    <div className='flex items-center justify-center gap-4'>
                                        <div className={'relative w-20 sm:w-14 h-20 sm:h-14 rounded-full overflow-hidden flex items-center justify-center  '} >
                                            <img src={result.image} className='absolute w-full h-full object-cover' alt="Background" />
                                            <div className={"absolute w-full h-full bg-" + result.color + " bg-opacity-60 flex items-center justify-center"}></div>
                                            <p className='relative z-10 text-white text-xl font-extrabold'>{result.position}</p>
                                        </div>
                                    </div>
                                    <div className='flex flex-col px-2'>
                                        <p>{result.name}</p>
                                        <p className='text-sm text-gray-600'>{result.explanation}</p>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    </div>

                </Card>

                <div className='w-full grid grid-cols-1 md:grid-cols-1 gap-4  pb-4 mt-4'>


                    <div className='flex flex-col shadow-2xl px-4 py-4 w-full bg-white rounded-2xl h-full '>
                        <div className='flex justify-between flex-col sm:flex-row gap-2 mb-4'>
                            <div className='flex items-center  ml-1 justify-center'>
                                <CircleStackIcon className='w-9 h-9' />
                                <div className=' pl-4 h-full flex items-center'>
                                    <div className='text-base font-semibold  text-gray-900 '>
                                        {"Debate dos partidos com assento parlamentar"}
                                    </div>
                                </div>

                            </div>
                            <a className='text-sm bg-black text-white rounded-xl h-fit px-3 py-2 shadow-xl text-center'
                                href="https://app.meetpulp.com/meeting/public/f2f2dcd9-4dea-47ed-a298-c4fd6872b74f"

                            >
                                <div>Sentimentos e mais</div>

                            </a>
                        </div>
                        <div className={'px-2 text-sm py-4 border-t-2 border-dotted h-full'}>
                            <span className='font-bold'>Resumo</span>:<br />
                            O Carlos Daniel deu as boas-vindas aos líderes partidários e realçou a importância deste debate antes das eleições. O Pedro Nuno Santos esclareceu a posição do PS, dizendo que governará sozinho ou em maioria, incluindo à esquerda, e não criará um impasse constitucional caso fique em segundo atrás da direita. O Luís Montenegro afirmou que a posição do PS é confusa e destacou que só será primeiro-ministro se o seu partido vencer, explicando a sua posição sobre alianças. O André Ventura argumentou que os estudos mostram o Chega estável nos 17-20% pelo que uma maioria de direita é improvável sem eles, criticando Luís Montenegro por não se comprometer claramente. O Rui Rocha disse que a Iniciativa Liberal apoia uma solução que a inclua a si e ao PSD, mas com visões diferentes, não apoiando um governo minoritário do PS. O Paulo Raimundo enfatizou responder aos problemas dos cidadãos em vez de cálculos políticos e comprometeu-se com a oposição ao regresso das políticas de austeridade. O Rui Tavares discutiu problemas da justiça portuguesa comparativamente à UE no que diz respeito à velocidade e eficiência. A Mariana Mortágua focou-se na saúde e nos problemas do SNS, defendendo mais investimento e autonomia para este funcionar melhor.
                        </div>

                    </div>
                </div>
            </div >
            <div className='text-xs text-gray-600 '>* Os resultados apresentados tem apenas como objectivo entretenimento, e não expressando as opiniões do Meetpulp nem nenhum dos funcionários da empresa. Poderão haver erros, o Meetpulp não se responsabiliza pelos mesmos.</div>
            <div className='text-xs text-gray-600 mb-4'>Para ajuda ou alguma informação pode contactar-nos em support@meetpulp.com</div>
        </div >
    )
}

export default Legislativas2024Debate

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getPublicMeeting } from '../../../../Requests/MeetingRequests';
import ExpandedMeetingCard from '../../../../Components/Meetings/ExpandedMeetingCard';
import logo from "../../../../Assets/logo.png"
function PublicMeeting() {
    const { public_uuid } = useParams()
    const [meetingInfo, setMeetingInfo] = useState(null);

    useEffect(() => {
        if (public_uuid) {
            getPublicMeeting(public_uuid).then((res) => {
                setMeetingInfo(res.data)
            })
        }
    }, [public_uuid])

    const updateMeeting = () => {
        getPublicMeeting(public_uuid).then((res) => {
            setMeetingInfo(res.data)
        }).catch((error) => {

        });
    }

    return (
        <div className=' py-12 w-full flex justify-center'>
            <header className="bg-white fixed t top-0 z-40 w-full">
                <nav className="mx-auto flex items-center justify-between py-0 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a className="-m-1.5 p-1.5 flex items-center" href='https://meetpulp.com/pt' target='_blank'>
                            <img className="h-24 w-auto" src={logo} alt="" />
                            <p className="text-xl ml-4 font-semibold text-black text-start flex items-end">Meetpulp <span className='px-2 hidden md:block '>|</span><span className='text-sm text-gray-400 hidden md:block'> Grave, transcreva, resuma e obtenha tarefas apartir do seu áudio, com o poder de IA.</span></p>
                        </a>
                    </div>
                    <div className="flex ">
                        <a
                            type="button"
                            className="mr-2 text-md font-semibold leading-6 bg-black rounded-xl px-2 py-2 text-white"
                            href='https://meetpulp.com/pt' target='_blank'
                        >
                            Começar agora
                        </a>
                    </div>
                </nav>
                <div className='text-sm text-gray-400 md:hidden block px-4 text-center'>Grave, transcreva, resuma e obtenha tarefas apartir do seu áudio, com o poder de IA.</div>
            </header>
            <div className='flex flex-col w-full justify-center items-center mt-24'>
                <div className='bg-white max-w-7xl rounded-2xl w-full h-full shadow-xl mx-8'>
                    {meetingInfo && <ExpandedMeetingCard meeting={meetingInfo} updateMeeting={updateMeeting} view_mode='public' />}
                </div>
            </div>

        </div>
    )
}

export default PublicMeeting

import Cookies from "universal-cookie";

export function getTeamUuid() {
    const cookies = new Cookies();
    return cookies.get("team_uuid");
}

export function getUserUuid() {
    const cookies = new Cookies();
    return cookies.get("user_id");
}

export function getCredits() {
    const cookis = new Cookies();
    return cookis.get("credits");
}

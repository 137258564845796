import { Card, CategoryBar, Flex, Legend, Metric, Text, Tracker } from '@tremor/react'
import React from 'react'

function TimeBasedSentimentCard({ duration, fragment, t, sentimentTranslations, mapSentimentAndColors }) {
    return (
        <Card className='flex flex-col h-full justify-between my-1'>

            <div>
                <Flex alignItems="start">
                    <p className='font-semibold text-gray-700'>{t('ExpandedMeedingCard.DominantSentimentOverTime')}</p>
                </Flex>
            </div>

            <div>
                <Tracker className="mt-4"
                    data={
                        Object.entries(JSON.parse(fragment.result)).map(([key, value]) => {
                            return { tooltip: (sentimentTranslations(value, t('Language'))), color: mapSentimentAndColors(value) };
                        })
                    }
                />

            </div>
            {fragment.result && <div className='w-full flex justify-center items-center'>
                <Legend
                    className="mt-3"
                    categories={
                        [...new Set(Object.values(JSON.parse(fragment.result)).map(value => (
                            sentimentTranslations(value, t('Language'))
                        )))]
                    }
                    colors={
                        [...new Set(Object.values(JSON.parse(fragment.result)).map(value => (
                            mapSentimentAndColors(value) ? mapSentimentAndColors(value) : "black"
                        )))]
                    }
                />
            </div>}
        </Card >
    )
}

export default TimeBasedSentimentCard

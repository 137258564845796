import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function ViewFiles({ files, project_uuid, analysis_uuid }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <div className='flex flex-col h-screen'>
            <div className='bg-black text-white w-full p-4  text-center flex flex-col rounded-lg mt-4'>
                <button className='w-fit text-xs flex gap-2 z-30'
                    type='button'
                    onClick={() => { navigate("/project/" + project_uuid) }}
                >
                    <ArrowLeftIcon className='w-4 h-4'></ArrowLeftIcon>
                    {t('TextCoder.Back')}
                </button>
                <div className='flex-grow -mt-4'>
                    {t('TextCoder.Files')}
                </div>
            </div>
            <div className='w-64 flex flex-col gap-2 my-4 overflow-y-scroll'>
                {files?.map((file) => {
                    if ((file.file_type == "audio" || file.file_type == "manual_transcription") && file.status != "Not processed") {

                        return (

                            <button
                                key={file.uuid}
                                onClick={() => {
                                    navigate("/text_coder/" + project_uuid + "/file/" + file.uuid)
                                }}
                                className={'shadow-lg rounded-lg  p-2 border  ' + (file.uuid == analysis_uuid ? 'bg-black text-white' : 'bg-white text-black')}>
                                {file.name}
                            </button>
                        )
                    }
                })}

            </div>

        </div>
    )
}

export default ViewFiles

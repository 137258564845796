import { Document, Packer, Paragraph, TextRun, CommentRangeStart, CommentRangeEnd, CommentReference } from 'docx';
import { saveAs } from 'file-saver';
import { getSpeakerColor, newFormatTime } from '../../../../utils/calcs';

export const ExportToWord = (groupedTranscriptions, speakerNames, selections, colors, textCodes, analysis, teamMembers) => {
    let allParagraphs = [];
    let comments = []

    groupedTranscriptions.forEach(group => {

        const speakerColor = getSpeakerColor(speakerNames, speakerNames[group.speaker], colors);
        const sortedSelections = selections?.filter(sel => sel.uuid === group.uuid).sort((a, b) => a.start - b.start);
        const lineTextRuns = []
        const speakerParagraph = new Paragraph({
            children: [
                new TextRun({
                    text: `${speakerNames[group.speaker]} `,
                    color: speakerColor?.replace('#', ''),
                    bold: true,
                    size: 28
                }),
                new TextRun({
                    text: ` ${group.startTime ? newFormatTime(group.startTime) : ""}-${group.endTime ? newFormatTime(group.endTime) : ""}`,
                    size: 20
                }),
            ],
        });

        sortedSelections?.map((element) => {
            let name = textCodes.find((code) => code.uuid === element.name);
            const author = teamMembers?.find(member => member.user_uuid === element.author);
            if (author?.visible) {
                comments.push({
                    id: comments.length,
                    auuid: element.auuid,
                    author: author.user_name,
                    date: new Date(),
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: name.name
                                })
                            ]
                        })
                    ]
                })
            }
        })



        for (let i = 0; i <= group.lines.length; i++) {
            sortedSelections?.forEach((element) => {
                const author = teamMembers?.find(member => member.user_uuid === element.author);
                if (author?.visible) {
                    const comment_index = comments.findIndex((comment) => comment.auuid == element.auuid);
                    if (element.start == i) {
                        lineTextRuns.push(new CommentRangeStart(comment_index))
                    }
                    if (element.end == (i)) {

                        lineTextRuns.push(new CommentRangeEnd(comment_index))
                        lineTextRuns.push(new CommentReference(comment_index))

                    }
                }
            });

            lineTextRuns.push(new TextRun({
                text: group.lines.charAt(i),
                size: 20
            }));
        }
        const textParagraph = new Paragraph({
            children: lineTextRuns,
        });
        allParagraphs.push(speakerParagraph);
        allParagraphs.push(textParagraph);
        allParagraphs.push(new Paragraph(""));

    })

    const doc = new Document({
        comments: {
            children: comments
        },
        sections: [{
            properties: {},
            children: allParagraphs,
        }]
    });

    // Export the document
    Packer.toBlob(doc).then(blob => {
        saveAs(blob, analysis.name + "_transcription");
    }).catch((error) => { console.log(error) });

}
